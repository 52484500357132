import React from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import { viewPlayerShip } from "redux/actions";
import Damage from "components/base/Damage";
import AvatarShip from "components/ui/AvatarShip";
import {
  floatAnimation,
  damagedAnimation,
  dodgeAnimation,
  loseAnimation,
} from "components/base/styles";
import { PlayerData } from "types";
import { hasAnimation } from "utils/stats";

interface OnlinePlayerProps {
  player: PlayerData;
}

interface OnlinePlayerWrapperProps {
  position: number;
  isMoving: boolean;
}

interface AvatarImageWrapperProps {
  animations: string[];
  lastMove: string;
}

const OnlinePlayerWrapper = styled.div<OnlinePlayerWrapperProps>`
  position: absolute;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${floatAnimation} 3.5s ease-in-out infinite;
  ${(props) => props.isMoving && "animation-play-state: paused;"}
  left: calc(${(props) => props.position * 0.2}% + 20vw);
  transition: all 200ms ease-out;
  z-index: 4;

  &:hover {
    filter: brightness(120%);
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  bottom: 80%;
`;

const Name = styled.div`
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: 400;
`;

const damagedMixin = css`
  animation: ${damagedAnimation} 300ms ease-in-out forwards;
`;

const dodgeMixin = css`
  animation: ${dodgeAnimation} 300ms ease-in-out forwards;
`;

const loseMixin = css`
  animation: ${loseAnimation} 3000ms ease-in forwards;
`;

const AvatarImageWrapper = styled.div<AvatarImageWrapperProps>`
  height: 100%;
  transform: scaleX(${(props) => (props.lastMove === "right" ? 1 : -1)});
  ${(props) => (hasAnimation(props.animations, "damaged") ? damagedMixin : "")};
  ${(props) => (hasAnimation(props.animations, "dodge") ? dodgeMixin : "")};
  ${(props) => (hasAnimation(props.animations, "lose") ? loseMixin : "")};
  cursor: pointer;
  position: relative;
`;

export default function OnlinePlayer({ player }: OnlinePlayerProps) {
  const dispatch = useDispatch();
  const {
    userName,
    data: {
      location: { position },
      ui: { damage, animations, isMoving, lastMove },
    },
  } = player;

  return (
    <OnlinePlayerWrapper
      position={position}
      isMoving={isMoving}
      onClick={() => dispatch(viewPlayerShip(userName))}
    >
      <InfoWrapper>
        <Damage damageList={damage} />
        <Name>{userName}</Name>
      </InfoWrapper>

      <AvatarImageWrapper lastMove={lastMove} animations={animations}>
        <AvatarShip shipData={player.data} />
      </AvatarImageWrapper>
    </OnlinePlayerWrapper>
  );
}
