import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTransition } from "react-transition-state";

import { getChatMessages, getOnlineUsers, loadUserData } from "redux/actions";
import { getGameState } from "redux/selectors";
import {
  getAllChatMessages,
  getAllUsers,
  getUserAuth,
} from "utils/storage/firebase";
import { GlobalStyle } from "theme";
import MainMenu from "components/ui/MainMenu";
import GameMap from "components/ui/GameMap";
import styled, { css } from "styled-components";
import MusicPlayer from "./base/MusicPlayer";

interface MainMenuWrapperProps {
  $status: string;
}

const hideMixin = css`
  opacity: 0;
  transform: scale(1.5);
  pointer-events: none;
`;

const MainMenuWrapper = styled.div<MainMenuWrapperProps>`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  transition: all 1500ms linear;

  ${(props) => props.$status === "exiting" && hideMixin}
`;

export default function App() {
  const dispatch = useDispatch();

  const { isGameModeActive } = useSelector(getGameState);

  const [{ status, isMounted }, toggleTransition] = useTransition({
    timeout: 2000,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true,
    initialEntered: true,
  });

  useEffect(() => {
    // Start fetching on event-based Firebase methods
    getUserAuth((userAuth) => {
      dispatch(loadUserData(userAuth));
    });
    getAllUsers((users) => {
      dispatch(getOnlineUsers(users));
    });
    getAllChatMessages((chatMessages) => {
      dispatch(getChatMessages(chatMessages));
    });
  }, [dispatch]);

  useEffect(() => {
    if (!!isGameModeActive) {
      // Hide main menu
      toggleTransition(false);
    } else {
      // Show main menu
      toggleTransition(true);
    }
  }, [isGameModeActive]);

  return (
    <div>
      <GlobalStyle />
      {isMounted && (
        <MainMenuWrapper $status={status}>
          <MainMenu />
        </MainMenuWrapper>
      )}
      {!!isGameModeActive && <GameMap />}
      <MusicPlayer />
    </div>
  );
}
