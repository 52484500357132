import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useImmer } from "use-immer";

import { linkUserAccount, unlinkUserAccount } from "redux/actions";
import { getGameState } from "redux/selectors";
import Tooltip from "components/base/TooltipDeprecated";
import saveImage from "assets/save.svg";

interface SettingState {
  isTooltipActive: boolean;
}

const LinkAccountWrapper = styled.div`
  color: white;
  z-index: 3;
  cursor: pointer;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SaveIcon = styled.img`
  height: 28px;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: 130%;
`;

const UnlinkAccountWrapper = styled.div`
  color: white;
  z-index: 3;
  cursor: pointer;
`;

const defaultSettingState = {
  isTooltipActive: false,
};

export default function LinkAccount() {
  const dispatch = useDispatch();

  const [settingState, setSettingState] =
    useImmer<SettingState>(defaultSettingState);

  const showTooltip = () => {
    setSettingState((draft) => {
      draft.isTooltipActive = true;
    });
  };

  const hideTooltip = () => {
    setSettingState((draft) => {
      draft.isTooltipActive = false;
    });
  };

  const startLinkAccountFlow = () => {
    dispatch(linkUserAccount());
  };

  const startUnlinkAccountFlow = () => {
    dispatch(unlinkUserAccount());
  };

  const { isUserAccountLinked } = useSelector(getGameState);

  return (
    <>
      {!isUserAccountLinked && (
        <LinkAccountWrapper
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
          onClick={startLinkAccountFlow}
        >
          <SaveIcon src={saveImage} />
          <div>Link to account</div>
          {settingState.isTooltipActive && (
            <TooltipWrapper>
              <Tooltip text="Save Game" />
            </TooltipWrapper>
          )}
        </LinkAccountWrapper>
      )}
      {!!isUserAccountLinked && (
        <UnlinkAccountWrapper onClick={startUnlinkAccountFlow}>
          Unlink account
        </UnlinkAccountWrapper>
      )}
    </>
  );
}
