import { BuildingModel } from "types";

import bishopShipworksExteriorImage from "assets/building-upgrade.svg";
import bishopShipworksInteriorImage from "assets/bg-bishop-shipworks.svg";
import bishopPilotAcademyExteriorImage from "assets/building-pilot-academy.svg";
import bishopPilotAcademyInteriorImage from "assets/bg-bishop-shipworks.svg";
import bishopInfinityShopExteriorImage from "assets/building-repair.svg";
import bishopInfinityShopInteriorImage from "assets/bg-bishop-shipworks.svg";

interface BuildingData {
  [key: string]: BuildingModel;
}

export const BUILDINGS: BuildingData = {
  bishopShipworks: {
    type: "shipworks",
    name: "Bishop Shipworks",
    area: "bishopCity",
    position: 150,
    exteriorImage: bishopShipworksExteriorImage,
    interiorImage: bishopShipworksInteriorImage,
  },
  bishopPilotAcademy: {
    type: "pilotAcademy",
    name: "Bishop Pilot Academy",
    area: "bishopCity",
    position: 100,
    exteriorImage: bishopPilotAcademyExteriorImage,
    interiorImage: bishopPilotAcademyInteriorImage,
  },
  bishopInfinityShop: {
    type: "infinityShop",
    name: "Bishop Infinity Shop",
    area: "bishopCity",
    position: 50,
    exteriorImage: bishopInfinityShopExteriorImage,
    interiorImage: bishopInfinityShopInteriorImage,
  },
};
