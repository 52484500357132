import React from "react";
import styled, { keyframes } from "styled-components";

interface DamageProps {
  damageList: DamageItemProps[];
}

interface DamageItemProps {
  damage: number;
  isCriticalHit: boolean;
}

interface DamageValueProps {
  isCriticalHit: boolean;
}

const damageAnimation = keyframes`
  25% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
`;

const DamageWrapper = styled.div`
  position: relative;
  height: 36px;
  display: flex;
  justify-content: center;
`;

const DamageValue = styled.div<DamageValueProps>`
  height: ${(props) => (props.isCriticalHit ? "52px" : "36px")};
  font-size: ${(props) => (props.isCriticalHit ? "48px" : "32px")};
  color: ${(props) => (props.isCriticalHit ? "#b54747" : "#b75f5f")};
  font-weight: 600;
  position: absolute;
  bottom: 0;
  animation: ${damageAnimation} 1200ms ease-in-out forwards;
  text-align: center;
`;

const Damage = ({ damageList = [] }: DamageProps) => {
  return (
    <DamageWrapper>
      {damageList.map((damageItem, index) => (
        <DamageValue
          key={`${index}.${damageItem.damage}`}
          isCriticalHit={damageItem.isCriticalHit}
        >
          {damageItem.damage}
        </DamageValue>
      ))}
    </DamageWrapper>
  );
};

export default Damage;
