import React from "react";
import styled from "styled-components";

import creditsImage from "assets/credits.svg";
import { getItemData, getShopData } from "utils/stats";
import { buyShopObject } from "redux/actions";
import ObjectIcon from "components/base/ObjectIcon";
import { COLORS } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";

interface ShopProps {
  slug: string;
}

interface IconProps {
  image: string;
}

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
`;

const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BuyWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  opacity: 0;
  transition: opacity 200ms ease-out;
  pointer-events: none;
`;

const BuyButton = styled.div`
  height: 100%;
  background-color: white;
  mask: url(${creditsImage}) no-repeat center;
  mask-size: 60%;
`;

const ItemImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;

  &:hover ${BuyWrapper} {
    opacity: 1;
  }
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ItemName = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;

const Description = styled.div`
  font-size: 14px;
`;

const ItemCredits = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.CREDITS};
  align-items: center;
  font-size: 16px;
  text-align: right;
`;

const ItemCreditsIcon = styled.div<IconProps>`
  height: 14px;
  width: 14px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

export default function Shop({ slug }: ShopProps) {
  const dispatch = useDispatch();

  const {
    data: { inventory },
  } = useSelector(getCharacter);

  const shop = getShopData(slug);

  const buyItem = (slug: string) => {
    dispatch(buyShopObject({ slug, quantity: 1 }));
  };

  return (
    <Items>
      {shop.items.map((shopItem) => {
        const item = getItemData(shopItem);
        const inventoryItem = inventory.items.find(
          (item) => item.slug === shopItem
        );
        const quantity = !!inventoryItem ? inventoryItem.quantity : 0;
        if (!!item) {
          return (
            <Item key={item.name} onClick={() => buyItem(shopItem)}>
              <ItemWrapper>
                <ItemImageWrapper>
                  <ObjectIcon slug={shopItem} />
                  <BuyWrapper>
                    <BuyButton />
                  </BuyWrapper>
                </ItemImageWrapper>
                <ItemInfo>
                  <ItemName>{item.name}</ItemName>
                  {!!quantity && (
                    <Description>In Inventory: x{quantity}</Description>
                  )}
                </ItemInfo>
              </ItemWrapper>
              <ItemCredits>
                <ItemCreditsIcon image={creditsImage} />
                {item.credits}
              </ItemCredits>
            </Item>
          );
        } else {
          return <span key={shopItem} />;
        }
      })}
    </Items>
  );
}
