import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import OnlinePlayers from "components/ui/OnlinePlayers";
import { useDispatch, useSelector } from "react-redux";
import { useImmer } from "use-immer";
import { addChatMessage } from "redux/actions";
import { getGameState } from "redux/selectors";
import { ChatMessage } from "types";

interface ChatState {
  messageText: string;
}

const DataWrapper = styled.div`
  display: flex;
  gap: 5%;
`;

const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const OnlinePlayersWrapper = styled.div`
  height: calc(84vh - 120px);
  overflow-y: scroll;
`;

const ChatWrapper = styled.div`
  border: 1px #ffffff10 solid;
  background-color: #00000020;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const ChatDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(84vh - 210px);
  overflow-y: scroll;
`;

const Message = styled.div`
  display: flex;
  align-items: baseline;
  gap: 6px;
`;

const MessageInfo = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

const MessagePlayer = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
`;

const MessageTime = styled.div`
  opacity: 0.75;
  font-size: 12px;
  white-space: nowrap;
`;

const MessageText = styled.div``;

const ChatLatest = styled.div``;

const ChatInput = styled.input`
  background-color: #ffffff25;
  border: 1px white solid;
  padding: 10px;
  margin-top: 20px;
  font-size: 16px;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  color: white;
  text-align: left;
  position: static;

  &::placeholder {
    color: white;
    opacity: 0.75;
  }
`;

export default function Comms() {
  const dispatch = useDispatch();
  const chatRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useImmer<ChatState>({ messageText: "" });

  const { chatMessages } = useSelector(getGameState);

  // Scroll to newest message on load, and when new messages are sent
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollIntoView({
        behavior: "auto",
        block: "end",
      });
    }
  }, [chatRef, chatMessages]);

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((draft: ChatState) => {
      draft.messageText = event.target.value;
    });
  };

  const handleMessageKeydown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      dispatch(addChatMessage(state.messageText));
      setState((draft: ChatState) => {
        draft.messageText = "";
      });
    }
  };

  return (
    <DataWrapper>
      <Section>
        <Title>Active Starships</Title>
        <OnlinePlayersWrapper>
          <OnlinePlayers />
        </OnlinePlayersWrapper>
      </Section>
      <Section>
        <Title>Transmissions</Title>
        <ChatWrapper>
          <ChatDisplay>
            {!!chatMessages &&
              chatMessages.map((message: ChatMessage) => {
                const messageTime = new Date(message.time).toLocaleTimeString(
                  [],
                  {
                    hour: "numeric",
                    minute: "2-digit",
                  }
                );
                return (
                  <Message>
                    <MessageInfo>
                      <MessageTime>{messageTime}</MessageTime>
                      <MessagePlayer>{message.userName} ▸</MessagePlayer>
                    </MessageInfo>
                    <MessageText>{message.text}</MessageText>
                  </Message>
                );
              })}
            <ChatLatest ref={chatRef} />
          </ChatDisplay>
          <ChatInput
            ref={inputRef}
            placeholder="Enter your message"
            value={state.messageText}
            onChange={handleMessageChange}
            onKeyDown={handleMessageKeydown}
            maxLength={300}
          />
        </ChatWrapper>
      </Section>
    </DataWrapper>
  );
}
