import React from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { disableMusic, enableMusic } from "redux/actions";
import { getCharacter } from "redux/selectors";

interface MusicOptionProps {
  $isActive: boolean;
}

const Title = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const MusicOption = styled.div<MusicOptionProps>`
  opacity: 0.5;
  ${(props) => props.$isActive && `opacity: 1;`}
`;

export default function SettingsMusic() {
  const dispatch = useDispatch();

  const {
    data: { settings },
  } = useSelector(getCharacter);

  const handleEnableMusic = () => {
    dispatch(enableMusic());
  };

  const handleDisableMusic = () => {
    dispatch(disableMusic());
  };

  return (
    <div>
      <Title>Music</Title>

      <MusicOption
        $isActive={!!settings.isMusicEnabled}
        onClick={handleEnableMusic}
      >
        Music on
      </MusicOption>
      <MusicOption
        $isActive={!settings.isMusicEnabled}
        onClick={handleDisableMusic}
      >
        Music off
      </MusicOption>
    </div>
  );
}
