import React from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { getCharacter } from "redux/selectors";
import LargeWeakenBar from "components/base/LargeWeakenBar";
import firepowerImage from "assets/stat-firepower.svg";
import resilienceImage from "assets/stat-resilience.svg";
import speedImage from "assets/stat-speed.svg";
import precisionImage from "assets/stat-precision.svg";
import energyImage from "assets/stat-energy.svg";
import enhancementsImage from "assets/enhancements.svg";
import creditsImage from "assets/credits.svg";
import { CharacterBaseStats, CharacterDerivedStats } from "types";
import { useImmer } from "use-immer";
import { COLORS } from "utils/constants";
import {
  getStatsResetCost,
  getStatsTokensSpent,
  getShipData,
  MAX_BASE_STAT_VALUE,
} from "utils/stats";
import { buyEnhanceStat, buyResetStats } from "redux/actions";
import { BASE_STATS } from "data/baseStats";
import { DERIVED_STATS } from "data/derivedStats";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import Medallions from "components/base/Medallions";

interface EnhanceProps {
  canEnhance: boolean;
}

interface EnhanceState {
  highlightStat: keyof CharacterBaseStats | null;
}

interface IconProps {
  image: string;
}

interface BaseStatProps {
  enhanceable: boolean;
}

interface DerivedStatProps {
  slug: keyof CharacterDerivedStats;
  value: number;
  highlightStat: keyof CharacterBaseStats | null;
  isTooltipAbove?: boolean;
}

interface DerivedStatWrapperProps {
  $isDim: boolean;
  $color: string;
}

interface ResetButtonProps {
  $isDisabled: boolean;
}

const dimMixin = css`
  opacity: 0.2;
`;

const disabledMixin = css`
  opacity: 0.3;
  pointer-events: none;
`;

const DataWrapper = styled.div`
  display: flex;
  gap: 5%;
`;

const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`;

const StatsTitle = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const BaseStats = styled.div``;

const EnhancementsIcon = styled.div<IconProps>`
  width: 16px;
  height: 16px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementCostWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.5;
  transition: all 100ms ease-out;
  color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementCost = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const EnhanceText = styled.div`
  display: none;
`;
const CostText = styled.div``;

const enhanceableMixin = css`
  :hover ${EnhancementCostWrapper} {
    opacity: 1;
  }

  :hover ${EnhanceText} {
    display: block;
  }

  :hover ${CostText} {
    display: none;
  }
`;

const BaseStat = styled.div<BaseStatProps>`
  padding-bottom: 20px;
  cursor: pointer;

  ${(props) => !!props.enhanceable && enhanceableMixin}
`;

const BaseStatIcon = styled.div<IconProps>`
  height: 20px;
  width: 20px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const StatInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatName = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const BarWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ResetButton = styled.div<ResetButtonProps>`
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  background-color: #0e4042;
  padding: 8px 16px;
  color: white;
  margin-top: 30px;
  align-self: end;
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover {
    filter: brightness(120%);
  }

  ${(props) => props.$isDisabled && disabledMixin}
`;

const Credits = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.CREDITS};
  align-items: center;
`;

const CreditsIcon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

const DerivedStats = styled.div``;

const StatGroup = styled.div`
  margin-bottom: 12px;
`;

const StatGroupName = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  margin-bottom: 4px;
`;

const Separator = styled.div`
  width: 100%;
  border-bottom: white 0.5px solid;
  opacity: 0.75;
`;

const StatIcon = styled.div<IconProps>`
  width: 10px;
  height: 10px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: white;
`;

const DerivedStatWrapper = styled.div<DerivedStatWrapperProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 16px;
  transition: all 100ms ease-out;
  ${(props) => props.$isDim && dimMixin}
  ${(props) => `color: ${props.$color};`}

  ${StatIcon} {
    ${(props) => `background-color: ${props.$color};`}
  }
`;

const DerivedStatName = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StatValue = styled.div``;

const DerivedStat = ({
  slug,
  value,
  highlightStat = null,
  isTooltipAbove = false,
}: DerivedStatProps) => {
  const statSlug = slug as keyof CharacterDerivedStats;
  const statInfo = DERIVED_STATS[statSlug];

  return (
    <Trigger>
      <DerivedStatWrapper
        $isDim={!!highlightStat && highlightStat !== statInfo.baseStat}
        $color={statInfo.color}
      >
        <DerivedStatName>
          <StatIcon image={statInfo.image} />
          {statInfo.name}
        </DerivedStatName>
        <StatValue>{statInfo.formatter(value)}</StatValue>
      </DerivedStatWrapper>
      <Tooltip isAbove={isTooltipAbove}>
        <TooltipName>{statInfo.name}</TooltipName>
        <TooltipDesc>{statInfo.description}</TooltipDesc>
      </Tooltip>
    </Trigger>
  );
};

export default function ShipworksEnhance({ canEnhance = false }: EnhanceProps) {
  const dispatch = useDispatch();
  const {
    data: {
      currentBaseStats,
      enhancedBaseStats,
      weakenedBaseStats,
      stats,
      ship,
    },
  } = useSelector(getCharacter);

  const statsCosts = getShipData(ship).baseStatsCosts;

  const tokensSpent = getStatsTokensSpent(enhancedBaseStats, ship);
  // Use spent tokens instead of reset cost to enable/disable reset button, since mechanical engineers reset for free
  const canReset = !!tokensSpent;
  const resetCost = getStatsResetCost(enhancedBaseStats, ship);

  const initialEnhanceState: EnhanceState = {
    highlightStat: null,
  };
  const [state, setState] = useImmer<EnhanceState>(initialEnhanceState);

  const setHighlightStat = (stat: keyof CharacterBaseStats) => {
    setState((draft) => {
      draft.highlightStat = stat;
    });
  };

  const resetHighlightStat = () => {
    setState((draft) => {
      draft.highlightStat = null;
    });
  };

  const enhanceStat = (stat: keyof CharacterBaseStats) => {
    if (!!canEnhance) {
      dispatch(buyEnhanceStat({ baseStat: stat }));
    }
  };

  return (
    <DataWrapper>
      <Section>
        <BaseStats>
          <StatsTitle>Core Attributes</StatsTitle>
          <Medallions />
          <BaseStat
            enhanceable={canEnhance}
            onClick={() => enhanceStat(BASE_STATS.FIREPOWER)}
            onMouseEnter={() => setHighlightStat(BASE_STATS.FIREPOWER)}
            onMouseLeave={resetHighlightStat}
          >
            <StatInfo>
              <StatName>Firepower</StatName>
              <EnhancementCostWrapper>
                <EnhanceText>Enhance Stat:</EnhanceText>
                <CostText>Cost:</CostText>
                <EnhancementCost>
                  <EnhancementsIcon image={enhancementsImage} />
                  {statsCosts[BASE_STATS.FIREPOWER]}
                </EnhancementCost>
              </EnhancementCostWrapper>
            </StatInfo>
            <BarWrapper>
              <BaseStatIcon image={firepowerImage} color={COLORS.FIREPOWER} />
              <LargeWeakenBar
                number={currentBaseStats.firepower}
                weakenNumber={weakenedBaseStats.firepower}
                maxNumber={MAX_BASE_STAT_VALUE}
                color={COLORS.FIREPOWER}
                isReversed={false}
              />
            </BarWrapper>
          </BaseStat>
          <BaseStat
            enhanceable={canEnhance}
            onClick={() => enhanceStat(BASE_STATS.RESILIENCE)}
            onMouseEnter={() => setHighlightStat(BASE_STATS.RESILIENCE)}
            onMouseLeave={resetHighlightStat}
          >
            <StatInfo>
              <StatName>Resilience</StatName>
              <EnhancementCostWrapper>
                <EnhanceText>Enhance Stat:</EnhanceText>
                <CostText>Cost:</CostText>
                <EnhancementCost>
                  <EnhancementsIcon image={enhancementsImage} />
                  {statsCosts[BASE_STATS.RESILIENCE]}
                </EnhancementCost>
              </EnhancementCostWrapper>
            </StatInfo>
            <BarWrapper>
              <BaseStatIcon image={resilienceImage} color={COLORS.RESILIENCE} />
              <LargeWeakenBar
                number={currentBaseStats.resilience}
                weakenNumber={weakenedBaseStats.resilience}
                maxNumber={MAX_BASE_STAT_VALUE}
                color={COLORS.RESILIENCE}
                isReversed={false}
              />
            </BarWrapper>
          </BaseStat>
          <BaseStat
            enhanceable={canEnhance}
            onClick={() => enhanceStat(BASE_STATS.SPEED)}
            onMouseEnter={() => setHighlightStat(BASE_STATS.SPEED)}
            onMouseLeave={resetHighlightStat}
          >
            <StatInfo>
              <StatName>Speed</StatName>
              <EnhancementCostWrapper>
                <EnhanceText>Enhance Stat:</EnhanceText>
                <CostText>Cost:</CostText>
                <EnhancementCost>
                  <EnhancementsIcon image={enhancementsImage} />
                  {statsCosts[BASE_STATS.SPEED]}
                </EnhancementCost>
              </EnhancementCostWrapper>
            </StatInfo>
            <BarWrapper>
              <BaseStatIcon image={speedImage} color={COLORS.SPEED} />
              <LargeWeakenBar
                number={currentBaseStats.speed}
                weakenNumber={weakenedBaseStats.speed}
                maxNumber={MAX_BASE_STAT_VALUE}
                color={COLORS.SPEED}
                isReversed={false}
              />
            </BarWrapper>
          </BaseStat>
          <BaseStat
            enhanceable={canEnhance}
            onClick={() => enhanceStat(BASE_STATS.PRECISION)}
            onMouseEnter={() => setHighlightStat(BASE_STATS.PRECISION)}
            onMouseLeave={resetHighlightStat}
          >
            <StatInfo>
              <StatName>Precision</StatName>
              <EnhancementCostWrapper>
                <EnhanceText>Enhance Stat:</EnhanceText>
                <CostText>Cost:</CostText>
                <EnhancementCost>
                  <EnhancementsIcon image={enhancementsImage} />
                  {statsCosts[BASE_STATS.PRECISION]}
                </EnhancementCost>
              </EnhancementCostWrapper>
            </StatInfo>
            <BarWrapper>
              <BaseStatIcon image={precisionImage} color={COLORS.PRECISION} />
              <LargeWeakenBar
                number={currentBaseStats.precision}
                weakenNumber={weakenedBaseStats.precision}
                maxNumber={MAX_BASE_STAT_VALUE}
                color={COLORS.PRECISION}
                isReversed={false}
              />
            </BarWrapper>
          </BaseStat>
          <BaseStat
            enhanceable={canEnhance}
            onClick={() => enhanceStat(BASE_STATS.ENERGY)}
            onMouseEnter={() => setHighlightStat(BASE_STATS.ENERGY)}
            onMouseLeave={resetHighlightStat}
          >
            <StatInfo>
              <StatName>Antimatter</StatName>
              <EnhancementCostWrapper>
                <EnhanceText>Enhance Stat:</EnhanceText>
                <CostText>Cost:</CostText>
                <EnhancementCost>
                  <EnhancementsIcon image={enhancementsImage} />
                  {statsCosts[BASE_STATS.ENERGY]}
                </EnhancementCost>
              </EnhancementCostWrapper>
            </StatInfo>
            <BarWrapper>
              <BaseStatIcon image={energyImage} color={COLORS.ENERGY} />
              <LargeWeakenBar
                number={currentBaseStats.energy}
                weakenNumber={weakenedBaseStats.energy}
                maxNumber={MAX_BASE_STAT_VALUE}
                color={COLORS.ENERGY}
                isReversed={false}
              />
            </BarWrapper>
          </BaseStat>
        </BaseStats>
        {!!canEnhance && (
          <ResetButton
            onClick={() => dispatch(buyResetStats({ credits: resetCost }))}
            $isDisabled={!canReset}
          >
            Reset Medallions
            <Credits>
              <CreditsIcon image={creditsImage} />
              {resetCost}
            </Credits>
          </ResetButton>
        )}
      </Section>
      <Section>
        <DerivedStats>
          <StatsTitle>Enhanced Stats</StatsTitle>
          <StatGroup>
            <StatGroupName>
              Attack
              <Separator />
            </StatGroupName>
            <DerivedStat
              slug="attackDamage"
              value={stats.attackDamage}
              highlightStat={state.highlightStat}
            />
            <DerivedStat
              slug="weakenParts"
              value={stats.weakenParts}
              highlightStat={state.highlightStat}
            />
            <DerivedStat
              slug="attackSpeed"
              value={stats.attackSpeed}
              highlightStat={state.highlightStat}
            />
            <DerivedStat
              slug="attackAccuracy"
              value={stats.attackAccuracy}
              highlightStat={state.highlightStat}
            />

            <DerivedStat
              slug="criticalHitMultiplier"
              value={stats.criticalHitMultiplier}
              highlightStat={state.highlightStat}
            />
            <DerivedStat
              slug="criticalHitChance"
              value={stats.criticalHitChance}
              highlightStat={state.highlightStat}
            />
          </StatGroup>
          <StatGroup>
            <StatGroupName>
              Defense
              <Separator />
            </StatGroupName>
            <DerivedStat
              slug="maxHealth"
              value={stats.maxHealth}
              highlightStat={state.highlightStat}
            />
            <DerivedStat
              slug="damageReduction"
              value={stats.damageReduction}
              highlightStat={state.highlightStat}
            />
            <DerivedStat
              slug="weakenPartsReduction"
              value={stats.weakenPartsReduction}
              highlightStat={state.highlightStat}
            />
            <DerivedStat
              slug="dodgeChance"
              value={stats.dodgeChance}
              highlightStat={state.highlightStat}
            />
          </StatGroup>
          <StatGroup>
            <StatGroupName>
              Pilot Abilities
              <Separator />
            </StatGroupName>
            <DerivedStat
              slug="fasterRecharge"
              value={stats.fasterRecharge}
              highlightStat={state.highlightStat}
            />
            <DerivedStat
              slug="energyMultiplier"
              value={stats.energyMultiplier}
              highlightStat={state.highlightStat}
            />
          </StatGroup>
          <StatGroup>
            <StatGroupName>
              Movement
              <Separator />
            </StatGroupName>
            <DerivedStat
              slug="movementSpeed"
              value={stats.movementSpeed}
              highlightStat={state.highlightStat}
              isTooltipAbove
            />
          </StatGroup>
        </DerivedStats>
      </Section>
    </DataWrapper>
  );
}
