import styled from "styled-components";
import { COLORS } from "utils/constants";
import { useSelector } from "react-redux";

import { getCharacter } from "redux/selectors";
import enhancementsImage from "assets/enhancements.svg";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";

interface IconProps {
  image: string;
}

const MedallionsWrapper = styled.div`
  margin-bottom: 10px;
`;

const AvailableMedallions = styled.div`
  display: flex;
  gap: 5px;
  color: ${COLORS.ENHANCEMENTS};
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
`;

const EnhancementPoints = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.ENHANCEMENTS};
  gap: 2px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
`;

const EnhancementsIcon = styled.div<IconProps>`
  width: 18px;
  height: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.ENHANCEMENTS};
`;

const Medallions = () => {
  const {
    data: { baseStatPoints },
  } = useSelector(getCharacter);

  return (
    <MedallionsWrapper>
      <Trigger>
        <AvailableMedallions>
          Medallions:
          <EnhancementPoints>
            <EnhancementsIcon image={enhancementsImage} />
            {baseStatPoints}
          </EnhancementPoints>
        </AvailableMedallions>
        <Tooltip>
          <TooltipName>Medallions</TooltipName>
          <TooltipDesc>
            You earn medallions when you level up, more at higher levels.
          </TooltipDesc>
          <TooltipDesc>
            You can use medallions to either strengthen your starship's core
            attributes or learn new pilot skills. You can do this at the nearest
            Shipworks or Pilot Academy in any city.
          </TooltipDesc>
        </Tooltip>
      </Trigger>
    </MedallionsWrapper>
  );
};

export default Medallions;
