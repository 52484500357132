import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import "css-doodle";

import {
  continueAsCharacter,
  disableMusic,
  enableMusic,
  playMusic,
  setMainMenuScreen,
  signInUserAccount,
  signOutUserAccount,
} from "redux/actions";
import { getCharacter, getGameState } from "redux/selectors";
import { titleAnimation, titleHideAnimation } from "components/base/styles";
import MainMenuBackground from "./MainMenuBackground";
import gameplayVideo from "assets/gameplay-preview.mp4";
import { MainMenuScreens } from "types";
import NewCharacter from "./NewCharacter";
import { useImmer } from "use-immer";

interface MainMenuDisplayProps {
  $isHidden: boolean;
}

interface GameplayPreviewProps {
  $isShowing: boolean;
}

interface MusicSettingsProps {
  $isHidden: boolean;
}

interface MusicOptionProps {
  $isActive: boolean;
}

interface MainMenuState {
  isShowGameplay: boolean;
}

const titleHiddenMixin = css`
  animation: ${titleHideAnimation} 1s linear forwards;
  pointer-events: none;
  mix-blend-mode: normal;
`;

const optionsHiddenMixin = css`
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
`;

const newCharHiddenMixin = css`
  opacity: 0;
  pointer-events: none;
`;

const MainMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
`;

const GameplayPreview = styled.video<GameplayPreviewProps>`
  position: absolute;
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  ${(props) => props.$isShowing && `opacity: 1`};
  transition: all 500ms ease-in;
`;

const PreviewButton = styled.div`
  right: 5vw;
  bottom: 10vh;
  position: absolute;
  z-index: 6;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 15px;
  background-color: #ffffff25;
  transition: all 500ms ease-in;

  :hover {
    background-color: #ffffff50;
  }
`;

const MainMenuContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  position: relative;
`;

const TitleWrapper = styled.div<MainMenuDisplayProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) => props.$isHidden && titleHiddenMixin}
`;

const TitleInner = styled.div`
  position: relative;
`;

const MainTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  mix-blend-mode: overlay;
  text-shadow: 0 0 5px white;

  :hover {
    cursor: pointer;
    mix-blend-mode: normal;
  }

  animation: ${titleAnimation} 10s linear infinite;
`;

const Title = styled.div`
  font-family: "AlphaCentauri", sans-serif;
  font-size: 100px;
  line-height: 0.9;
  letter-spacing: 18px;
`;

const Description = styled.div`
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 10px;
`;

const MusicSettings = styled.div<MusicSettingsProps>`
  color: white;
  position: absolute;
  bottom: 10vh;
  ${(props) => props.$isHidden && `opacity: 0;`}
  transition: all 500ms ease-in;
`;

const MusicOption = styled.div<MusicOptionProps>`
  opacity: 0.5;
  ${(props) => props.$isActive && `opacity: 1;`}
`;

const OptionsWrapper = styled.div<MainMenuDisplayProps>`
  position: absolute;
  transform: scale(1);
  transition: all 1s linear;

  ${(props) => props.$isHidden && optionsHiddenMixin}
`;

const OptionsInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;

  animation: ${titleAnimation} 10s linear infinite;
`;

const NewCharacterWrapper = styled.div<MainMenuDisplayProps>`
  transition: opacity 250ms linear;
  width: 100%;
  height: 100%;

  ${(props) => props.$isHidden && newCharHiddenMixin}
`;

const AuthOption = styled.div`
  :hover {
    cursor: pointer;
    text-shadow: 0 0 5px white;
  }
`;

export default function MainMenu() {
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);

  const { mainMenuScreen, isNewUser, isUserAccountLinked } =
    useSelector(getGameState);
  const {
    userName,
    data: { settings },
  } = useSelector(getCharacter);

  const initialMainMenuState: MainMenuState = {
    isShowGameplay: false,
  };
  const [state, setState] = useImmer<MainMenuState>(initialMainMenuState);

  const goToScreen = (status: MainMenuScreens) => {
    dispatch(setMainMenuScreen(status));
  };

  const handleEnableMusic = () => {
    dispatch(enableMusic());
  };

  const handleDisableMusic = () => {
    dispatch(disableMusic());
  };

  const startMusic = () => {
    dispatch(playMusic());
  };

  const handlePlayVideo = () => {
    setState((draft) => {
      draft.isShowGameplay = true;
    });
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleStopVideo = () => {
    setState((draft) => {
      draft.isShowGameplay = false;
    });
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <MainMenuWrapper>
      <BackgroundWrapper>
        <MainMenuBackground />
        <GameplayPreview
          ref={videoRef}
          src={gameplayVideo}
          muted
          loop
          $isShowing={state.isShowGameplay}
        />
      </BackgroundWrapper>
      <MainMenuContent>
        <TitleWrapper $isHidden={mainMenuScreen !== "title"}>
          <TitleInner>
            <MainTitle
              onClick={() => {
                goToScreen("authOptions");
                startMusic();
              }}
            >
              <Title>
                LIGHT <br />
                VOYAGERS
              </Title>
              <Description>A Multiplayer Space RPG</Description>
            </MainTitle>
          </TitleInner>
          <MusicSettings $isHidden={state.isShowGameplay}>
            <MusicOption
              $isActive={!!settings.isMusicEnabled}
              onClick={handleEnableMusic}
            >
              Music on
            </MusicOption>
            <MusicOption
              $isActive={!settings.isMusicEnabled}
              onClick={handleDisableMusic}
            >
              Music off
            </MusicOption>
          </MusicSettings>
          <PreviewButton
            onMouseEnter={handlePlayVideo}
            onMouseLeave={handleStopVideo}
          >
            See Gameplay
          </PreviewButton>
        </TitleWrapper>
        <OptionsWrapper $isHidden={mainMenuScreen !== "authOptions"}>
          <OptionsInner>
            {!isNewUser && !!userName && (
              <AuthOption onClick={() => dispatch(continueAsCharacter())}>
                Continue as {userName}
              </AuthOption>
            )}
            {!!isNewUser && (
              <AuthOption onClick={() => dispatch(signInUserAccount())}>
                Log In
              </AuthOption>
            )}
            {!userName && (
              <AuthOption onClick={() => goToScreen("newCharacter")}>
                Create New Character
              </AuthOption>
            )}
            {!isNewUser && isUserAccountLinked && (
              <AuthOption onClick={() => dispatch(signOutUserAccount())}>
                Log Out
              </AuthOption>
            )}
            {!isNewUser && !isUserAccountLinked && !!userName && (
              <AuthOption onClick={() => dispatch(signOutUserAccount())}>
                Clear Data
              </AuthOption>
            )}
          </OptionsInner>
        </OptionsWrapper>
        <NewCharacterWrapper $isHidden={mainMenuScreen !== "newCharacter"}>
          <NewCharacter onBack={() => goToScreen("authOptions")} />
        </NewCharacterWrapper>
      </MainMenuContent>
    </MainMenuWrapper>
  );
}
