import React from "react";
import styled from "styled-components";

import SettingsAuth from "components/ui/SettingsAuth";
import SettingsMusic from "./SettingsMusic";

const DataWrapper = styled.div`
  display: flex;
  gap: 5%;
`;

const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const SettingsMusicWrapper = styled.div`
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: white;
`;

export default function Settings() {
  return (
    <DataWrapper>
      <Section>
        <div>
          <Title>Account</Title>
          <SettingsAuth />
        </div>
      </Section>
      <Section>
        <SettingsMusicWrapper>
          <SettingsMusic />
        </SettingsMusicWrapper>
        <Title>Feedback</Title>
        <div>
          <Link href="https://discord.gg/eCuSGRqGry" target="_blank">
            Discord
          </Link>
        </div>
        <Title>Credits</Title>
        Title screen background is a modified version of this
        <Link href="https://codepen.io/yuanchuan/pen/wZJqNK" target="_blank">
          Codepen
        </Link>
      </Section>
    </DataWrapper>
  );
}
