import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { COLORS } from "utils/constants";
import { getCharacter } from "redux/selectors";
import { getUpgradeData } from "utils/stats";
import AvatarShip from "./AvatarShip";
import {
  floatAnimation,
  upgradesScreenAnimation,
} from "components/base/styles";
import { installUpgrade, uninstallUpgrade } from "redux/actions";
import installImage from "assets/install.svg";
import uninstallImage from "assets/uninstall.svg";
import { PartTypes } from "types";
import { useImmer } from "use-immer";
import ObjectIcon from "components/base/ObjectIcon";

interface UpgradeProps {
  canInstall: boolean;
}

interface UpgradesScreenState {
  highlightPart: PartTypes | null;
}

const DataWrapper = styled.div`
  display: flex;
  gap: 5%;
  height: 100%;
`;

const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Upgrades = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px #43fffa solid;
  background-color: #00000099;
  background-size: 2em 2em;
  background-position: center center;
  background-image: linear-gradient(to right, #aaaaaa30 1px, transparent 1px),
    linear-gradient(to bottom, #aaaaaa30 1px, transparent 1px);
  animation: ${upgradesScreenAnimation} 1s linear infinite;
`;

const ShipWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Ship = styled.div`
  height: 60%;
  animation: ${floatAnimation} 3.5s ease-in-out infinite;
`;

const ComponentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const UninstallWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  opacity: 0;
  transition: opacity 200ms ease-out;
  pointer-events: none;
`;

const UninstallButton = styled.div`
  height: 100%;
  background-color: white;
  mask: url(${uninstallImage}) no-repeat center;
  mask-size: 60%;
`;

const Component = styled.div`
  background-color: black;
  border: 1px white solid;
  display: flex;
  align-items: center;
  justify-content: middle;
  width: 10%;
  position: absolute;
  cursor: pointer;
  border-radius: 4px;

  &:hover ${UninstallWrapper} {
    opacity: 1;
  }
`;

const ComponentWeapons = styled(Component)`
  top: 30%;
  right: 10%;
  border-color: ${COLORS.FIREPOWER};
`;

const ComponentShields = styled(Component)`
  top: 30%;
  left: 10%;
  border-color: ${COLORS.RESILIENCE};
`;

const ComponentThrusters = styled(Component)`
  top: 45.5%;
  left: 10%;
  border-color: ${COLORS.SPEED};
`;

const ComponentTargetingSystem = styled(Component)`
  bottom: 30%;
  right: 10%;
  border-color: ${COLORS.PRECISION};
`;

const ComponentReactorCore = styled(Component)`
  bottom: 30%;
  left: 10%;
  border-color: ${COLORS.ENERGY};
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
`;

const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const InstallWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  opacity: 0;
  transition: opacity 200ms ease-out;
  pointer-events: none;
`;

const InstallButton = styled.div`
  height: 100%;
  background-color: white;
  mask: url(${installImage}) no-repeat center;
  mask-size: 60%;
`;

const ItemImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;

  &:hover ${InstallWrapper} {
    opacity: 1;
  }
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;

const Quantity = styled.div`
  font-size: 16px;
  text-align: right;
`;

export default function UpgradeScreen({ canInstall = false }: UpgradeProps) {
  const dispatch = useDispatch();
  const character = useSelector(getCharacter);
  const {
    data: { inventory, shipUpgrades },
  } = character;

  const initialUpgradesScreenState: UpgradesScreenState = {
    highlightPart: null,
  };
  const [upgradesScreenState, setUpgradesScreenState] =
    useImmer<UpgradesScreenState>(initialUpgradesScreenState);

  const setHighlightPart = (part: PartTypes) => {
    setUpgradesScreenState((draft) => {
      draft.highlightPart = part;
    });
  };

  const resetHighlightPart = () => {
    setUpgradesScreenState((draft) => {
      draft.highlightPart = null;
    });
  };

  const handleInstallUpgrade = (slug: string) => {
    if (!!canInstall) {
      dispatch(installUpgrade(slug));
    }
  };

  const handleUninstallUpgrade = (part: PartTypes) => {
    if (!!canInstall) {
      dispatch(uninstallUpgrade(part));
    }
  };

  return (
    <DataWrapper>
      <Section>
        <Title>Installed Upgrades</Title>
        <Upgrades>
          <ShipWrapper>
            <Ship>
              <AvatarShip
                isPreview
                highlightPart={upgradesScreenState.highlightPart}
                shipData={character.data}
              />
            </Ship>
          </ShipWrapper>
          <ComponentsWrapper>
            <ComponentWeapons
              onMouseEnter={() => setHighlightPart("weapons")}
              onMouseLeave={resetHighlightPart}
              onClick={() => handleUninstallUpgrade("weapons")}
            >
              <ObjectIcon slug={shipUpgrades.weapons.slug} />
              {!!canInstall && !shipUpgrades.weapons.isDefault && (
                <UninstallWrapper>
                  <UninstallButton />
                </UninstallWrapper>
              )}
            </ComponentWeapons>
            <ComponentShields
              onMouseEnter={() => setHighlightPart("shields")}
              onMouseLeave={resetHighlightPart}
              onClick={() => handleUninstallUpgrade("shields")}
            >
              <ObjectIcon slug={shipUpgrades.shields.slug} />
              {!!canInstall && !shipUpgrades.shields.isDefault && (
                <UninstallWrapper>
                  <UninstallButton />
                </UninstallWrapper>
              )}
            </ComponentShields>
            <ComponentThrusters
              onMouseEnter={() => setHighlightPart("thrusters")}
              onMouseLeave={resetHighlightPart}
              onClick={() => handleUninstallUpgrade("thrusters")}
            >
              <ObjectIcon slug={shipUpgrades.thrusters.slug} />
              {!!canInstall && !shipUpgrades.thrusters.isDefault && (
                <UninstallWrapper>
                  <UninstallButton />
                </UninstallWrapper>
              )}
            </ComponentThrusters>
            <ComponentTargetingSystem
              onMouseEnter={() => setHighlightPart("targetingSystem")}
              onMouseLeave={resetHighlightPart}
              onClick={() => handleUninstallUpgrade("targetingSystem")}
            >
              <ObjectIcon slug={shipUpgrades.targetingSystem.slug} />
              {!!canInstall && !shipUpgrades.targetingSystem.isDefault && (
                <UninstallWrapper>
                  <UninstallButton />
                </UninstallWrapper>
              )}
            </ComponentTargetingSystem>
            <ComponentReactorCore
              onMouseEnter={() => setHighlightPart("reactorCore")}
              onMouseLeave={resetHighlightPart}
              onClick={() => handleUninstallUpgrade("reactorCore")}
            >
              <ObjectIcon slug={shipUpgrades.reactorCore.slug} />
              {!!canInstall && !shipUpgrades.reactorCore.isDefault && (
                <UninstallWrapper>
                  <UninstallButton />
                </UninstallWrapper>
              )}
            </ComponentReactorCore>
          </ComponentsWrapper>
        </Upgrades>
      </Section>
      <Section>
        <Title>Upgrade Inventory</Title>
        <Items>
          {inventory.upgrades.map((inventoryUpgrade) => {
            const upgrade = getUpgradeData(inventoryUpgrade.slug);
            if (!!upgrade) {
              return (
                <Item
                  key={inventoryUpgrade.slug}
                  onClick={() => handleInstallUpgrade(inventoryUpgrade.slug)}
                >
                  <ItemWrapper>
                    <ItemImageWrapper>
                      <ObjectIcon slug={inventoryUpgrade.slug} />
                      {!!canInstall && (
                        <InstallWrapper>
                          <InstallButton />
                        </InstallWrapper>
                      )}
                    </ItemImageWrapper>
                    <ItemInfo>
                      <Name>{upgrade.name}</Name>
                    </ItemInfo>
                  </ItemWrapper>
                  <Quantity>x{inventoryUpgrade.quantity}</Quantity>
                </Item>
              );
            } else {
              return <span key={inventoryUpgrade.slug} />;
            }
          })}
        </Items>
      </Section>
    </DataWrapper>
  );
}
