import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { getCharacter } from "redux/selectors";
import creditsImage from "assets/credits.svg";
import experienceImage from "assets/exp.svg";
import enhancementsImage from "assets/enhancements.svg";

import { COLORS } from "utils/constants";
import {
  getAreaData,
  getPilotData,
  getPlanetData,
  getShipData,
} from "utils/stats";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import Status from "components/base/Status";

interface IconProps {
  image: string;
}

const DataWrapper = styled.div`
  display: flex;
  gap: 5%;
`;

const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Vitals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;

const VitalWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const VitalIcon = styled.div<IconProps>`
  height: 16px;
  width: 16px;
  mask: url(${(props) => props.image}) no-repeat center;
`;

const VitalValue = styled.div``;

const ShipWrapper = styled(VitalWrapper)`
  color: #56d2ef;
`;
const PilotWrapper = styled(VitalWrapper)`
  color: #f98562;
`;

const LevelWrapper = styled(VitalWrapper)`
  color: ${COLORS.LEVELS};
`;

const LevelIcon = styled.div``;

const ExperienceWrapper = styled(VitalWrapper)`
  color: ${COLORS.EXPERIENCE};
`;

const ExperienceIcon = styled(VitalIcon)`
  background-color: ${COLORS.EXPERIENCE};
`;

const CreditsWrapper = styled(VitalWrapper)`
  color: ${COLORS.CREDITS};
`;

const CreditsIcon = styled(VitalIcon)`
  background-color: ${COLORS.CREDITS};
`;

const MedallionsWrapper = styled(VitalWrapper)`
  color: ${COLORS.ENHANCEMENTS};
`;

const MedallionsIcon = styled(VitalIcon)`
  background-color: ${COLORS.ENHANCEMENTS};
`;

const Atlas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const AtlasWrapper = styled(VitalWrapper)`
  color: ${COLORS.LOCATION};
`;

const SectorIcon = styled.div`
  font-size: 22px;
  line-height: 16px;
`;

export default function ShipworksRepair() {
  const {
    data: {
      ship,
      pilot,
      level,
      currentLevelExp,
      nextLevelExp,
      credits,
      location,
      baseStatPoints,
    },
  } = useSelector(getCharacter);

  const { name: shipClass, description: shipDesc } = getShipData(ship);
  const { name: pilotProfession, description: pilotDesc } = getPilotData(pilot);
  const { name: planet } = getPlanetData(location.planet);
  const { name: sector } = getAreaData(location.area);

  return (
    <DataWrapper>
      <Section>
        <Title>Core Data</Title>
        <Vitals>
          <Trigger>
            <InfoWrapper>
              <Name>Starship Class</Name>
              <ShipWrapper>
                <VitalValue>{shipClass}</VitalValue>
              </ShipWrapper>
            </InfoWrapper>
            <Tooltip>
              <TooltipName>Starship: {shipClass}</TooltipName>
              <TooltipDesc>{shipDesc}</TooltipDesc>
            </Tooltip>
          </Trigger>
          <Trigger>
            <InfoWrapper>
              <Name>Pilot Discipline</Name>
              <PilotWrapper>
                <VitalValue>{pilotProfession}</VitalValue>
              </PilotWrapper>
            </InfoWrapper>
            <Tooltip>
              <TooltipName>Pilot: {pilotProfession}</TooltipName>
              <TooltipDesc>{pilotDesc}</TooltipDesc>
            </Tooltip>
          </Trigger>
          <Trigger>
            <InfoWrapper>
              <Name>Level</Name>
              <LevelWrapper>
                <LevelIcon>✧</LevelIcon>
                <VitalValue>{level}</VitalValue>
              </LevelWrapper>
            </InfoWrapper>
            <Tooltip>
              <TooltipName>Level</TooltipName>
              <TooltipDesc>{`You are currently at level ${level}. Keep leveling up to strengthen your ship and learn more pilot skills!`}</TooltipDesc>
            </Tooltip>
          </Trigger>
          <Trigger>
            <InfoWrapper>
              <Name>Experience To Next Level</Name>
              <ExperienceWrapper>
                <ExperienceIcon image={experienceImage} />
                <VitalValue>{nextLevelExp - currentLevelExp}</VitalValue>
              </ExperienceWrapper>
            </InfoWrapper>
            <Tooltip>
              <TooltipName>Experience</TooltipName>
              <TooltipDesc>{`You only need ${
                nextLevelExp - currentLevelExp
              } experience to get to the next level. Gain more experience through battles to level up!`}</TooltipDesc>
            </Tooltip>
          </Trigger>
          <Trigger>
            <InfoWrapper>
              <Name>Credits</Name>
              <CreditsWrapper>
                <CreditsIcon image={creditsImage} />
                <VitalValue>{credits}</VitalValue>
              </CreditsWrapper>
            </InfoWrapper>
            <Tooltip>
              <TooltipName>Credits</TooltipName>
              <TooltipDesc>
                {`You have ${credits} credits to spend on buying repairs, items, upgrades, and more!`}
              </TooltipDesc>
            </Tooltip>
          </Trigger>
          <Trigger>
            <InfoWrapper>
              <Name>Medallions</Name>
              <MedallionsWrapper>
                <MedallionsIcon image={enhancementsImage} />
                <VitalValue>{baseStatPoints}</VitalValue>
              </MedallionsWrapper>
            </InfoWrapper>
            <Tooltip>
              <TooltipName>Medallions</TooltipName>
              <TooltipDesc>
                You earn medallions when you level up, more at higher levels.
              </TooltipDesc>
              <TooltipDesc>
                {`You can use your ${baseStatPoints} medallions to either strengthen your starship's core
                attributes or learn new pilot skills. You can do this at the
                nearest Shipworks or Pilot Academy in any city.`}
              </TooltipDesc>
            </Tooltip>
          </Trigger>
        </Vitals>
        <Title>Atlas</Title>
        <Atlas>
          <InfoWrapper>
            <Name>Current Planet</Name>
            <AtlasWrapper>
              <VitalValue>❍ {planet}</VitalValue>
            </AtlasWrapper>
          </InfoWrapper>
          <InfoWrapper>
            <Name>Sector</Name>
            <AtlasWrapper>
              <SectorIcon>⌔</SectorIcon>
              <VitalValue>{sector}</VitalValue>
            </AtlasWrapper>
          </InfoWrapper>
        </Atlas>
      </Section>
      <Section>
        <Title>Status</Title>
        <Status />
      </Section>
    </DataWrapper>
  );
}
