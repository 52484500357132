import { NPCModel } from "types";

import wickAvatarImage from "assets/npc-wick-avatar.svg";
import wickImage from "assets/npc-wick.svg";
import wittAvatarImage from "assets/npc-witt-avatar.svg";
import wittImage from "assets/npc-witt.svg";

interface NPCData {
  [key: string]: NPCModel;
}

export const NPCS: NPCData = {
  wick: {
    name: "Wick",
    avatar: wickAvatarImage,
    image: wickImage,
    position: 30,
    heightPercentage: 30,
    dialog: [
      "...Hmm? Wait, are you talking to me?",
      "Whoa, no one ever comes to me for actual visitor info... Wow, this is exciting! Shoot, I'm forgetting what I'm supposed to say, it's been so long.",
      "Okay, so hi, my name's Wick. You're currently in Bishop City, the capital city of planet Bishop. Er, that's obvious, duh.",
      "You'll find anything you need here, whether it's starship repairs, installing new starship upgrades, or pilot training.",
      "Leaving the city is a bit more dangerous. There's roving ships out in the Hollowlands that will want to fight you and loot you. Which sucks.",
      "Your ship looks like it can handle a little fighting though, as rough as it looks. Oh dang, that wasn't nice, sorry. Forgot how to talk to people.",
      "Yeah, I think that's all you need to know for now!",
      "...You wanna just hang and chat or whatever?",
    ],
  },
  witt: {
    name: "Witt",
    avatar: wittAvatarImage,
    image: wittImage,
    position: 30,
    heightPercentage: 30,
    dialog: [
      "...What? No, you must looking for Wick. I'm his sister, Witt.",
      "He's too chicken to come out here to the Hollowlands, ever since the Straylight clan took it over.",
      "They scare me a little too, but I do want one of those dope masks. Maybe they'll let me join one day.",
      "For now, I'll just hang out here and watch you get wrecked.",
      "I've heard beating them in battle can get you some nice upgrades for your ship.",
      "...But you're probably gonna get wrecked instead.",
    ],
  },
};
