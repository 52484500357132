import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import {
  CharacterBaseStats,
  NewCharacterScreens,
  PilotModel,
  PilotTypes,
  ShipModel,
  ShipTypes,
} from "types";
import { useImmer } from "use-immer";
import { getAllShipsData, getAllPilotsData, getPilotData } from "utils/stats";
import reaserAvatarImage from "assets/npc-reaser-avatar.svg";
import AvatarShip from "./AvatarShip";
import { getCharacter, getGameState, getShipUpgrades } from "redux/selectors";
import {
  floatAnimation,
  pilotScreenAnimation,
  upgradesScreenAnimation,
} from "components/base/styles";
import { BASE_STATS_INFO } from "data/baseStats";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import Skill from "components/base/Skill";
import {
  createCharacter,
  setNewCharacterScreen,
  validateCharacterName,
} from "redux/actions";

interface NewCharacterProps {
  onBack: () => void;
}

interface NewCharacterState {
  ship?: ShipTypes;
  pilot?: PilotTypes;
  userName?: string;
}

interface ShipWrapperProps {
  $color: string;
  $bgTime: number;
  $isSelected: boolean;
}

interface ShipProps {
  $floatTime: number;
}

interface BarIconProps {
  image: string;
  color: string;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

interface PilotWrapperProps {
  $isSelected: boolean;
}

interface ButtonProps {
  $disabled?: boolean;
}

const disabledMixin = css`
  pointer-events: none;
  opacity: 0.25;
`;

const NewCharacterWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #00000099;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  padding: 20px 0 40px;
  overflow-y: scroll;
  box-sizing: border-box;
`;

const CommanderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

const Commander = styled.div`
  display: flex;
  align-items: end;
  max-width: 75%;
`;

const CommanderImage = styled.img`
  height: 140px;
  display: block;
  margin-right: -40px;
  z-index: 1;
`;

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const CommanderName = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 40px;
`;

const Dialog = styled.div`
  background-color: #0e404299;
  padding: 20px;
  border-radius: 10px;
  color: #ddd;
  text-align: left;
  padding-left: 40px;
`;

const SelectWrapper = styled.div``;

// Ship Select

const ShipSelect = styled.div``;

const Ships = styled.div`
  padding: 0 2%;
  display: flex;
  align-items: stretch;
  gap: 1%;
`;

const ShipWrapper = styled.div<ShipWrapperProps>`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease-in;
  position: relative;
  cursor: pointer;
  background-color: #00000090;
  color: ${(props) => props.$color};
  background-size: 2em 2em;
  background-position: center center;
  background-image: linear-gradient(to right, #aaaaaa30 1px, transparent 1px),
    linear-gradient(to bottom, #aaaaaa30 1px, transparent 1px);
  animation: ${upgradesScreenAnimation} ${(props) => `${props.$bgTime}s`} linear
    infinite;
  border: 1px ${(props) => props.$color}90 solid;

  :hover {
    background-color: ${(props) => props.$color}20;
    border-color: ${(props) => props.$color};
  }

  ${(props) =>
    props.$isSelected &&
    `
    background-color: ${props.$color}20;
    border-color: ${props.$color};
    border-width: 4px;
    margin: -3px;
  `}
`;

const Ship = styled.div<ShipProps>`
  animation: ${floatAnimation} ${(props) => `${props.$floatTime}s`} ease-in-out
    infinite;
  position: relative;
  overflow: hidden;
  padding: 20px;
  padding-bottom: 10px;
  width: 60%;
`;

const ShipInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 20px;
  padding-top: 0;
`;

const ShipName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  filter: saturate(0.5) brightness(1.2);
  line-height: 1.2;
  margin-bottom: 10px;
`;

const ShipDesc = styled.div`
  color: white;
  font-size: 14px;
  padding-bottom: 20px;
  text-align: left;
`;

const ShipStats = styled.div`
  color: white;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
`;

const StatsTitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 5px;
  color: #2da5b2;
`;

const StatWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  gap: 4%;
`;

const StatIcon = styled.div<BarIconProps>`
  height: 14px;
  width: 14px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const StatBar = styled.div`
  height: 14px;
  transform: skew(-30deg);
  flex: 1 1;
`;

const BarValue = styled.div.attrs(({ percentage }: BarValueProps) => ({
  style: {
    width: `${percentage}%`,
  },
}))<BarValueProps>`
  height: 100%;
  z-index: 2;
  background-color: ${(props) => props.color};
`;

// Pilot Select

const PilotSelect = styled.div``;

const Pilots = styled.div`
  padding: 0 2%;
  display: flex;
  align-items: stretch;
  gap: 1%;
`;

const PilotWrapper = styled.div<PilotWrapperProps>`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease-in;
  position: relative;
  cursor: pointer;
  background-color: #00000090;
  background-size: 1em 1em;
  background-position: center center;
  background-image: linear-gradient(to bottom, #aaaaaa30 1px, transparent 1px);
  animation: ${pilotScreenAnimation} 2s linear infinite;
  border: 1px #ffffff90 solid;

  :hover {
    background-color: #2da5b220;
    border-color: #2da5b2;
  }

  ${(props) =>
    props.$isSelected &&
    `
    background-color: #2da5b220;
    border-color: #2da5b2;
    border-width: 4px;
    margin: -3px;
  `}
`;

const PilotImageWrapper = styled.div`
  padding: 20px;
  padding-bottom: 10px;
  position: relative;
`;

const PilotImage = styled.img`
  width: 60%;
  padding: 0 30px;
  box-shadow: 0 4px 6px -6px white;
`;

const PilotInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 20px;
  padding-top: 0;
`;

const PilotName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 10px;
  color: #fcd61f;
  flex-basis: 60px;
`;

const PilotDesc = styled.div`
  color: white;
  font-size: 14px;
  padding-bottom: 20px;
  text-align: left;
`;

const PilotSkills = styled.div`
  color: white;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
`;

const SkillsTitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  text-align: left;
  margin-bottom: 5px;
  color: #2da5b2;
`;

const SkillWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const SkillName = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`;

// Name Select

const NameSelect = styled.div``;

const NameInput = styled.input`
  background-color: #ffffff25;
  border: 1px white solid;
  padding: 15px;
  font-size: 18px;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  color: white;
  text-align: center;
  min-width: 300px;
  &::placeholder {
    color: white;
    opacity: 0.75;
  }
`;

const NameSelectMessage = styled.div`
  margin-top: 15px;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 15px;
  background-color: #af222240;
`;

// Save Auth

const SaveCharacter = styled.div``;

const CharacterSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const SelectedImages = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const SelectedShipWrapper = styled.div`
  width: 150px;
  height: 150px;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const SelectedShip = styled.div`
  animation: ${floatAnimation} 3s ease-in-out infinite;
  position: relative;
  width: 100%;
  height: 100%;
`;

const SelectedPilot = styled.div`
  width: 150px;
  height: 150px;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
`;

const SelectedPilotImage = styled.img`
  height: 100%;
`;

const SelectedName = styled.div`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  border-radius: 10px;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
`;

const SaveOption = styled.div``;

// Shared Navigation

const NavigationActions = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 20px;
  justify-content: center;
`;

const Button = styled.div<ButtonProps>`
  cursor: pointer;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  color: white;
  padding: 8px 20px;

  &:hover {
    filter: brightness(120%);
  }

  ${(props) => props.$disabled && disabledMixin}
`;

const BackButton = styled(Button)`
  background-color: #666666;
`;

const SelectButton = styled(Button)`
  background-color: #248791;
`;

const SCREENS = {
  shipSelect: {
    dialog:
      "Congratulations on graduating from Bishop Pilot Academy and becoming a full-fledged starship pilot! You have been the shining star of your entire cohort and I've always known you were destined for greatness. ...But remind me, which starship do you fly again?",
  },
  pilotSelect: {
    dialog:
      "And forgive me, which pilot discipline did you specialize in again?",
  },
  nameSelect: {
    dialog:
      "Oh right, of course. And I'm so embarrassed to have to ask, but... who are you again?",
  },
  saveCharacter: {
    dialog:
      "Yes, yes, I obviously knew all of that. I was just kidding around, a little graduation humor. Now get out of here and go on some amazing adventures out there in the vast galaxies... champ.",
  },
};

export default function NewCharacter({ onBack }: NewCharacterProps) {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const { data } = useSelector(getCharacter);
  const { newCharacterScreen, nameSelectMessage } = useSelector(getGameState);

  const [state, setState] = useImmer<NewCharacterState>({});

  const allShips = getAllShipsData();
  const allPilots = getAllPilotsData();

  const getSelectedShip = (ship?: ShipTypes) => {
    if (ship) {
      const defaultShipData = {
        ship: ship,
        shipUpgrades: getShipUpgrades(),
        ui: { animation: null, isMoving: false },
      };

      return defaultShipData;
    }
    return;
  };

  const getSelectedPilot = (pilot?: PilotTypes) => {
    if (pilot) {
      const { images } = getPilotData(pilot);

      return images.avatar;
    }
    return;
  };

  const goToScreen = (screen: NewCharacterScreens) => {
    dispatch(setNewCharacterScreen(screen));
  };

  const handleClickShip = (ship: ShipTypes) => {
    setState((draft: NewCharacterState) => {
      draft.ship = ship;
    });
  };

  const handleClickPilot = (pilot: PilotTypes) => {
    setState((draft: NewCharacterState) => {
      draft.pilot = pilot;
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((draft: NewCharacterState) => {
      draft.userName = event.target.value;
    });
  };

  const handleNameKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      validateUserName();
    }
  };

  const validateUserName = () => {
    if (!!state.userName) {
      dispatch(validateCharacterName(state.userName));
    }
  };

  const saveNewCharacter = () => {
    if (!!state.ship && !!state.pilot && !!state.userName) {
      // Save new character to Firebase
      dispatch(
        createCharacter({
          ship: state.ship,
          pilot: state.pilot,
          userName: state.userName,
        })
      );
    }
  };

  const STAT_ADJUST = 0.2;
  // Add a tiny bit to stat bars to make the lowest value visible
  const DEFAULT_FLOAT_TIME = 4;
  // Animation timing for different float speeds for each ship
  const DEFAULT_BG_TIME = 1;

  return (
    <NewCharacterWrapper>
      <CommanderWrapper>
        <Commander>
          <CommanderImage alt="commander image" src={reaserAvatarImage} />
          <DialogWrapper>
            <CommanderName>Pilot Commander Reaser</CommanderName>
            <Dialog>{SCREENS[newCharacterScreen].dialog}</Dialog>
          </DialogWrapper>
        </Commander>
      </CommanderWrapper>
      <SelectWrapper>
        {newCharacterScreen === "shipSelect" && (
          <ShipSelect>
            <Ships>
              {Object.entries(allShips).map(([key, value]) => {
                const shipSlug = key as ShipTypes;
                const shipInfo = value as ShipModel;

                const defaultShipData = {
                  ship: shipSlug,
                  shipUpgrades: getShipUpgrades(),
                  ui: { animation: null, isMoving: false },
                };

                const maxStatValue =
                  Math.max(...Object.values(shipInfo.startingBaseStats)) +
                  STAT_ADJUST;

                const floatTime =
                  DEFAULT_FLOAT_TIME - shipInfo.startingBaseStats.speed / 2;
                const bgTime =
                  DEFAULT_BG_TIME - shipInfo.startingBaseStats.speed / 5;

                return (
                  <ShipWrapper
                    key={shipSlug}
                    $color={shipInfo.color}
                    $bgTime={bgTime}
                    $isSelected={state.ship === shipSlug}
                    onClick={() => handleClickShip(shipSlug)}
                  >
                    <Ship $floatTime={floatTime}>
                      <AvatarShip
                        highlightPart={null}
                        shipData={defaultShipData}
                      />
                    </Ship>
                    <ShipInfo>
                      <ShipName>{shipInfo.name}</ShipName>
                      <ShipDesc>{shipInfo.description}</ShipDesc>
                      <ShipStats>
                        <StatsTitle>Base Stats</StatsTitle>
                        {BASE_STATS_INFO.map((stat) => {
                          const statSlug =
                            stat.slug as keyof CharacterBaseStats;
                          const statValue =
                            shipInfo.startingBaseStats[statSlug] + STAT_ADJUST;

                          return (
                            <Trigger key={statSlug}>
                              <StatWrapper>
                                <StatIcon
                                  image={stat.image}
                                  color={stat.color}
                                />
                                <StatBar>
                                  <BarValue
                                    percentage={
                                      (statValue / maxStatValue) * 100
                                    }
                                    color={stat.color}
                                  ></BarValue>
                                </StatBar>
                              </StatWrapper>
                              <Tooltip isAbove>
                                <TooltipName>{stat.name}</TooltipName>
                                <TooltipDesc>{stat.description}</TooltipDesc>
                              </Tooltip>
                            </Trigger>
                          );
                        })}
                      </ShipStats>
                    </ShipInfo>
                  </ShipWrapper>
                );
              })}
            </Ships>
            <NavigationActions>
              <BackButton onClick={onBack}>Back</BackButton>
              <SelectButton
                $disabled={!state.ship}
                onClick={() => goToScreen("pilotSelect")}
              >
                Select
              </SelectButton>
            </NavigationActions>
          </ShipSelect>
        )}
        {newCharacterScreen === "pilotSelect" && (
          <PilotSelect>
            <Pilots>
              {Object.entries(allPilots).map(([key, value]) => {
                const pilotSlug = key as PilotTypes;
                const pilotInfo = value as PilotModel;

                return (
                  <PilotWrapper
                    key={pilotSlug}
                    $isSelected={state.pilot === pilotSlug}
                    onClick={() => handleClickPilot(pilotSlug)}
                  >
                    <PilotImageWrapper>
                      <PilotImage src={pilotInfo.images.avatar} />
                    </PilotImageWrapper>
                    <PilotInfo>
                      <PilotName>{pilotInfo.name}</PilotName>
                      <PilotDesc>{pilotInfo.description}</PilotDesc>
                      <PilotSkills>
                        <SkillsTitle>Starting Skill</SkillsTitle>
                        {pilotInfo.skills
                          .filter((skill) => !!skill.isDefault)
                          .map((skill) => {
                            return (
                              <SkillWrapper key={skill.slug}>
                                <Skill skill={skill} characterData={data} />
                                <SkillName>{skill.name}</SkillName>
                              </SkillWrapper>
                            );
                          })}
                      </PilotSkills>
                    </PilotInfo>
                  </PilotWrapper>
                );
              })}
            </Pilots>
            <NavigationActions>
              <BackButton onClick={() => goToScreen("shipSelect")}>
                Back
              </BackButton>
              <SelectButton
                $disabled={!state.pilot}
                onClick={() => goToScreen("nameSelect")}
              >
                Select
              </SelectButton>
            </NavigationActions>
          </PilotSelect>
        )}
        {newCharacterScreen === "nameSelect" && (
          <NameSelect>
            <NameInput
              ref={inputRef}
              placeholder="Enter your character name"
              autoFocus
              value={state.userName}
              onChange={handleNameChange}
              onKeyDown={handleNameKeyDown}
              maxLength={15}
            />
            {!!nameSelectMessage && (
              <NameSelectMessage>{nameSelectMessage}</NameSelectMessage>
            )}
            <NavigationActions>
              <BackButton onClick={() => goToScreen("pilotSelect")}>
                Back
              </BackButton>
              <SelectButton
                $disabled={!state.userName}
                onClick={validateUserName}
              >
                Select
              </SelectButton>
            </NavigationActions>
          </NameSelect>
        )}
        {newCharacterScreen === "saveCharacter" && (
          <SaveCharacter>
            <CharacterSummary>
              <SelectedImages>
                <SelectedShipWrapper>
                  <SelectedShip>
                    <AvatarShip
                      highlightPart={null}
                      shipData={getSelectedShip(state.ship)}
                    />
                  </SelectedShip>
                </SelectedShipWrapper>
                <SelectedPilot>
                  <SelectedPilotImage src={getSelectedPilot(state.pilot)} />
                </SelectedPilot>
              </SelectedImages>
              <SelectedName>{state.userName}</SelectedName>
            </CharacterSummary>
            <SaveOption></SaveOption>
            <NavigationActions>
              <BackButton onClick={() => goToScreen("nameSelect")}>
                Back
              </BackButton>
              <SelectButton
                $disabled={!state.userName}
                onClick={saveNewCharacter}
              >
                Onward
              </SelectButton>
            </NavigationActions>
          </SaveCharacter>
        )}
      </SelectWrapper>
    </NewCharacterWrapper>
  );
}
