import styled from "styled-components";

interface TooltipProps {
  text: string;
}

const TooltipWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
`;

const Tooltip = ({ text }: TooltipProps) => {
  return <TooltipWrapper>{text}</TooltipWrapper>;
};

export default Tooltip;
