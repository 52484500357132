import { PilotModel, PilotTypes } from "types";

import weaponsSpecialistAvatarImage from "assets/pilot-weapons-specialist.svg";
import stuntPilotAvatarImage from "assets/pilot-stunt-pilot.svg";
import mechanicalEngineerAvatarImage from "assets/pilot-mechanical-engineer.svg";
import physicistAvatarImage from "assets/pilot-physicist.svg";
import sharpshooterAvatarImage from "assets/pilot-sharpshooter.svg";
import shieldBreakerSkillImage from "assets/skill-shieldbreaker.svg";
import scattershotSkillImage from "assets/skill-scattershot.svg";
import powerSurgeSkillImage from "assets/skill-powersurge.svg";
import shieldBypassSkillImage from "assets/skill-shieldbypass.svg";
import hyperShiftSkillImage from "assets/skill-hypershift.svg";
import phantomStrikeSkillImage from "assets/skill-phantomstrike.svg";
import tripleShotSkillImage from "assets/skill-tripleshot.svg";
import emergencyRepairsSkillImage from "assets/skill-emergencyrepairs.svg";
import shieldRestoreSkillImage from "assets/skill-shieldrestore.svg";
import shieldStormSkillImage from "assets/skill-shieldstorm.svg";
import energyBlastSkillImage from "assets/skill-energyblast.svg";
import reactorOverloadSkillImage from "assets/skill-reactoroverload.svg";
import systemsDecaySkillImage from "assets/skill-systemsdecay.svg";
import skillsRechargeSkillImage from "assets/skill-skillsrecharge.svg";
import cantMissSkillImage from "assets/skill-cantmiss.svg";
import eagleEyeSkillImage from "assets/skill-eagleeye.svg";
import blastEchoSkillImage from "assets/skill-blastecho.svg";

import {
  energyBlastSaga,
  repairSaga,
  rapidFireSaga,
  pressurePointSaga,
  shieldBreakerSaga,
  shieldBypassSaga,
  eagleEyeSaga,
  hyperShiftSaga,
  systemsDecaySaga,
  skillsRechargeSaga,
  reactorOverloadSaga,
  phantomStrikeSaga,
  shieldStormSaga,
  powerSurgeSaga,
  shieldRestoreSaga,
  scattershotSaga,
  blastEchoSaga,
} from "redux/sagas/skill";
import {
  getEagleEyeDamage,
  getEmergencyRepairHealth,
  getEnergyBlastDamage,
  getPressurePointDamage,
  getRapidFireDamage,
  getShieldBreakerDamage,
  getShieldBypassDamage,
  getHyperShiftDamage,
  getSystemsDecayDamage,
  getReactorOverloadDamage,
  getPhantomStrikeDamage,
  getShieldStormDamage,
  getPowerSurgeDamage,
  getShieldRestoreAmount,
  getScattershotDamage,
  getBlastEchoDamage,
} from "utils/stats";

export const PILOTS: Record<PilotTypes, PilotModel> = {
  weaponsSpecialist: {
    name: "Weapons Specialist",
    description:
      "Weapons Specialists love working on their ship's weapons (maybe too much). Their skills are all about doing high damage.",
    images: {
      avatar: weaponsSpecialistAvatarImage,
    },
    skills: [
      {
        name: "Shield Breaker",
        slug: "shieldBreaker",
        image: shieldBreakerSkillImage,
        description:
          "Set yourself up to inflict greater damage later by weakening enemy shields by double the maximum amount.",
        values: ({ characterData: { stats } }) => {
          const { attackWeakenParts } = getShieldBreakerDamage(stats);
          return `Weaken shields by <strong>${attackWeakenParts}</strong>.`;
        },
        medallions: 0,
        isDefault: true,
        saga: shieldBreakerSaga,
        recharge: 10,
        hasAntimatterBoost: true,
      },
      {
        name: "Scattershot",
        slug: "scattershot",
        image: scattershotSkillImage,
        description:
          "Randomly weaken an enemy component by 3x the maximum amount.",
        values: ({ characterData: { stats } }) => {
          const { attackWeakenParts } = getScattershotDamage(stats);
          return `Weaken a random component by <strong>${attackWeakenParts}</strong>.`;
        },
        medallions: 6,
        isDefault: false,
        saga: scattershotSaga,
        recharge: 10,
        hasAntimatterBoost: true,
      },
      {
        name: "Power Surge",
        slug: "powerSurge",
        image: powerSurgeSkillImage,
        description:
          "Unleash a potentially devastating attack fueled by the accumulated damage you've inflicted. Built up maximum damage resets with each use.",
        values: ({
          characterData: { stats, skillsValues },
          fightData: {
            opponent: { damage: opponentDamages },
          },
        }) => {
          const { maxAttackDamage } = getPowerSurgeDamage(
            stats,
            opponentDamages,
            skillsValues
          );
          return `Inflict <strong>0</strong> to <strong>${maxAttackDamage}</strong> damage.`;
        },
        medallions: 8,
        isDefault: false,
        saga: powerSurgeSaga,
        recharge: 12,
        hasAntimatterBoost: true,
      },
    ],
  },
  stuntPilot: {
    name: "Stunt Pilot",
    description:
      "Stunt Pilots are skilled in agile, nimble flying. This allows them to attack more often and deftly dodge attacks.",
    images: {
      avatar: stuntPilotAvatarImage,
    },
    skills: [
      {
        name: "Rapid Fire",
        slug: "rapidFire",
        image: tripleShotSkillImage,
        description:
          "Use speed to your advantage by quickly firing three average damage shots.",
        values: ({ characterData: { stats } }) => {
          const { attackDamage, attackWeakenParts } = getRapidFireDamage(stats);
          return `Inflict <strong>${attackDamage}</strong> damage and weaken speed by <strong>${attackWeakenParts}</strong> with each shot.`;
        },
        medallions: 0,
        isDefault: true,
        saga: rapidFireSaga,
        recharge: 8,
        hasAntimatterBoost: true,
      },
      {
        name: "Phantom Strike",
        slug: "phantomStrike",
        image: phantomStrikeSkillImage,
        description:
          "Attack using a lightning fast maneuver to deal a maximum damage hit without using up a turn.",
        values: ({ characterData: { stats } }) => {
          const { attackDamage } = getPhantomStrikeDamage(stats);
          return `Inflict <strong>${attackDamage}</strong> damage.`;
        },
        medallions: 6,
        isDefault: false,
        saga: phantomStrikeSaga,
        recharge: 8,
        hasAntimatterBoost: true,
      },
      {
        name: "Hyper Shift",
        slug: "hyperShift",
        image: hyperShiftSkillImage,
        description:
          "Use up your next consecutive turns, each of which will double your maximum damage, to inflict devastating damage.",
        values: ({
          characterData: { stats },
          fightData: { nextConsecutiveTurns },
        }) => {
          const { attackDamage } = getHyperShiftDamage(
            stats,
            nextConsecutiveTurns
          );
          return `Inflict <strong>${attackDamage}</strong> damage by using up your ${nextConsecutiveTurns} next consecutive turns.`;
        },
        medallions: 12,
        isDefault: false,
        saga: hyperShiftSaga,
        recharge: 10,
        hasAntimatterBoost: true,
      },
    ],
  },
  mechanicalEngineer: {
    name: "Mechanical Engineer",
    description:
      "Mechanical Engineers are skilled in starship repair and enhancing their shields. Defense wins championships.",
    images: {
      avatar: mechanicalEngineerAvatarImage,
    },
    skills: [
      {
        name: "Emergency Repairs",
        slug: "emergencyRepairs",
        image: emergencyRepairsSkillImage,
        description: "Quickly repair a portion of your overall ship health.",
        values: ({ characterData: { stats } }) => {
          const { healPercentage } = getEmergencyRepairHealth(stats);
          return `Repair <strong>${Math.round(
            healPercentage * 100
          )}%</strong> of your ship health.`;
        },
        medallions: 0,
        isDefault: true,
        saga: repairSaga,
        recharge: 10,
        hasAntimatterBoost: true,
      },
      {
        name: "Shield Restore",
        slug: "shieldRestore",
        image: shieldRestoreSkillImage,
        description:
          "Restore a portion of your ship's shields and its resilience.",
        values: ({ characterData: { stats, totalBaseStats } }) => {
          const { restorePercentage } = getShieldRestoreAmount(
            stats,
            totalBaseStats
          );
          return `Restore <strong>${Math.round(
            restorePercentage * 100
          )}%</strong> of your ship's resilience.`;
        },
        medallions: 12,
        isDefault: false,
        saga: shieldRestoreSaga,
        recharge: 5,
        hasAntimatterBoost: true,
      },
      {
        name: "Shield Storm",
        slug: "shieldStorm",
        image: shieldStormSkillImage,
        description:
          "Convert deflected damage into a concentrated and devastating counterattack. Built up damage resets with each use.",
        values: ({
          characterData: {
            stats,
            ui: { damage: characterDamages },
            skillsValues,
          },
        }) => {
          const { attackDamage } = getShieldStormDamage(
            stats,
            characterDamages,
            skillsValues
          );
          return `Inflict <strong>${attackDamage}</strong> damage.`;
        },
        medallions: 7,
        isDefault: false,
        saga: shieldStormSaga,
        recharge: 10,
        hasAntimatterBoost: true,
      },
    ],
  },
  physicist: {
    name: "Physicist",
    description:
      "Physicists are nerds who mainly focus on antimatter energy. This gives their ship all sorts of interesting abilities.",
    images: {
      avatar: physicistAvatarImage,
    },
    skills: [
      {
        name: "Energy Blast",
        slug: "energyBlast",
        image: energyBlastSkillImage,
        description:
          "Release a concentrated burst of antimatter energy, scaled to 5x of your current antimatter stat.",
        values: ({ characterData: { stats, currentBaseStats } }) => {
          const { attackDamage } = getEnergyBlastDamage(
            stats,
            currentBaseStats
          );
          return `Inflict <strong>${attackDamage}</strong> damage.`;
        },
        medallions: 0,
        isDefault: true,
        saga: energyBlastSaga,
        recharge: 8,
        hasAntimatterBoost: true,
      },
      {
        name: "Reactor Overload",
        slug: "reactorOverload",
        image: reactorOverloadSkillImage,
        description:
          "Consume all of your antimatter reserves to unleash a cataclysmic energy explosion, scaled to 10x of your current antimatter stat.",
        values: ({ characterData: { stats, currentBaseStats } }) => {
          const { attackDamage } = getReactorOverloadDamage(
            stats,
            currentBaseStats
          );
          return `Inflict <strong>${attackDamage}</strong> damage.`;
        },
        medallions: 8,
        isDefault: false,
        saga: reactorOverloadSaga,
        recharge: 16,
        hasAntimatterBoost: true,
      },
      {
        name: "Shield Bypass",
        slug: "shieldBypass",
        image: shieldBypassSkillImage,
        description:
          "Fire a specialized antimatter beam that bypasses enemy shields, scaled to 2x of your current antimatter stat.",
        values: ({ characterData: { stats, currentBaseStats } }) => {
          const { attackDamage } = getShieldBypassDamage(
            stats,
            currentBaseStats
          );
          return `Inflict <strong>${attackDamage}</strong> guaranteed damage, if attack is not dodged.`;
        },
        medallions: 6,
        isDefault: false,
        saga: shieldBypassSaga,
        recharge: 12,
        hasAntimatterBoost: true,
      },
      {
        name: "Systems Decay",
        slug: "systemsDecay",
        image: systemsDecaySkillImage,
        description:
          "Release an antimatter surge that weakens all enemy systems.",
        values: ({ characterData: { stats } }) => {
          const { attackWeakenParts } = getSystemsDecayDamage(stats);
          return `Weaken all enemy stats by <strong>${attackWeakenParts}</strong>.`;
        },
        medallions: 6,
        isDefault: false,
        saga: systemsDecaySaga,
        recharge: 10,
        hasAntimatterBoost: true,
      },
      {
        name: "Skills Recharge",
        slug: "skillsRecharge",
        image: skillsRechargeSkillImage,
        description:
          "Immediately recharge all of your other skills with a jolt of antimatter.",
        medallions: 5,
        isDefault: false,
        saga: skillsRechargeSaga,
        recharge: 10,
        hasAntimatterBoost: false,
      },
    ],
  },
  sharpshooter: {
    name: "Sharpshooter",
    description:
      "Sharpshooters will hit you where it hurts the most. Some call it luck, they call it skill. Kind of obnoxious.",
    images: {
      avatar: sharpshooterAvatarImage,
    },
    skills: [
      {
        name: "Pressure Point",
        slug: "pressurePoint",
        image: cantMissSkillImage,
        description:
          "Strike your enemy's weak spot over and over, dealing minimum damage first then doubling with every hit, up to 4 times.",
        values: ({ characterData: { stats, skillsValues } }) => {
          const { attackDamage } = getPressurePointDamage(stats, skillsValues);
          return `Inflict <strong>${attackDamage}</strong> damage with this next shot.`;
        },
        medallions: 0,
        isDefault: true,
        saga: pressurePointSaga,
        recharge: 10,
        hasAntimatterBoost: true,
      },
      {
        name: "Eagle Eye",
        slug: "eagleEye",
        image: eagleEyeSkillImage,
        description: "Deliver a critical hit attack that cannot be dodged.",
        values: ({ characterData: { stats } }) => {
          const { attackDamage, attackWeakenParts } = getEagleEyeDamage(stats);
          return `Inflict <strong>${attackDamage}</strong> damage and weaken targeting by <strong>${attackWeakenParts}</strong>.`;
        },
        medallions: 10,
        isDefault: false,
        saga: eagleEyeSaga,
        recharge: 10,
        hasAntimatterBoost: true,
      },
      {
        name: "Blast Echo",
        slug: "blastEcho",
        image: blastEchoSkillImage,
        description:
          "Re-target the last enemy part to be weakened and do it again by triple the average amount.",
        values: ({
          characterData: { stats },
          fightData: {
            opponent: { damage: opponentDamages },
          },
        }) => {
          const { attackWeakenParts, lastWeakenedBaseStat } =
            getBlastEchoDamage(stats, opponentDamages);
          return `Weaken <strong>${lastWeakenedBaseStat}</strong> by <strong>${attackWeakenParts}</strong>.`;
        },
        medallions: 7,
        isDefault: false,
        saga: blastEchoSaga,
        recharge: 10,
        hasAntimatterBoost: true,
      },
    ],
  },
};
