import React from "react";
import styled from "styled-components";

import { getBattleRatingData } from "utils/stats";
import { BattleRatings } from "types";
import Tooltip, { TooltipDesc, TooltipName, Trigger } from "./Tooltip";

interface BattleRatingProps {
  rating: BattleRatings;
}

interface BattleRatingNameProps {
  color: string;
}

const BattleRatingWrapper = styled.div`
  display: flex;
  gap: 5px;
  text-align: right;
  align-items: center;
`;

const BattleRatingImage = styled.img`
  height: 30px;
`;

const BattleRatingName = styled.div<BattleRatingNameProps>`
  text-transform: uppercase;
  font-weight: 600;
  color: ${(props) => props.color};
`;

export default function BattleRatingSmall({ rating }: BattleRatingProps) {
  const battleRatingData = getBattleRatingData(rating);

  return (
    <Trigger>
      <BattleRatingWrapper>
        <BattleRatingName color={battleRatingData.color}>
          {battleRatingData.name}
        </BattleRatingName>
        <BattleRatingImage src={battleRatingData.image} />
      </BattleRatingWrapper>
      <Tooltip>
        <TooltipName>Battle Rating: {battleRatingData.name}</TooltipName>
        <TooltipDesc>
          {`You are rewarded this item if you achieve a ${battleRatingData.name} 
          battle rating in a fight. You also get all the other items below this
          rating.`}
        </TooltipDesc>
        <TooltipDesc>
          {`${battleRatingData.name} battle rating means: ${battleRatingData.description}`}
        </TooltipDesc>
      </Tooltip>
    </Trigger>
  );
}
