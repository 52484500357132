import React from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { getCharacter } from "redux/selectors";
import enhancementsImage from "assets/enhancements.svg";
import Skill from "components/base/Skill";
import Medallions from "components/base/Medallions";
import creditsImage from "assets/credits.svg";
import { COLORS } from "utils/constants";
import { pilotScreenAnimation } from "components/base/styles";
import { getPilotData, getSkillsResetCost } from "utils/stats";
import { buyResetSkills, buyTrainSkill } from "redux/actions";

interface PilotSkillsProps {
  canTrain: boolean;
}

interface IconProps {
  image: string;
}

interface SkillWrapperProps {
  trainable: boolean;
}

interface ResetButtonProps {
  $isDisabled: boolean;
}

const disabledMixin = css`
  opacity: 0.3;
  pointer-events: none;
`;

const DataWrapper = styled.div`
  display: flex;
  gap: 5%;
`;

const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const PilotInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PilotImageWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 1px #43fffa solid;
  background-color: #000000;
  background-size: 0.3em 0.3em;
  background-position: center center;
  background-image: linear-gradient(to bottom, #aaaaaa50 1px, transparent 1px);
  animation: ${pilotScreenAnimation} 4s linear infinite;
`;

const PilotImage = styled.img`
  width: 50%;
  display: block;
  mix-blend-mode: color-dodge;
  z-index: 2;
`;

const PilotName = styled.div`
  font-size: 4vw;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  mix-blend-mode: hard-light;
  color: #fcd61f;
  text-align: center;
`;

const PilotDesc = styled.div``;

const Skills = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const SkillInfo = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

const LockedSkillInfo = styled(SkillInfo)`
  opacity: 0.5;
`;

const TrainText = styled.div`
  display: none;
`;
const CostText = styled.div``;

const trainableMixin = css`
  :hover ${LockedSkillInfo} {
    opacity: 1;
  }

  :hover ${TrainText} {
    display: block;
  }

  :hover ${CostText} {
    display: none;
  }
`;

const SkillWrapper = styled.div<SkillWrapperProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  ${(props) => !!props.trainable && trainableMixin}
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;

const Unlocked = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.UNLOCKED};
`;

const TrainCostWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementsIcon = styled.div<IconProps>`
  width: 16px;
  height: 16px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementCost = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Credits = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.CREDITS};
  align-items: center;
`;

const CreditsIcon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

const ResetButton = styled.div<ResetButtonProps>`
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  background-color: #0e4042;
  padding: 8px 16px;
  color: white;
  margin-top: 30px;
  align-self: end;
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover {
    filter: brightness(120%);
  }

  ${(props) => props.$isDisabled && disabledMixin}
`;

export default function PilotSkills({ canTrain = false }: PilotSkillsProps) {
  const dispatch = useDispatch();
  const { data: characterData } = useSelector(getCharacter);
  const { pilot, skills } = characterData;
  const {
    name: pilotName,
    images: pilotImages,
    description: pilotDescription,
  } = getPilotData(pilot);

  const resetCost = getSkillsResetCost(skills);
  const canReset = !!resetCost;

  const trainSkill = (skill: string) => {
    if (!!canTrain) {
      dispatch(buyTrainSkill({ skill }));
    }
  };

  return (
    <DataWrapper>
      <Section>
        <Title>Pilot Info</Title>
        <PilotInfo>
          <PilotImageWrapper>
            <PilotImage src={pilotImages.avatar} />
            <PilotName>{pilotName}</PilotName>
          </PilotImageWrapper>
          <PilotDesc>{pilotDescription}</PilotDesc>
        </PilotInfo>
      </Section>
      <Section>
        <Title>Skills List</Title>
        <Medallions />
        <Skills>
          {skills
            .filter((skill) => !!skill.isTrained)
            .map((skill) => {
              return (
                <SkillWrapper key={skill.slug} trainable={false}>
                  <Skill
                    key={skill.slug}
                    skill={skill}
                    characterData={characterData}
                  />
                  <SkillInfo>
                    <Name>{skill.name}</Name>
                    <Unlocked>Unlocked</Unlocked>
                  </SkillInfo>
                </SkillWrapper>
              );
            })}
          {skills
            .filter((skill) => !skill.isTrained)
            .map((skill) => {
              return (
                <SkillWrapper
                  key={skill.slug}
                  trainable={canTrain}
                  onClick={() => trainSkill(skill.slug)}
                >
                  <Skill
                    key={skill.slug}
                    skill={skill}
                    characterData={characterData}
                  />
                  <LockedSkillInfo>
                    <Name>{skill.name}</Name>
                    <TrainCostWrapper>
                      <TrainText>Learn Skill:</TrainText>
                      <CostText>Cost:</CostText>
                      <EnhancementCost>
                        <EnhancementsIcon image={enhancementsImage} />
                        {skill.medallions}
                      </EnhancementCost>
                    </TrainCostWrapper>
                  </LockedSkillInfo>
                </SkillWrapper>
              );
            })}
        </Skills>
        {!!canTrain && (
          <ResetButton
            onClick={() => dispatch(buyResetSkills())}
            $isDisabled={!canReset}
          >
            Reset Medallions
            <Credits>
              <CreditsIcon image={creditsImage} />
              {resetCost}
            </Credits>
          </ResetButton>
        )}
      </Section>
    </DataWrapper>
  );
}
