import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "redux/reducers";
import rootSaga from "redux/sagas";
import {
  DB_THROTTLE_THRESHOLD,
  saveCharacterData,
  throttle,
} from "utils/storage/firebase";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, {}, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

const saveState = () => {
  const gameState = store.getState().game;
  const characterState = store.getState().character;
  const fightState = store.getState().fight;
  if (gameState.isGameModeActive && characterState.userId) {
    saveCharacterData(characterState.userId, characterState.data, fightState);
  }
};

const throttledSaveState = throttle(saveState, DB_THROTTLE_THRESHOLD);

store.subscribe(throttledSaveState);

export default store;
