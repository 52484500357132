import { call, put, select, takeLeading } from "redux-saga/effects";

import {
  CharacterBaseStats,
  GetObjectPayload,
  InventoryModel,
  PartTypes,
} from "types";

import {
  employItem,
  getItem,
  getUpgrade,
  installUpgrade,
  uninstallUpgrade,
  removeItem,
  removeUpgrade,
  setInstalledUpgrade,
  setUninstalledUpgrade,
  showMessage,
  restorePart,
} from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import {
  getItemData,
  getPartsToRestore,
  getUpgradeData,
  isItemType,
} from "utils/stats";
import { gainExpSaga, healSaga } from "./character";
import { BASE_STATS_INFO } from "data/baseStats";

export function* getObjectsSaga({
  payload: objectsGained,
}: {
  payload: GetObjectPayload[];
}) {
  for (const object of objectsGained) {
    const isItem = isItemType(object.slug);
    if (isItem) {
      yield put(getItem({ slug: object.slug, quantity: object.quantity }));
    } else {
      yield put(getUpgrade({ slug: object.slug, quantity: object.quantity }));
    }
  }
}

export function* employItemSaga({ payload }: { payload: string }) {
  const {
    data: { inventory },
  } = yield select(getCharacter);
  const item = getItemData(payload);
  if (!item) {
    return;
  }

  // Make sure the player actually has this item
  const inventoryItem = inventory.items.find(
    (item: InventoryModel) => item.slug === payload
  );
  if (!inventoryItem) {
    return;
  }

  try {
    // Use item by calling its action and value
    // Only call() allows for handling errors, put() doesn't
    yield call(item.action, item.payload);

    // If used successfully, remove item
    yield put(removeItem({ slug: payload, quantity: 1 }));
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export function* employRepairKitSaga(healAmount: number) {
  const {
    data: { health, stats },
  } = yield select(getCharacter);

  // If health is already full, don't use up repair kit
  if (health >= stats.maxHealth) {
    throw new Error(`Your starship is already fully repaired, chill out`);
  }

  yield call(healSaga, healAmount);
}

export function* employRestoreKitSaga(restoreAmount: number) {
  const {
    data: { weakenedBaseStats },
  } = yield select(getCharacter);
  const { status } = yield select(getFight);

  if (status !== "fighting") {
    throw new Error(`You can only restore your parts while in battle`);
  }

  let restoreAmountRemaining = restoreAmount;

  const partsToRestore = getPartsToRestore(weakenedBaseStats);

  if (partsToRestore.length === 0) {
    throw new Error(
      `Your starship's parts are already fully restored, chill out`
    );
  }

  for (const [key, value] of partsToRestore) {
    const weakenedStat = key as keyof CharacterBaseStats;
    const weakenedAmount = value as number;

    if (restoreAmountRemaining <= 0) {
      break;
    }

    if (restoreAmountRemaining > weakenedAmount) {
      // Save remaining restore amount for next stat
      yield put(
        restorePart({
          baseStatRestored: weakenedStat,
          restorePartAmount: weakenedAmount,
        })
      );
      restoreAmountRemaining -= weakenedAmount;
    } else {
      // Use up rest of restore amount
      yield put(
        restorePart({
          baseStatRestored: weakenedStat,
          restorePartAmount: restoreAmountRemaining,
        })
      );
      restoreAmountRemaining = 0;
    }
  }
}

export function* employExpBoosterSaga(expAmount: number) {
  yield call(gainExpSaga, expAmount);
}

export function* installUpgradeSaga({ payload }: { payload: string }) {
  const {
    data: { inventory, installedUpgrades, totalBaseStats },
  } = yield select(getCharacter);
  const upgrade = getUpgradeData(payload);
  if (!upgrade) {
    return;
  }

  // Make sure the player actually has this item
  const inventoryUpgrade = inventory.upgrades.find(
    (upgrade: InventoryModel) => upgrade.slug === payload
  );
  if (!inventoryUpgrade) {
    return;
  }

  try {
    const { requirement } = upgrade;
    const baseStatReq = BASE_STATS_INFO.find(
      (stat) => stat.slug === requirement.baseStat
    );
    const baseStatValue = totalBaseStats[requirement.baseStat];
    if (baseStatReq && baseStatValue < requirement.value) {
      throw new Error(
        `Requires ${baseStatReq.name} ${requirement.value} or higher to install.`
      );
    }

    const existingUpgrade = installedUpgrades[upgrade.part];
    if (existingUpgrade) {
      // Uninstall existing upgrade and put back in inventory
      yield put(getUpgrade({ slug: existingUpgrade, quantity: 1 }));
    }

    // Install new upgrade
    yield put(
      setInstalledUpgrade({
        part: upgrade.part,
        slug: payload,
      })
    );

    // If installed successfully, remove upgrade from inventory
    yield put(removeUpgrade({ slug: payload, quantity: 1 }));
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export function* uninstallUpgradeSaga({
  payload: part,
}: {
  payload: PartTypes;
}) {
  const {
    data: { installedUpgrades },
  } = yield select(getCharacter);
  const existingUpgrade = installedUpgrades[part];
  if (!existingUpgrade) {
    return;
  }

  try {
    // Uninstall existing upgrade
    yield put(setUninstalledUpgrade(part));

    // Put uninstalled upgrade put back in inventory
    yield put(getUpgrade({ slug: existingUpgrade, quantity: 1 }));
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export default function* itemSagas() {
  yield takeLeading(employItem, employItemSaga);
  yield takeLeading(installUpgrade, installUpgradeSaga);
  yield takeLeading(uninstallUpgrade, uninstallUpgradeSaga);
}
