import { MobModel } from "types";

import { BASE_STATS } from "data/baseStats";
import grenShipImage from "assets/mob-gren-ship.svg";
import grenPilotImage from "assets/mob-gren-pilot.svg";
import ardinShipImage from "assets/mob-ardin-ship.svg";
import ardinPilotImage from "assets/mob-ardin-pilot.svg";
import sharperShipImage from "assets/mob-sharper-ship.svg";
import sharperPilotImage from "assets/mob-sharper-pilot.svg";
import crossShipImage from "assets/mob-cross-ship.svg";
import crossPilotImage from "assets/mob-cross-pilot.svg";
import ketherShipImage from "assets/mob-kether-ship.svg";
import ketherPilotImage from "assets/mob-kether-pilot.svg";
import gammaShipImage from "assets/mob-gamma-ship.svg";
import gammaPilotImage from "assets/mob-gamma-pilot.svg";
import lockheedShipImage from "assets/mob-lockheed-ship.svg";
import lockheedPilotImage from "assets/mob-lockheed-pilot.svg";
import rinconShipImage from "assets/mob-rincon-ship.svg";
import rinconPilotImage from "assets/mob-rincon-pilot.svg";
import hadronShipImage from "assets/mob-hadron-ship.svg";
import hadronPilotImage from "assets/mob-hadron-pilot.svg";
import acelaShipImage from "assets/mob-acela-ship.svg";
import acelaPilotImage from "assets/mob-acela-pilot.svg";
import megamaShipImage from "assets/mob-megama-ship.svg";
import megamaPilotImage from "assets/mob-megama-pilot.svg";
import breedenShipImage from "assets/mob-breeden-ship.svg";
import breedenPilotImage from "assets/mob-breeden-pilot.svg";
import revinShipImage from "assets/mob-revin-ship.svg";
import revinPilotImage from "assets/mob-revin-pilot.svg";
import valeraShipImage from "assets/mob-valera-ship.svg";
import valeraPilotImage from "assets/mob-valera-pilot.svg";

interface MobData {
  [key: string]: MobModel;
}

const { FIREPOWER, RESILIENCE, SPEED, PRECISION, ENERGY } = BASE_STATS;

export const MOBS: MobData = {
  gren: {
    name: "Gren",
    images: {
      ship: grenShipImage,
      pilot: grenPilotImage,
    },
    dialogs: {
      opening:
        "I annihilate anyone who dares challenge me. But also please don't challenge me.",
      win: "You win this time, but I'll annihilate you next time. But also please stop challenging me.",
      lose: "I actually beat you!? I mean, of course I did.",
    },
    position: 60,
    heightPercentage: 30,
    level: 1,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "exp_booster",
        quantity: 1,
      },
      gold: {
        slug: "minor_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 60, y: 55.5 },
      targeting: { x: 70, y: 55.5 },
      thrusters: { x: 31.5, y: 50.75 },
    },
  },
  ardin: {
    name: "Ardin",
    images: {
      ship: ardinShipImage,
      pilot: ardinPilotImage,
    },
    dialogs: {
      opening:
        "Sure, let's fight, I've got 5 seconds to spare. Oh, sorry, that might've come off rude.",
      win: "Oh wow, that was unexpected! ...Again, no offense intended.",
      lose: "Hey, you tried and that's ...something. I promise I'm not trying to be condescending.",
    },
    position: 90,
    heightPercentage: 30,
    level: 2,
    baseStatsModifiers: {
      firepower: 6,
      resilience: 1,
      speed: 1,
      precision: 2,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [FIREPOWER, RESILIENCE, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "pulse_laser",
        quantity: 1,
      },
      gold: {
        slug: "exp_booster",
        quantity: 1,
      },
      silver: {
        slug: "minor_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "pulse_laser",
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 45, y: 55 },
      targeting: { x: 63, y: 55 },
      thrusters: { x: 29, y: 49.5 },
    },
  },
  sharper: {
    name: "Sharper",
    images: {
      ship: sharperShipImage,
      pilot: sharperPilotImage,
    },
    dialogs: {
      opening: "Ugh, you're challenging me to a fight? I'm trying to nap here.",
      win: "Okay, yay, you defeated me. Now go away and let me sleep.",
      lose: "I told you to leave me alone.",
    },
    position: 120,
    heightPercentage: 30,
    level: 3,
    baseStatsModifiers: {
      firepower: 1,
      resilience: 1,
      speed: 1,
      precision: 6,
      energy: 1,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 1,
      energy: 3,
    },
    baseStatsTargets: [SPEED, FIREPOWER, PRECISION, RESILIENCE, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "targeting_chip",
        quantity: 1,
      },
      gold: {
        slug: "exp_booster",
        quantity: 2,
      },
      silver: {
        slug: "minor_repair_kit",
        quantity: 2,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: "targeting_chip",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 68, y: 55.75 },
      targeting: { x: 77, y: 55.75 },
      thrusters: { x: 23.6, y: 49.75 },
    },
  },
  cross: {
    name: "Cross",
    images: {
      ship: crossShipImage,
      pilot: crossPilotImage,
    },
    dialogs: {
      opening: "YEAH LET'S FIGHT LET'S GO RIGHT NOW FIGHT FIGHT FIGHT",
      win: "I DIDN'T EXPECT THAT BUT OKAY",
      lose: "LET'S GO AGAIN",
    },
    position: 150,
    heightPercentage: 30,
    level: 4,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 1,
      speed: 2,
      precision: 0,
      energy: 5,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, SPEED, FIREPOWER, PRECISION, RESILIENCE],
    isBoss: false,
    drops: {
      platinum: {
        slug: "minor_restore_module",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 67, y: 55.25 },
      targeting: { x: 77, y: 55.25 },
      thrusters: { x: 26.2, y: 49 },
    },
  },
  kether: {
    name: "Kether",
    images: {
      ship: ketherShipImage,
      pilot: ketherPilotImage,
    },
    dialogs: {
      opening: "I'm gonna fly circles around you, slowpoke.",
      win: "I'm not that... fast? Oh my god, my whole identity.",
      lose: "Told you, sluggy.",
    },
    position: 180,
    heightPercentage: 30,
    level: 6,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 0,
      speed: 8,
      precision: 0,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 1,
      precision: 3,
      energy: 2,
    },
    baseStatsTargets: [ENERGY, PRECISION, SPEED, RESILIENCE, FIREPOWER],
    isBoss: false,
    drops: {},
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 56, y: 53.25 },
      targeting: { x: 66, y: 53.25 },
      thrusters: { x: 35.2, y: 49.5 },
    },
  },
  gamma: {
    name: "Gamma",
    images: {
      ship: gammaShipImage,
      pilot: gammaPilotImage,
    },
    dialogs: {
      opening: "TBD",
      win: "TBD",
      lose: "TBD",
    },
    position: 210,
    heightPercentage: 30,
    level: 8,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 7,
      speed: 0,
      precision: 1,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 1,
      speed: 3,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, ENERGY, SPEED, PRECISION],
    isBoss: true,
    drops: {},
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 80, y: 57.75 },
      targeting: { x: 91, y: 57.75 },
      thrusters: { x: 13, y: 48.5 },
    },
  },
  lockheed: {
    name: "Lockheed",
    images: {
      ship: lockheedShipImage,
      pilot: lockheedPilotImage,
    },
    dialogs: {
      opening: "TBD",
      win: "TBD",
      lose: "TBD",
    },
    position: 250,
    heightPercentage: 30,
    level: 10,
    baseStatsModifiers: {
      firepower: 4,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, PRECISION, ENERGY, SPEED],
    isBoss: true,
    drops: {},
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 52, y: 57.75 },
      targeting: { x: 61.5, y: 57.75 },
      thrusters: { x: 24.2, y: 52.25 },
    },
  },
  rincon: {
    name: "Rincon",
    images: {
      ship: rinconShipImage,
      pilot: rinconPilotImage,
    },
    dialogs: {
      opening: "TBD",
      win: "TBD",
      lose: "TBD",
    },
    position: 60,
    heightPercentage: 30,
    level: 11,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, SPEED, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "major_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 60, y: 55.5 },
      targeting: { x: 70, y: 55.5 },
      thrusters: { x: 31.5, y: 50.75 },
    },
  },
  hadron: {
    name: "Hadron",
    images: {
      ship: hadronShipImage,
      pilot: hadronPilotImage,
    },
    dialogs: {
      opening: "TBD",
      win: "TBD",
      lose: "TBD",
    },
    position: 90,
    heightPercentage: 30,
    level: 12,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 2,
      speed: 1,
      precision: 1,
      energy: 4,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 3,
      energy: 1,
    },
    baseStatsTargets: [ENERGY, SPEED, FIREPOWER, PRECISION, RESILIENCE],
    isBoss: false,
    drops: {
      platinum: {
        slug: "velo_nexus",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: "velo_nexus",
    },
    partPositions: {
      weapons: { x: 67, y: 55.25 },
      targeting: { x: 77, y: 55.25 },
      thrusters: { x: 26.2, y: 49 },
    },
  },
  acela: {
    name: "Acela",
    images: {
      ship: acelaShipImage,
      pilot: acelaPilotImage,
    },
    dialogs: {
      opening: "TBD",
      win: "TBD",
      lose: "TBD",
    },
    position: 120,
    heightPercentage: 30,
    level: 13,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 2,
      speed: 5,
      precision: 0,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 1,
      precision: 3,
      energy: 2,
    },
    baseStatsTargets: [SPEED, ENERGY, PRECISION, RESILIENCE, FIREPOWER],
    isBoss: false,
    drops: {
      platinum: {
        slug: "hyperburner",
        quantity: 1,
      },
      gold: {
        slug: "medium_repair_kit",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: "hyperburner",
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 56, y: 53.25 },
      targeting: { x: 66, y: 53.25 },
      thrusters: { x: 35.2, y: 49.5 },
    },
  },
  megama: {
    name: "Megama",
    images: {
      ship: megamaShipImage,
      pilot: megamaPilotImage,
    },
    dialogs: {
      opening:
        "Can your weapons even pierce my shields? You're welcome to try.",
      win: "I guess I need to upgrade my shields.",
      lose: "I tried to warn you.",
    },
    position: 150,
    heightPercentage: 30,
    level: 14,
    baseStatsModifiers: {
      firepower: 2,
      resilience: 7,
      speed: 0,
      precision: 1,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 1,
      speed: 3,
      precision: 2,
      energy: 2,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, ENERGY, SPEED, PRECISION],
    isBoss: true,
    drops: {
      platinum: {
        slug: "guardian_shield",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: "guardian_shield",
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 80, y: 59.75 },
      targeting: { x: 91, y: 59.75 },
      thrusters: { x: 13, y: 50.5 },
    },
  },
  breeden: {
    name: "Breeden",
    images: {
      ship: breedenShipImage,
      pilot: breedenPilotImage,
    },
    dialogs: {
      opening: "TBD",
      win: "TBD",
      lose: "TBD",
    },
    position: 180,
    heightPercentage: 30,
    level: 16,
    baseStatsModifiers: {
      firepower: 3,
      resilience: 2,
      speed: 1,
      precision: 4,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 2,
      resilience: 2,
      speed: 2,
      precision: 1,
      energy: 3,
    },
    baseStatsTargets: [SPEED, FIREPOWER, PRECISION, RESILIENCE, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "stellar_sight",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: null,
      shields: null,
      thrusters: null,
      targetingSystem: "stellar_sight",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 68, y: 55.75 },
      targeting: { x: 77, y: 55.75 },
      thrusters: { x: 23.6, y: 49.75 },
    },
  },
  revin: {
    name: "Revin",
    images: {
      ship: revinShipImage,
      pilot: revinPilotImage,
    },
    dialogs: {
      opening: "TBD",
      win: "TBD",
      lose: "TBD",
    },
    position: 210,
    heightPercentage: 30,
    level: 18,
    baseStatsModifiers: {
      firepower: 5,
      resilience: 3,
      speed: 1,
      precision: 1,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [FIREPOWER, SPEED, RESILIENCE, PRECISION, ENERGY],
    isBoss: false,
    drops: {
      platinum: {
        slug: "star_breaker",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "star_breaker",
      shields: null,
      thrusters: null,
      targetingSystem: null,
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 49, y: 55.75 },
      targeting: { x: 67, y: 55.75 },
      thrusters: { x: 23, y: 49 },
    },
  },
  valera: {
    name: "Valera",
    images: {
      ship: valeraShipImage,
      pilot: valeraPilotImage,
    },
    dialogs: {
      opening: "You want to fight ...me? Uh, good luck, I guess.",
      win: "Well, damn.",
      lose: "lol",
    },
    position: 250,
    heightPercentage: 30,
    level: 20,
    baseStatsModifiers: {
      firepower: 4,
      resilience: 2,
      speed: 1,
      precision: 3,
      energy: 0,
    },
    baseStatsCosts: {
      firepower: 1,
      resilience: 2,
      speed: 2,
      precision: 2,
      energy: 3,
    },
    baseStatsTargets: [RESILIENCE, FIREPOWER, PRECISION, ENERGY, SPEED],
    isBoss: true,
    drops: {
      platinum: {
        slug: "plasma_blade",
        quantity: 1,
      },
    },
    installedUpgrades: {
      weapons: "plasma_blade",
      shields: null,
      thrusters: null,
      targetingSystem: "stellar_sight",
      reactorCore: null,
    },
    partPositions: {
      weapons: { x: 52, y: 57.75 },
      targeting: { x: 61.5, y: 57.75 },
      thrusters: { x: 24.2, y: 52.25 },
    },
  },
};
