import { CharacterBaseStats, CharacterDerivedStats } from "types";

import firepowerImage from "assets/stat-firepower.svg";
import resilienceImage from "assets/stat-resilience.svg";
import speedImage from "assets/stat-speed.svg";
import precisionImage from "assets/stat-precision.svg";
import energyImage from "assets/stat-energy.svg";
import { BASE_STATS } from "./baseStats";
import { COLORS } from "utils/constants";

export interface DerivedStatInfo {
  name: string;
  description: string;
  baseStat: keyof CharacterBaseStats;
  startingValue: number;
  incrementValue: number;
  image: string;
  color: string;
  rounder: (x: number) => number;
  formatter: (x: number) => string;
  addFormatter: (x: number) => string;
  buffFormatter: (x: number) => string;
}

const roundWhole = (x: number): number => Math.round(x);
const roundTwoDecimals = (x: number): number => Math.round(x * 100) / 100;

const number = (x: number): string => `${x}`;
const multiple = (x: number): string => `${x}x`;
const percent = (x: number): string => `${Math.round(x * 100)}%`;
const addNumber = (x: number): string => (x > 0 ? `+${x}` : `${x}`);
const addMultiple = (x: number): string => (x > 0 ? `+${x}x` : `${x}x`);
const addPercent = (x: number): string =>
  x > 0 ? `+${Math.round(x * 100)}%` : `${Math.round(x * 100)}%`;
const buff = (x: number) => `${x}X`;

export const DERIVED_STATS: Record<
  keyof CharacterDerivedStats,
  DerivedStatInfo
> = {
  maxHealth: {
    name: "Max Ship Health",
    description: "How much overall health your ship has.",
    baseStat: BASE_STATS.RESILIENCE,
    startingValue: 50,
    incrementValue: 20,
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    rounder: roundWhole,
    formatter: number,
    addFormatter: addNumber,
    buffFormatter: buff,
  },
  attackDamage: {
    name: "Max Attack Damage",
    description: "The max ship damage you'll deal in a regular attack.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 10,
    incrementValue: 5,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundWhole,
    formatter: number,
    addFormatter: addNumber,
    buffFormatter: buff,
  },
  attackSpeed: {
    name: "Attack Speed",
    description: "How often you can attack in a battle.",
    baseStat: BASE_STATS.SPEED,
    startingValue: 1,
    incrementValue: 0.1,
    image: speedImage,
    color: COLORS.SPEED,
    rounder: roundTwoDecimals,
    formatter: multiple,
    addFormatter: addMultiple,
    buffFormatter: buff,
  },
  attackAccuracy: {
    name: "Attack Precision",
    description: "How consistently you inflict higher damage to your opponent.",
    baseStat: BASE_STATS.PRECISION,
    startingValue: 0,
    incrementValue: 0.02,
    image: precisionImage,
    color: COLORS.PRECISION,
    rounder: roundTwoDecimals,
    formatter: percent,
    addFormatter: addPercent,
    buffFormatter: buff,
  },
  weakenParts: {
    name: "Max Weaken Components",
    description:
      "The max amount you can weaken an opponent's specific part with each attack, which degrades their stats during battle.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 1,
    incrementValue: 0.1,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundTwoDecimals,
    formatter: number,
    addFormatter: addNumber,
    buffFormatter: buff,
  },
  damageReduction: {
    name: "Damage Shields",
    description:
      "Stronger damage shields lead to more damage reduction when an opponent hits you with an attack.",
    baseStat: BASE_STATS.RESILIENCE,
    startingValue: 0,
    incrementValue: 1,
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    rounder: roundWhole,
    formatter: number,
    addFormatter: addNumber,
    buffFormatter: buff,
  },
  weakenPartsReduction: {
    name: "Component Shields",
    description:
      "Stronger component shields lead to reudction of how much your ship parts get weakened during battle.",
    baseStat: BASE_STATS.RESILIENCE,
    startingValue: 0,
    incrementValue: 0.05,
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    rounder: roundTwoDecimals,
    formatter: number,
    addFormatter: addNumber,
    buffFormatter: buff,
  },
  criticalHitChance: {
    name: "Critical Hit Chance",
    description: "Chance of landing a critical hit on your opponent.",
    baseStat: BASE_STATS.PRECISION,
    startingValue: 0,
    incrementValue: 0.01,
    image: precisionImage,
    color: COLORS.PRECISION,
    rounder: roundTwoDecimals,
    formatter: percent,
    addFormatter: addPercent,
    buffFormatter: buff,
  },
  criticalHitMultiplier: {
    name: "Critical Hit Boost",
    description: "Increases the damage you deal when you land a critical hit.",
    baseStat: BASE_STATS.FIREPOWER,
    startingValue: 2,
    incrementValue: 0.1,
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    rounder: roundTwoDecimals,
    formatter: multiple,
    addFormatter: addMultiple,
    buffFormatter: buff,
  },
  dodgeChance: {
    name: "Dodge Chance",
    description: "Increases the chances of outright dodging an attack",
    baseStat: BASE_STATS.SPEED,
    startingValue: 0,
    incrementValue: 0.01,
    image: speedImage,
    color: COLORS.SPEED,
    rounder: roundTwoDecimals,
    formatter: percent,
    addFormatter: addPercent,
    buffFormatter: buff,
  },
  movementSpeed: {
    name: "Movement Speed",
    description: "How quickly your ship can move around the planets.",
    baseStat: BASE_STATS.SPEED,
    startingValue: 1,
    incrementValue: 0.1,
    image: speedImage,
    color: COLORS.SPEED,
    rounder: roundTwoDecimals,
    formatter: multiple,
    addFormatter: addMultiple,
    buffFormatter: buff,
  },
  fasterRecharge: {
    name: "Faster Ability Recharge",
    description: "Your active pilot skills recharge faster during battle.",
    baseStat: BASE_STATS.ENERGY,
    startingValue: 0,
    incrementValue: 0.1,
    image: energyImage,
    color: COLORS.ENERGY,
    rounder: roundTwoDecimals,
    formatter: number,
    addFormatter: addNumber,
    buffFormatter: buff,
  },
  energyMultiplier: {
    name: "Antimatter Boost",
    description: "Increases the efficacy of your pilot skills.",
    baseStat: BASE_STATS.ENERGY,
    startingValue: 1,
    incrementValue: 0.1,
    image: energyImage,
    color: COLORS.ENERGY,
    rounder: roundTwoDecimals,
    formatter: multiple,
    addFormatter: addMultiple,
    buffFormatter: buff,
  },
};
