import { GradeModel, UpgradeModel } from "types";
import baseWeaponsImage from "assets/starship-base-weapons.svg";
import baseWeaponsIcon from "assets/starship-base-weapons-icon.svg";
import baseShieldsImage from "assets/starship-base-shields.svg";
import baseShieldsIcon from "assets/starship-base-shields-icon.svg";
import baseThrustersImage from "assets/starship-base-thrusters.svg";
import baseThrustersIcon from "assets/starship-base-thrusters-icon.svg";
import baseTargetingSystemImage from "assets/starship-base-targeting-system.svg";
import baseTargetingSystemIcon from "assets/starship-base-targeting-system-icon.svg";
import baseReactorCoreImage from "assets/starship-base-reactor-core.svg";
import baseReactorCoreIcon from "assets/starship-base-reactor-core-icon.svg";
import pulseLaserImage from "assets/upgrade-pulse-laser.svg";
import pulseLaserIcon from "assets/upgrade-pulse-laser-icon.svg";
import starBreakerImage from "assets/upgrade-breaker.svg";
import starBreakerIcon from "assets/upgrade-breaker-icon.svg";
import plasmaBladeImage from "assets/upgrade-plasma-blade.svg";
import plasmaBladeIcon from "assets/upgrade-plasma-blade-icon.svg";
import targetingChipImage from "assets/upgrade-targeting-chip.svg";
import targetingChipIcon from "assets/upgrade-targeting-chip-icon.svg";
import stellarSightImage from "assets/upgrade-stellar-sight.svg";
import stellarSightIcon from "assets/upgrade-stellar-sight-icon.svg";
import veloNexusImage from "assets/upgrade-velo-nexus.svg";
import veloNexusIcon from "assets/upgrade-velo-nexus-icon.svg";
import guardianShieldImage from "assets/upgrade-guardian-shield.svg";
import guardianShieldIcon from "assets/upgrade-guardian-shield-icon.svg";
import hyperburnerImage from "assets/upgrade-hyperburner.svg";
import hyperburnerIcon from "assets/upgrade-hyperburner-icon.svg";
import {
  attackAnimation,
  reactorCoreAnimation,
  shieldsAnimation,
  targetingSystemAnimation,
  thrusterAnimation,
} from "components/base/styles";
import { BASE_STATS } from "./baseStats";

const DEFAULT_WEAPONS_SPEED = 300;
const DEFAULT_SHIELDS_SPEED = 1200;
const DEFAULT_THRUSTERS_SPEED = 400;
const DEFAULT_TARGETING_SPEED = 1200;
const DEFAULT_REACTOR_SPEED = 1200;

interface UpgradeData {
  [key: string]: UpgradeModel;
}

export const UPGRADES: UpgradeData = {
  default_weapons: {
    slug: "default_weapons",
    name: "Base Weapon",
    description: "Crappy weapon that came with your starship",
    image: baseWeaponsImage,
    icon: baseWeaponsIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_shields: {
    slug: "default_shields",
    name: "Base Shield",
    description: "Crappy shield that came with your starship",
    image: baseShieldsImage,
    icon: baseShieldsIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirement: {
      baseStat: BASE_STATS.RESILIENCE,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_thrusters: {
    slug: "default_thrusters",
    name: "Base Thrusters",
    description: "Crappy thrusters that came with your starship",
    image: baseThrustersImage,
    icon: baseThrustersIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirement: {
      baseStat: BASE_STATS.SPEED,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_targeting_system: {
    slug: "default_targeting_system",
    name: "Base Targeting Grid",
    description: "Crappy targeting system that came with your starship",
    image: baseTargetingSystemImage,
    icon: baseTargetingSystemIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  default_reactor_core: {
    slug: "default_reactor_core",
    name: "Base Reactor Core",
    description: "Crappy reactor core that came with your starship",
    image: baseReactorCoreImage,
    icon: baseReactorCoreIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirement: {
      baseStat: BASE_STATS.ENERGY,
      value: 0,
    },
    grade: "basic",
    derivedStatsModifiers: {},
    isDefault: true,
  },
  pulse_laser: {
    slug: "pulse_laser",
    name: "Pulse Laser",
    description:
      "A tiny laser upgrade that barely counts as an upgrade. Pew pew.",
    image: pulseLaserImage,
    icon: pulseLaserIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 1,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackDamage: 1,
    },
  },
  star_breaker: {
    slug: "star_breaker",
    name: "Star Breaker",
    description: "A weapon designed to incapacitate ship parts, so watch out.",
    image: starBreakerImage,
    icon: starBreakerIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 10,
    },
    grade: "elite",
    derivedStatsModifiers: {
      weakenParts: 1,
    },
  },
  plasma_blade: {
    slug: "plasma_blade",
    name: "Plasma Blade",
    description: "Uh... Try not to get hit by this.",
    image: plasmaBladeImage,
    icon: plasmaBladeIcon,
    animation: attackAnimation,
    animationSpeed: DEFAULT_WEAPONS_SPEED,
    part: "weapons",
    requirement: {
      baseStat: BASE_STATS.FIREPOWER,
      value: 12,
    },
    grade: "ultra",
    derivedStatsModifiers: {
      attackDamage: 0.5,
      weakenParts: 0.25,
      criticalHitMultiplier: 0.25,
    },
  },
  targeting_chip: {
    slug: "targeting_chip",
    name: "Small Scope",
    description:
      "A small targeting system upgrade that makes your attacks hit a bit harder.",
    image: targetingChipImage,
    icon: targetingChipIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 2,
    },
    grade: "basic",
    derivedStatsModifiers: {
      attackAccuracy: 1,
    },
  },
  stellar_sight: {
    slug: "stellar_sight",
    name: "Stellar Sight",
    description:
      "A robust targeting system upgrade that makes your attacks hit harder.",
    image: stellarSightImage,
    icon: stellarSightIcon,
    animation: targetingSystemAnimation,
    animationSpeed: DEFAULT_TARGETING_SPEED,
    part: "targetingSystem",
    requirement: {
      baseStat: BASE_STATS.PRECISION,
      value: 6,
    },
    grade: "elite",
    derivedStatsModifiers: {
      attackAccuracy: 0.5,
      criticalHitChance: 0.5,
    },
  },
  velo_nexus: {
    slug: "velo_nexus",
    name: "Velo Nexus",
    description:
      "An upgrade to your reactor core that allows you to employ pilot abilities more often.",
    image: veloNexusImage,
    icon: veloNexusIcon,
    animation: reactorCoreAnimation,
    animationSpeed: DEFAULT_REACTOR_SPEED,
    part: "reactorCore",
    requirement: {
      baseStat: BASE_STATS.ENERGY,
      value: 5,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      fasterRecharge: 1,
    },
  },
  hyperburner: {
    slug: "hyperburner",
    name: "Hyperburner",
    description: "Thrusters that increase your ability to avoid attacks",
    image: hyperburnerImage,
    icon: hyperburnerIcon,
    animation: thrusterAnimation,
    animationSpeed: DEFAULT_THRUSTERS_SPEED,
    part: "thrusters",
    requirement: {
      baseStat: BASE_STATS.SPEED,
      value: 6,
    },
    grade: "advanced",
    derivedStatsModifiers: {
      dodgeChance: 0.5,
      attackSpeed: 0.5,
    },
  },
  guardian_shield: {
    slug: "guardian_shield",
    name: "Guardian Shield",
    description: "A powerful force shield that effortlessly deflects damage.",
    image: guardianShieldImage,
    icon: guardianShieldIcon,
    animation: shieldsAnimation,
    animationSpeed: DEFAULT_SHIELDS_SPEED,
    part: "shields",
    requirement: {
      baseStat: BASE_STATS.RESILIENCE,
      value: 8,
    },
    grade: "elite",
    derivedStatsModifiers: {
      maxHealth: 0.25,
      damageReduction: 0.5,
      weakenPartsReduction: 0.25,
    },
  },
};

interface GradeData {
  [key: string]: GradeModel;
}

export const GRADES: GradeData = {
  basic: {
    slug: "basic",
    name: "Basic",
    color: "#777777",
    multiplier: 1,
  },
  advanced: {
    slug: "advanced",
    name: "Advanced",
    color: "#0c5ff2",
    multiplier: 1.1,
  },
  elite: {
    slug: "elite",
    name: "Elite",
    color: "#dc2eef",
    multiplier: 1.25,
  },
  ultra: {
    slug: "ultra",
    name: "Ultra",
    color: "#facd05",
    multiplier: 1.5,
  },
};
