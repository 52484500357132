import { CharacterBaseStats } from "types";
import { COLORS } from "utils/constants";
import firepowerImage from "assets/stat-firepower.svg";
import resilienceImage from "assets/stat-resilience.svg";
import speedImage from "assets/stat-speed.svg";
import precisionImage from "assets/stat-precision.svg";
import energyImage from "assets/stat-energy.svg";

export const BASE_STATS: Record<string, keyof CharacterBaseStats> = {
  FIREPOWER: "firepower",
  RESILIENCE: "resilience",
  SPEED: "speed",
  PRECISION: "precision",
  ENERGY: "energy",
};

export const BASE_STATS_INFO = [
  {
    slug: BASE_STATS.FIREPOWER,
    name: "firepower",
    color: COLORS.FIREPOWER,
    image: firepowerImage,
    description: "Firepower determines how powerful a ship's attacks are.",
    part: "weapons",
  },
  {
    slug: BASE_STATS.RESILIENCE,
    name: "resilience",
    color: COLORS.RESILIENCE,
    image: resilienceImage,
    description: "Resilience determines how strong a ship's shields are.",
    part: "shields",
  },
  {
    slug: BASE_STATS.SPEED,
    name: "speed",
    color: COLORS.SPEED,
    image: speedImage,
    description: "Speed determines how often a ship can attack and dodge.",
    part: "thrusters",
  },
  {
    slug: BASE_STATS.PRECISION,
    name: "precision",
    color: COLORS.PRECISION,
    image: precisionImage,
    description:
      "Precision determines how often accurate a ship's attacks are.",
    part: "targeting grid",
  },
  {
    slug: BASE_STATS.ENERGY,
    name: "antimatter",
    color: COLORS.ENERGY,
    image: energyImage,
    description:
      "Antimatter determines how often a pilot can use their special abilities.",
    part: "reactor core",
  },
];
