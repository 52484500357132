import React from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";

import { getCharacter } from "redux/selectors";
import { getBuildingData } from "utils/stats";
import ShipworksInterior from "components/ui/ShipworksInterior";
import PilotAcademyInterior from "components/ui/PilotAcademyInterior";
import InfinityShopInterior from "components/ui/ShipworksInterior";

interface BuildingInteriorWrapperProps {
  isInsideBuilding: boolean;
  bgImage: string;
}

const showMixin = css`
  opacity: 1;
  pointer-events: auto;
`;

const BuildingInteriorWrapper = styled.div<BuildingInteriorWrapperProps>`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  ${(props) => !!props.bgImage && `background-image: url("${props.bgImage}");`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
  pointer-events: none;
  ${(props) => (props.isInsideBuilding ? showMixin : "")};
  transition: all 500ms ease-out;
`;

const BUILDING_COMPONENTS = {
  shipworks: ShipworksInterior,
  pilotAcademy: PilotAcademyInterior,
  infinityShop: InfinityShopInterior,
};

export default function BuildingInterior() {
  const {
    data: { location },
  } = useSelector(getCharacter);

  let BuildingComponent = null;
  let backgroundImage = null;

  if (!!location.building) {
    const building = getBuildingData(location.building);
    BuildingComponent = BUILDING_COMPONENTS[building.type];
    backgroundImage = building.interiorImage;
  }

  return (
    <BuildingInteriorWrapper
      isInsideBuilding={!!location.building}
      bgImage={backgroundImage}
    >
      {BuildingComponent && <BuildingComponent />}
    </BuildingInteriorWrapper>
  );
}
