import styled from "styled-components";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";

import healthImage from "assets/health.svg";
import expImage from "assets/exp.svg";
import Tooltip, { TooltipDesc, TooltipName, Trigger } from "./Tooltip";
import LargeBar from "./LargeBar";
import { COLORS } from "utils/constants";

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export default function Status() {
  const {
    data: { currentLevelExp, nextLevelExp, health, stats },
  } = useSelector(getCharacter);

  return (
    <StatusWrapper>
      <Trigger>
        <Name>Ship Health</Name>
        <LargeBar
          icon={healthImage}
          number={health}
          maxNumber={stats.maxHealth}
          color="#42a545"
        />
        <Tooltip>
          <TooltipName>Ship Health</TooltipName>
          <TooltipDesc>
            {`This is how much health your ship currently has. Don't let it drop to zero!`}
          </TooltipDesc>
          <TooltipDesc>
            You can fully repair your starship at the nearest Shipworks in any
            city.
          </TooltipDesc>
        </Tooltip>
      </Trigger>
      <Trigger>
        <Name>Experience</Name>
        <LargeBar
          icon={expImage}
          number={currentLevelExp}
          maxNumber={nextLevelExp}
          color={COLORS.EXPERIENCE}
        />
        <Tooltip>
          <TooltipName>Experience</TooltipName>
          <TooltipDesc>
            {`This is how much experience you have at your current level. Gain more experience through battles to level up!`}
          </TooltipDesc>
        </Tooltip>
      </Trigger>
    </StatusWrapper>
  );
}
