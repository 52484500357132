import styled from "styled-components";

interface WeakenBarProps {
  number: number;
  weakenNumber: number;
  maxNumber: number;
  color: string;
  isReversed: boolean;
}

interface WeakenBarWrapperProps {
  isReversed: boolean;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

const WeakenBarWrapper = styled.div<WeakenBarWrapperProps>`
  width: 100%;
  height: 30px;
  background-color: #ffffff15;
  display: flex;
  flex-grow: 1;
  transform: skew(-30deg);
  ${(props) => props.isReversed && `transform: skew(30deg) scaleX(-1);`}
`;

const BarValue = styled.div.attrs(({ percentage }: BarValueProps) => ({
  style: {
    width: `${percentage}%`,
  },
}))<BarValueProps>`
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 200ms ease-out;
  z-index: 2;
  background-color: ${(props) => props.color};
`;

const BarWeakenValue = styled(BarValue)`
  opacity: 0.3;
  display: flex;
  justify-content: end;
  align-items: center;
  overflow: hidden;
`;

const RemainingNumber = styled.div`
  transform: skew(30deg);
  margin-left: 8px;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
`;

const TotalNumber = styled.div`
  transform: skew(30deg);
  margin-right: 8px;
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  opacity: 0.8;
`;

export default function WeakenBar({
  number,
  weakenNumber,
  maxNumber,
  color,
  isReversed,
}: WeakenBarProps) {
  const totalNumber = number + weakenNumber;

  return (
    <WeakenBarWrapper isReversed={isReversed}>
      <BarValue percentage={(number / maxNumber) * 100} color={color}>
        <RemainingNumber>{number}</RemainingNumber>
      </BarValue>
      <BarWeakenValue
        percentage={(weakenNumber / maxNumber) * 100}
        color={color}
      >
        <TotalNumber>{totalNumber}</TotalNumber>
      </BarWeakenValue>
    </WeakenBarWrapper>
  );
}
