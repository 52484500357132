import { ShopModel } from "types";

interface ShopsData {
  [key: string]: ShopModel;
}

export const SHOPS: ShopsData = {
  bishopShipworksRepair: {
    items: [
      "minor_repair_kit",
      "medium_repair_kit",
      "major_repair_kit",
      "minor_restore_module",
    ],
  },
  bishopInfinityShop: { items: [] },
};
