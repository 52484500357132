import { ItemModel } from "types";
import expBoosterIcon from "assets/item-exp-booster.svg";
import minorRepairKitIcon from "assets/item-minor-repair-kit.svg";
import mediumRepairKitIcon from "assets/item-medium-repair-kit.svg";
import majorRepairKitIcon from "assets/item-major-repair-kit.svg";
import minorRestoreModuleIcon from "assets/item-minor-restore-module.svg";
import {
  employRepairKitSaga,
  employExpBoosterSaga,
  employRestoreKitSaga,
} from "redux/sagas/item";

interface ItemData {
  [key: string]: ItemModel;
}

export const ITEMS: ItemData = {
  minor_repair_kit: {
    name: "Minor Repair Kit",
    description: "A kit that repairs your ship's health slightly.",
    icon: minorRepairKitIcon,
    action: employRepairKitSaga,
    attribute: "health",
    payload: 20,
    credits: 10,
    inFight: true,
  },
  medium_repair_kit: {
    name: "Medium Repair Kit",
    description: "A kit that repairs your ship's health medium...ly.",
    icon: mediumRepairKitIcon,
    action: employRepairKitSaga,
    attribute: "health",
    payload: 100,
    credits: 50,
    inFight: true,
  },
  major_repair_kit: {
    name: "Major Repair Kit",
    description: "A kit that heavily repairs your ship's health.",
    icon: majorRepairKitIcon,
    action: employRepairKitSaga,
    attribute: "health",
    payload: 200,
    credits: 100,
    inFight: true,
  },
  minor_restore_module: {
    name: "Minor Restore Parts Module",
    description:
      "A modules that restores your ship's parts slightly while in battle.",
    icon: minorRestoreModuleIcon,
    action: employRestoreKitSaga,
    attribute: "integrity",
    payload: 5,
    credits: 25,
    inFight: true,
  },
  exp_booster: {
    name: "Minor Experience Booster",
    description: "A module that boosts your experience by a little bit.",
    icon: expBoosterIcon,
    action: employExpBoosterSaga,
    attribute: "experience",
    payload: 100,
    credits: 20,
    inFight: false,
  },
};
