import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useHotkeys } from "react-hotkeys-hook";

import { move, stopMove } from "redux/actions";
import World from "components/world/World";
import ShipInterior from "components/ui/ShipInterior";
import BuildingInterior from "components/ui/BuildingInterior";
import Avatar from "components/ui/Avatar";
import Messages from "components/ui/Messages";
import CharacterInfo from "components/ui/CharacterInfo";
import Fight from "components/ui/Fight";

const MapWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
`;

export default function GameMap() {
  const dispatch = useDispatch();

  useHotkeys("left,right", (event, handler) => {
    event.preventDefault();
    dispatch(move(handler.shortcut));
  });

  useHotkeys(
    "left,right",
    (event) => {
      event.preventDefault();
      dispatch(stopMove());
    },
    { keyup: true }
  );

  return (
    <MapWrapper>
      <World>
        <Avatar />
      </World>
      <ShipInterior />
      <BuildingInterior />
      <CharacterInfo />
      <Fight />
      <Messages />
    </MapWrapper>
  );
}
