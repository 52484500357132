import { BASE_STATS } from "data/baseStats";
import { call, delay, put, select, spawn } from "redux-saga/effects";

import {
  showMessage,
  setSkillRecharge,
  animateAction,
  endAnimation,
  setSkillValue,
  addOpponentTurnPriority,
  resetSkillsRecharge,
  weakenPartFully,
  restorePart,
} from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import { SkillModel } from "types";
import { characterAttackAnimation, characterSkillAttackSaga } from "./fight";
import { healSaga } from "./character";
import {
  getEagleEyeDamage,
  getEmergencyRepairHealth,
  getEnergyBlastDamage,
  getPressurePointDamage,
  getRapidFireDamage,
  getShieldBreakerDamage,
  getShieldBypassDamage,
  getHyperShiftDamage,
  getSystemsDecayDamage,
  getReactorOverloadDamage,
  getPhantomStrikeDamage,
  getShieldStormDamage,
  getPowerSurgeDamage,
  getShieldRestoreAmount,
  getRandomBaseStat,
  getScattershotDamage,
  getBlastEchoDamage,
} from "utils/stats";

export function* shieldBreakerSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(500);

  const {
    data: { stats },
  } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getShieldBreakerDamage(stats);

  yield call(
    characterSkillAttackSaga,
    attackDamage,
    attackWeakenParts,
    BASE_STATS.RESILIENCE
  );
}

export function* scattershotSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(750);

  const {
    data: { stats },
  } = yield select(getCharacter);

  const randomBaseStat = getRandomBaseStat();

  const { attackDamage, attackWeakenParts } = getScattershotDamage(stats);

  yield call(
    characterSkillAttackSaga,
    attackDamage,
    attackWeakenParts,
    randomBaseStat
  );
}

export function* powerSurgeSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(1000);

  yield spawn(characterAttackAnimation);
  // Let skill attack hit first before showing opponent damage
  yield delay(100);

  const {
    data: { stats, skillsValues },
  } = yield select(getCharacter);
  const {
    opponent: { damage: opponentDamages },
  } = yield select(getFight);

  const { attackDamage, attackWeakenParts, totalDamage } = getPowerSurgeDamage(
    stats,
    opponentDamages,
    skillsValues
  );

  yield call(characterSkillAttackSaga, attackDamage, attackWeakenParts);

  // Keep track of total damage reduced that's now been used up
  yield put(setSkillValue({ slug: skillSlug, value: totalDamage }));
}

export function* rapidFireSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(1000);

  const {
    data: { stats },
  } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getRapidFireDamage(stats);

  const NUMBER_OF_ATTACKS = 3;
  for (var i = 0; i < NUMBER_OF_ATTACKS; i++) {
    if (i > 0) {
      yield delay(500);
    }
    yield spawn(characterAttackAnimation);
    // Let skill attack hit first before showing opponent damage
    yield delay(100);
    yield call(
      characterSkillAttackSaga,
      attackDamage,
      attackWeakenParts,
      BASE_STATS.SPEED
    );
  }
}

export function* hyperShiftSaga(skillSlug: string): any {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(700);

  const {
    data: { stats },
  } = yield select(getCharacter);
  const {
    opponent: {
      stats: { attackSpeed: opponentAttackSpeed },
    },
    nextConsecutiveTurns,
  } = yield select(getFight);

  const { attackDamage, attackWeakenParts } = getHyperShiftDamage(
    stats,
    nextConsecutiveTurns
  );

  // Add opponent enough attack speed to turn priority so that it's their turn next
  // This is before the attack, in case their attack speed gets affected
  yield put(
    addOpponentTurnPriority(nextConsecutiveTurns * opponentAttackSpeed)
  );

  yield call(characterSkillAttackSaga, attackDamage, attackWeakenParts);
}

export function* phantomStrikeSaga(skillSlug: string): any {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(300);

  yield spawn(characterAttackAnimation);
  // Let skill attack hit first before showing opponent damage
  yield delay(100);

  const {
    data: { stats },
  } = yield select(getCharacter);
  const {
    opponent: {
      stats: { attackSpeed: opponentAttackSpeed },
    },
  } = yield select(getFight);

  const { attackDamage, attackWeakenParts } = getPhantomStrikeDamage(stats);

  // Subtract opponent attack speed to mimic this turn never happening
  yield put(addOpponentTurnPriority(-opponentAttackSpeed));

  yield call(characterSkillAttackSaga, attackDamage, attackWeakenParts);
}

export function* repairSaga(skillSlug: string) {
  const {
    data: { stats },
  } = yield select(getCharacter);

  const { healAmount } = getEmergencyRepairHealth(stats);

  yield call(healSaga, healAmount);

  yield spawn(characterSkillAnimation, skillSlug);
}

export function* shieldRestoreSaga(skillSlug: string) {
  const {
    data: { stats, totalBaseStats },
  } = yield select(getCharacter);

  const { restoreAmount } = getShieldRestoreAmount(stats, totalBaseStats);

  yield put(
    restorePart({
      baseStatRestored: BASE_STATS.RESILIENCE,
      restorePartAmount: restoreAmount,
    })
  );

  yield spawn(characterSkillAnimation, skillSlug);
}

export function* shieldStormSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(500);

  const {
    data: {
      stats,
      ui: { damage: characterDamages },
      skillsValues,
    },
  } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts, totalDamageReduced } =
    getShieldStormDamage(stats, characterDamages, skillsValues);

  yield call(characterSkillAttackSaga, attackDamage, attackWeakenParts);

  // Keep track of total damage reduced that's now been used up
  yield put(setSkillValue({ slug: skillSlug, value: totalDamageReduced }));
}

export function* energyBlastSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(600);

  const {
    data: { stats, currentBaseStats },
  } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getEnergyBlastDamage(
    stats,
    currentBaseStats
  );

  yield call(characterSkillAttackSaga, attackDamage, attackWeakenParts);
}

export function* reactorOverloadSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(600);

  const {
    data: { stats, currentBaseStats, totalBaseStats },
  } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getReactorOverloadDamage(
    stats,
    currentBaseStats
  );

  // Drain antimatter of character
  yield put(
    weakenPartFully({ baseStatWeakened: BASE_STATS.ENERGY, totalBaseStats })
  );

  yield call(characterSkillAttackSaga, attackDamage, attackWeakenParts);
}

export function* shieldBypassSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(400);

  const {
    data: { stats, currentBaseStats },
  } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getShieldBypassDamage(
    stats,
    currentBaseStats
  );

  // Ignore shields
  yield call(
    characterSkillAttackSaga,
    attackDamage,
    attackWeakenParts,
    undefined,
    false,
    true
  );
}

export function* systemsDecaySaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);

  const {
    data: { stats },
  } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getSystemsDecayDamage(stats);

  for (const key in BASE_STATS) {
    yield delay(600);

    const baseStatWeakened = BASE_STATS[key];

    yield call(
      characterSkillAttackSaga,
      attackDamage,
      attackWeakenParts,
      baseStatWeakened,
      true
    );
  }
}

export function* skillsRechargeSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);

  yield delay(1000);

  yield put(resetSkillsRecharge());

  yield delay(300);
}

export function* pressurePointSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(1000);

  yield spawn(characterAttackAnimation);
  // Let skill attack hit first before showing opponent damage
  yield delay(100);

  const {
    data: { stats, skillsValues },
  } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getPressurePointDamage(
    stats,
    skillsValues
  );

  yield call(characterSkillAttackSaga, attackDamage, attackWeakenParts);

  // Keep track of how many time this skill is used
  const useCount = skillsValues[skillSlug] || 0;
  const newUseCount = useCount + 1;
  yield put(setSkillValue({ slug: skillSlug, value: newUseCount }));
}

export function* eagleEyeSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill animation play before shots are fired
  yield delay(1000);

  yield spawn(characterAttackAnimation);
  // Let skill attack hit first before showing opponent damage
  yield delay(100);

  const {
    data: { stats },
  } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getEagleEyeDamage(stats);

  // Ignore dodge
  yield call(
    characterSkillAttackSaga,
    attackDamage,
    attackWeakenParts,
    BASE_STATS.PRECISION,
    true,
    false,
    true
  );
}

export function* blastEchoSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(600);

  const {
    data: { stats },
  } = yield select(getCharacter);
  const {
    opponent: { damage: opponentDamages },
  } = yield select(getFight);

  const { attackDamage, attackWeakenParts, lastWeakenedBaseStat } =
    getBlastEchoDamage(stats, opponentDamages);

  yield call(
    characterSkillAttackSaga,
    attackDamage,
    attackWeakenParts,
    lastWeakenedBaseStat || undefined
  );
}

export function* activateSkillSaga(skillSlug: string) {
  const {
    data: { skillsRecharge, skills },
  } = yield select(getCharacter);

  try {
    const skill = skills.find((skill: SkillModel) => skill.slug === skillSlug);
    if (!skill) {
      throw new Error("Skill not found");
    }

    // Check if skill is still recharging
    const rechargeTurns = skillsRecharge[skillSlug];
    if (rechargeTurns !== "undefined" && rechargeTurns > 0) {
      yield put(showMessage("Skill is still recharging"));
      return;
    }

    // Use skill by calling its saga and value
    // Call saga directly instead of yield putting action to keep it synchronous
    yield call(skill.saga, skillSlug, skill.payload);

    // If activated successfully, start recharging skill + 1 to accommodate current turn
    const newRechargeTurns = (skill.recharge || 0) + 1;
    yield put(setSkillRecharge({ slug: skillSlug, turns: newRechargeTurns }));
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

function* characterSkillAnimation(animation: string) {
  // Animate character attack
  yield put(animateAction(animation));

  yield delay(1000);

  // Reset animation
  yield put(endAnimation(animation));
}

export default function* skillSagas() {}
