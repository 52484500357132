import { call, put, select, takeLeading } from "redux-saga/effects";

import {
  addChatMessage,
  changeAreaPlaylist,
  disableMovement,
  disableMusic,
  enableMovement,
  enableMusic,
  enterShip,
  exitShip,
  getChatMessages,
  getOnlineUsers,
  hideInsideShip,
  pauseMusic,
  playMusic,
  playNextMusicTrack,
  setChatMessages,
  setCurrentPlaylist,
  setCurrentTrack,
  setMusicDisabled,
  setMusicEnabled,
  setOnlineUsers,
  setTrackChange,
  showInsideShip,
  showPlayerDetails,
  startMusicPlayer,
  stopMusicPlayer,
  switchDataScreen,
  viewPlayerShip,
} from "redux/actions";
import { getCharacter, getFight, getGameState } from "redux/selectors";
import { ChatMessage, OnlineUser } from "types";
import { FIGHT_DIALOG_STATUSES } from "utils/constants";
import { getAreaData, getNextMusicTrack } from "utils/stats";
import { createChatMessage } from "utils/storage/firebase";

export function* getOnlineUsersSaga({
  payload: users,
}: {
  payload: OnlineUser[];
}) {
  // Filter for only online users
  const onlineUsers = Object.values(users).filter((user) => !!user.isOnline);
  yield put(setOnlineUsers(onlineUsers));
}

function* enterShipSaga() {
  // Prevent entering ship when in a fight
  const { status } = yield select(getFight);
  const isFighting =
    status !== "notFighting" && !FIGHT_DIALOG_STATUSES.includes(status);
  if (isFighting) {
    return;
  }

  yield put(showInsideShip());

  yield put(disableMovement());
}

function* exitShipSaga() {
  yield put(hideInsideShip());

  yield put(enableMovement());
}

function* viewPlayerShipSaga({ payload: player }: { payload: string | null }) {
  yield call(enterShipSaga);

  yield put(switchDataScreen("comms"));

  yield put(showPlayerDetails(player));
}

function* addChatMessageSaga({ payload: text }: { payload: string }) {
  // Get user ID and user name
  let { userId, userName } = yield select(getCharacter);

  // TODO: Any message validation

  // Add new message in Firebase
  yield createChatMessage(userId, userName, text);
}

function* getChatMessagesSaga({
  payload: messages,
}: {
  payload: ChatMessage[];
}) {
  // Should already be limited by amount
  const chatMessages = Object.values(messages);

  yield put(setChatMessages(chatMessages));
}

function* playMusicSaga() {
  // Always check player setting before turning music on
  let {
    data: { settings },
  } = yield select(getCharacter);

  if (!!settings.isMusicEnabled) {
    yield put(startMusicPlayer());
  }
}

function* pauseMusicSaga() {
  yield put(stopMusicPlayer());
}

function* enableMusicSaga() {
  yield put(setMusicEnabled());
  yield call(playMusicSaga);
}

function* disableMusicSaga() {
  yield put(setMusicDisabled());
  yield call(pauseMusicSaga);
}

function* playNextMusicTrackSaga() {
  let { currentTrack, currentPlaylist } = yield select(getGameState);

  const nextTrack = getNextMusicTrack(currentTrack, currentPlaylist);

  yield put(setCurrentTrack(nextTrack));
  // Trigger play of new track, even if repeating same track
  yield put(setTrackChange());
}

function* changeAreaPlaylistSaga() {
  let {
    data: { location },
  } = yield select(getCharacter);
  const areaData = getAreaData(location.area);

  yield put(setCurrentPlaylist(areaData.playlist));
}

export default function* gameSagas() {
  yield takeLeading(getOnlineUsers, getOnlineUsersSaga);
  yield takeLeading(enterShip, enterShipSaga);
  yield takeLeading(exitShip, exitShipSaga);
  yield takeLeading(viewPlayerShip, viewPlayerShipSaga);
  yield takeLeading(addChatMessage, addChatMessageSaga);
  yield takeLeading(getChatMessages, getChatMessagesSaga);
  yield takeLeading(playMusic, playMusicSaga);
  yield takeLeading(pauseMusic, pauseMusicSaga);
  yield takeLeading(enableMusic, enableMusicSaga);
  yield takeLeading(disableMusic, disableMusicSaga);
  yield takeLeading(playNextMusicTrack, playNextMusicTrackSaga);
  yield takeLeading(changeAreaPlaylist, changeAreaPlaylistSaga);
}
