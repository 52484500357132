import styled from "styled-components";

interface BarProps {
  icon: string;
  number: number;
  maxNumber: number;
  color: string;
}

interface BarMaxProps {
  color: string;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

interface BarIconProps {
  image: string;
  color: string;
}

const BarWrapper = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const BarIcon = styled.div<BarIconProps>`
  height: 28px;
  width: 28px;
  z-index: 3;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const Bar = styled.div`
  position: relative;
  flex-grow: 1;
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

const BarMax = styled.div<BarMaxProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${(props) => props.color};
  filter: saturate(75%) brightness(60%);
  transform: skew(-30deg);
`;

const BarValue = styled.div.attrs(({ percentage }: BarValueProps) => ({
  style: {
    width: `${percentage}%`,
  },
}))<BarValueProps>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 200ms ease-out;
  z-index: 2;
  transform: skew(-30deg);
`;

const BarMaxNumber = styled.div`
  height: 100%;
  color: #00000060;
  font-size: 24px;
  font-weight: 600;
  margin-right: 8px;
  z-index: 1;
  line-height: 1.7;
`;

const BarValueNumber = styled.div`
  height: 100%;
  position: absolute;
  left: 12px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  z-index: 3;
  line-height: 1.7;
`;

export default function LargeBar({ icon, number, maxNumber, color }: BarProps) {
  return (
    <BarWrapper>
      <BarIcon image={icon} color={color} />
      <Bar>
        <BarMax color={color}></BarMax>
        <BarMaxNumber>{maxNumber}</BarMaxNumber>
        <BarValue percentage={(number / maxNumber) * 100} color={color} />
        <BarValueNumber>{number}</BarValueNumber>
      </Bar>
    </BarWrapper>
  );
}
