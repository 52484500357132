import { call, delay, put, select, takeLeading } from "redux-saga/effects";

import {
  BuildingPayload,
  BuyPayload,
  CharacterData,
  EnhancePayload,
  FightState,
  GetObjectPayload,
  NewCharPayload,
  SkillModel,
  TrainPayload,
} from "types";

import {
  setNewUser,
  createCharacter,
  continueAsCharacter,
  showNameSelectMessage,
  setUserName,
  setUserId,
  loadUserData,
  setInitialCharacterState,
  setInitialFightState,
  setGameModeActive,
  signInUserAccount,
  linkUserAccount,
  showMessage,
  move,
  moveLeft,
  moveRight,
  buyRepair,
  buyRestore,
  gainHealth,
  increaseExp,
  decreaseExp,
  levelUp,
  gainStatPoints,
  gainCredits,
  loseCredits,
  setUserAccountLinked,
  setFightResults,
  restoreAllParts,
  enterBuilding,
  exitBuilding,
  showInsideBuilding,
  hideInsideBuilding,
  disableMovement,
  enableMovement,
  buyResetStats,
  resetStats,
  buyEnhanceStat,
  enhanceStat,
  loseStatPoints,
  enterArea,
  hideWorld,
  showWorld,
  setNearBoundary,
  buyTrainSkill,
  trainSkill,
  resetSkills,
  buyResetSkills,
  setShipClass,
  setPilotProfession,
  continueFight,
  validateCharacterName,
  setNewCharacterScreen,
  signOutUserAccount,
  clearCharacterState,
  setGameModeInactive,
  unlinkUserAccount,
  setUserAccountUnlinked,
  exitToMainMenu,
  changeAreaPlaylist,
  buyShopObject,
  clearFightState,
} from "redux/actions";
import {
  getExistingUserName,
  claimUserName,
  createNewCharacter,
  signInAnonymousUser,
  signInGoogleUser,
  linkGoogleUserAccount,
  loadUser,
  setOnlineStatus,
  signOutUser,
  setUserOffline,
  unlinkGoogleUserAccount,
  unlinkEmailUserAccount,
} from "utils/storage/firebase";
import { getCharacter } from "redux/selectors";
import {
  calculateLevel,
  getAreaData,
  getStatsTokensSpent,
  getShipData,
  getStatPointsGained,
  getSkillsResetCost,
  getSkillsTokensSpent,
  getStatsResetCost,
  getObjectData,
} from "utils/stats";
import { User } from "firebase/auth";
import { getObjectsSaga } from "./item";

export const BOUNDARY_RANGE = 25;

function* loadUserDataSaga({ payload: userAuth }: { payload: User | null }) {
  // If no auth exists, it's a new user
  if (!userAuth) {
    yield put(setNewUser(true));
    return;
  }

  // If already authenticated (ex: linking Google account)
  // Don't load data or change state
  const { userId } = yield select(getCharacter);
  if (userId) {
    console.log("User data already loaded");
    return;
  }

  // Set user ID in state to set that auth exists
  yield put(setUserId(userAuth.uid));

  const userData: {
    userName: string;
    characterData: CharacterData;
    fightData: FightState;
  } | null = yield loadUser(userAuth.uid);

  if (!userData) {
    // Existing auth but no existing user - create character and link
    // This should only happen with Google
    if (!userAuth.isAnonymous) {
      console.log("No existing user, but user auth exists");
      yield put(setNewUser(true));
      yield put(
        showNameSelectMessage(
          "No character is linked to your account, go ahead and create one!"
        )
      );
    }
    return;
  }

  // Set if user has an account linked already
  if (!userAuth.isAnonymous) {
    yield put(setUserAccountLinked());
  }

  // Hide new character form to show something is happening
  yield put(setNewUser(false));

  // Set online status to true
  yield setOnlineStatus(userAuth.uid);

  // Load the rest of the data and start the game
  yield put(setUserName(userData.userName));
  yield put(setInitialCharacterState(userData.characterData));
  yield put(setInitialFightState(userData.fightData));
}

function* validateCharacterNameSaga({
  payload: userName,
}: {
  payload: string;
}) {
  // Character Name Validation

  // Validation: Minimum 3 characters
  if (userName.length < 3) {
    yield put(
      showNameSelectMessage("Your name should be at least 3 characters long")
    );
    return;
  }

  // Validation: Alphabet only
  var alphabet = /^[A-Za-z]+$/;
  if (!userName.match(alphabet)) {
    yield put(showNameSelectMessage("Your name can only contain letters"));
    return;
  }

  // Validation: Check if user name exists in Firebase
  const isUserNameTaken: boolean = yield getExistingUserName(userName);

  if (isUserNameTaken) {
    yield put(showNameSelectMessage("This name is already being used"));
    return;
  }

  // If validation passes, move to final new character screen
  yield put(setNewCharacterScreen("saveCharacter"));
  yield put(showNameSelectMessage(""));
}

function* createCharacterSaga({ payload }: { payload: NewCharPayload }) {
  const { ship, pilot, userName } = payload;

  // Create New Character

  // No longer a new user with no auth
  yield put(setNewUser(false));

  // If there's already a user auth (user ID exists in state),
  // leave user signed in

  // Get User ID
  let { userId } = yield select(getCharacter);

  // If no auth exists, sign in anonymously
  if (!userId) {
    const { uid } = yield signInAnonymousUser();
    userId = uid;
  }

  try {
    // Create new character in Firebase
    yield claimUserName(userId, userName);
    yield createNewCharacter(userId, userName);

    // Set online status to true
    yield setOnlineStatus(userId);

    // Set selected name, ship, and pilot in state
    yield put(setUserName(userName));
    yield put(setShipClass(ship));
    yield put(setPilotProfession(pilot));

    // Initial full heal
    yield call(healSaga);

    // Start saving character data to Firebase
    yield put(setGameModeActive());

    // Set area-specific playlist
    yield put(changeAreaPlaylist());

    yield put(showMessage(`Welcome, ${userName}!`));
  } catch (error: any) {
    console.error("Error in creating new character", error.message);
    return;
  }
}

function* continueAsCharacterSaga() {
  const { userName } = yield select(getCharacter);

  yield put(setGameModeActive());
  yield put(showMessage(`Welcome back, ${userName}!`));
  yield put(continueFight());
  yield put(changeAreaPlaylist());
}

function* signInUserAccountSaga() {
  try {
    const { uid: userId } = yield signInGoogleUser();

    // Set user ID in state to set that auth exists
    yield put(setUserId(userId));

    // No longer a new user with no auth
    yield put(setNewUser(false));

    yield put(setUserAccountLinked());
  } catch (error: any) {
    console.error("Error in signInGoogleUser", error.message);
    return;
  }
}

function* linkUserAccountSaga() {
  try {
    yield linkGoogleUserAccount();

    yield put(setUserAccountLinked());
    yield put(showMessage(`Saved successfully!`));
  } catch (error: any) {
    if (error.code === "auth/credential-already-in-use") {
      // Send a message to the title screen form
      yield put(showMessage("Your Google account is already in use"));
    }
    console.error("Error in linkGoogleUserAccount", error.message);
  }
}

function* signOutUserAccountSaga() {
  const { userId } = yield select(getCharacter);

  try {
    // Set user as offline in Firebase
    yield setUserOffline(userId);

    // Firebase user auth sign out,
    yield signOutUser();

    // Stop saving to Firebase
    yield put(setGameModeInactive());

    // Clear out character state
    yield put(clearCharacterState());

    // Clear out fight state
    yield put(clearFightState());

    // Set user account as unlinked
    yield put(setUserAccountUnlinked());

    // Set as new user, since there's no character set
    yield put(setNewUser(true));
  } catch (error: any) {
    console.error("Error in signOutUser", error.message);
    return;
  }
}

function* exitToMainMenuSaga() {
  const { userId } = yield select(getCharacter);

  try {
    // Set user as offline
    yield setUserOffline(userId);

    // Stop saving to Firebase, show main menu
    yield put(setGameModeInactive());
  } catch (error: any) {
    console.error("Error in signOutUser", error.message);
    return;
  }
}

function* unlinkUserAccountSaga() {
  try {
    yield unlinkGoogleUserAccount();
    yield unlinkEmailUserAccount();

    yield put(setUserAccountUnlinked());
    yield put(showMessage(`Unlinked successfully!`));
  } catch (error: any) {
    if (error.code === "auth/no-such-provider") {
      // Send a message to the title screen form
      yield put(showMessage("Your Google account was unable to be unlinked"));
    }
    console.error("Error in unlinkGoogleUserAccount", error.message);
    // return;
  }
}

export function* moveSaga({ payload }: { payload: string }): any {
  const {
    data: {
      location,
      stats,
      ui: { isMovementDisabled },
    },
  } = yield select(getCharacter);

  if (isMovementDisabled) {
    return;
  }

  const areaData = getAreaData(location.area);
  switch (payload) {
    case "left":
      // Make sure player won't go to negative position, with a bigger movement speed
      if (location.position >= stats.movementSpeed) {
        yield put(moveLeft(stats.movementSpeed));

        // If near boundary of area, send exit message
        if (location.position < BOUNDARY_RANGE) {
          if (!!areaData.areaLeft) {
            if (!location.isNearBoundary) {
              yield put(setNearBoundary(true));
              yield put(showMessage(`Leaving ${areaData.name}`));
            }
          }
        } else {
          if (!!location.isNearBoundary) {
            yield put(setNearBoundary(false));
          }
        }
      } else {
        // Enter new area (if exists) to left of current area
        if (!!areaData.areaLeft) {
          const areaLeftData = getAreaData(areaData.areaLeft);
          yield call(
            enterAreaSaga,
            areaData.areaLeft,
            areaLeftData.moveRange,
            payload
          );
        }
      }

      break;
    case "right":
      // Make sure player won't go beyond right boundary with a bigger movement speed
      if (location.position + stats.movementSpeed <= areaData.moveRange) {
        yield put(moveRight(stats.movementSpeed));

        // If near boundary of area, send exit message
        if (location.position + BOUNDARY_RANGE >= areaData.moveRange) {
          if (!!areaData.areaRight) {
            if (!location.isNearBoundary) {
              yield put(setNearBoundary(true));
              yield put(showMessage(`Leaving ${areaData.name}`));
            }
          }
        } else {
          // Reset boundary state
          if (!!location.isNearBoundary) {
            yield put(setNearBoundary(false));
          }
        }
      } else {
        // Enter new area (if exists) to right of current area
        if (!!areaData.areaRight) {
          yield call(enterAreaSaga, areaData.areaRight, 0, payload);
        }
      }
      break;
    default:
      break;
  }
}

export function* enterAreaSaga(
  area: string,
  position: number,
  direction: string,
  building?: string,
  buildingScreen?: string,
  buildingDialog?: string
) {
  yield put(disableMovement());

  yield put(hideWorld());

  const areaData = getAreaData(area);
  yield put(showMessage(`Entering ${areaData.name}`));

  yield delay(500);

  yield put(
    enterArea({ area: area, position: position, direction: direction })
  );

  // Send directly to building if specified
  if (building) {
    yield put(
      enterBuilding({
        building: building,
        screen: buildingScreen || null,
        dialog: buildingDialog || null,
      })
    );
  }

  yield delay(750);

  yield put(showWorld());

  yield put(enableMovement());

  // Switch to area-specific playlist
  yield put(changeAreaPlaylist());
}

function* enterBuildingSaga({ payload }: { payload: BuildingPayload }) {
  const { building, screen = null, dialog = null } = payload;

  yield put(showInsideBuilding({ building, screen, dialog }));

  yield put(disableMovement());
}

function* exitBuildingSaga() {
  yield put(hideInsideBuilding());

  yield put(enableMovement());
}

function* buyRepairSaga({ payload }: { payload: BuyPayload }) {
  const {
    data: { credits, health, stats },
  } = yield select(getCharacter);

  // Check if character has enough credits to pay
  if (credits < payload.credits) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Check if already fully healed
  if (health >= stats.maxHealth) {
    yield put(showMessage(`You're already fully healed, chill out`));
    return;
  }

  // Heal specified amount of health
  yield call(healSaga);

  // Subtract credits
  yield call(loseCreditsSaga, payload.credits);
}

export function* healSaga(healAmount?: number): any {
  const {
    data: { health, stats },
  } = yield select(getCharacter);

  // If no amount is passed through, heal to max health
  const healthGained = healAmount || stats.maxHealth;

  // Don't heal past max health
  const totalHealthGained = Math.min(stats.maxHealth - health, healthGained);

  yield put(gainHealth(totalHealthGained));
}

function* buyRestoreSaga({ payload }: { payload: BuyPayload }) {
  const {
    data: { credits },
  } = yield select(getCharacter);

  // Check if character has enough credits to pay
  if (credits < payload.credits) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Restore all weakened parts
  yield put(restoreAllParts());

  // Subtract credits
  yield call(loseCreditsSaga, payload.credits);
}

function* buyEnhanceStatSaga({ payload }: { payload: EnhancePayload }) {
  const {
    data: { baseStatPoints, ship },
  } = yield select(getCharacter);

  // Get token cost
  const tokenCost = getShipData(ship).baseStatsCosts[payload.baseStat];

  // Check if character has enough tokens to pay
  if (baseStatPoints < tokenCost) {
    yield put(showMessage(`You don't have enough medallions, maybe get more`));
    return;
  }

  // Increase chosen base stat by 1
  yield put(enhanceStat(payload.baseStat));

  // Decrease tokens by cost
  yield put(loseStatPoints(tokenCost));
}

function* buyResetStatsSaga() {
  const {
    data: { enhancedBaseStats, ship, credits },
  } = yield select(getCharacter);

  // Get credits cost of reset stats (mechanical engineers reset for free)
  const resetCost = getStatsResetCost(enhancedBaseStats, ship);

  // Check if character has enough credits to pay
  if (credits < resetCost) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Reset all enhanced base stats
  yield put(resetStats());

  // Give back all tokens
  const tokensSpent = getStatsTokensSpent(enhancedBaseStats, ship);
  yield call(gainStatPointsSaga, tokensSpent);

  // Subtract credits
  yield call(loseCreditsSaga, resetCost);
}

function* buyTrainSkillSaga({ payload }: { payload: TrainPayload }) {
  const {
    data: { baseStatPoints, skills },
  } = yield select(getCharacter);

  // Get skill cost
  const skillData = skills.find(
    (skill: SkillModel) => skill.slug === payload.skill
  );
  const tokenCost = skillData.medallions;

  // Check if already trained skill
  if (!!skillData.isTrained) {
    yield put(
      showMessage(
        `You've already trained that skill. Stop trying so hard, nerd.`
      )
    );
    return;
  }

  // Check if character has enough tokens to pay
  if (baseStatPoints < tokenCost) {
    yield put(showMessage(`You don't have enough medallions, maybe get more`));
    return;
  }

  // Add skill to character's learned skills
  yield put(trainSkill(payload.skill));

  // Decrease tokens by cost
  yield put(loseStatPoints(tokenCost));
}

function* buyResetSkillsSaga() {
  const {
    data: { skills, credits },
  } = yield select(getCharacter);

  // Get credits cost of reset
  const resetCost = getSkillsResetCost(skills);

  // Check if character has enough credits to pay
  if (credits < resetCost) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Reset all skills
  yield put(resetSkills());

  // Give back all tokens
  const tokensSpent = getSkillsTokensSpent(skills);
  yield call(gainStatPointsSaga, tokensSpent);

  // Subtract credits
  yield call(loseCreditsSaga, resetCost);
}

export function* buyShopObjectSaga({ payload }: { payload: GetObjectPayload }) {
  const {
    data: { credits },
  } = yield select(getCharacter);

  // Get item or upgrade data
  const objectData = getObjectData(payload.slug);
  const objectCost = objectData.credits;

  // Check if player has enough credits
  if (credits < objectCost) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Gain items or upgrades - move to array
  yield call(getObjectsSaga, { payload: [payload] });

  // Subtract credits
  yield call(loseCreditsSaga, objectCost);
}

export function* gainExpSaga(expGained: number): any {
  // Add exp
  yield put(increaseExp(expGained));

  // Calculate if newly gained exp is enough for a level up
  const {
    data: { level, experience },
  } = yield select(getCharacter);

  const newLevel = calculateLevel(experience);
  if (newLevel > level) {
    const levelsGained = newLevel - level;
    yield call(levelUpSaga, levelsGained);
  }
}

export function* loseExpSaga(expLost: number): any {
  const {
    data: { currentLevelExp },
  } = yield select(getCharacter);

  // Don't let current level exp amount go below 0
  const totalExpLost = Math.min(currentLevelExp, expLost);

  // Lose exp
  yield put(decreaseExp(totalExpLost));
}

export function* levelUpSaga(levelsGained: number): any {
  // Update to new level
  yield put(levelUp(levelsGained));

  const {
    data: { level },
  } = yield select(getCharacter);

  // Calculate and add base stat points to spend - handle multiple levels
  const statPointsGained = getStatPointsGained(level, levelsGained);
  yield call(gainStatPointsSaga, statPointsGained);

  yield put(
    setFightResults({
      isLevelUp: true,
      statPoints: statPointsGained,
    })
  );
}

export function* gainStatPointsSaga(statPointsGained: number): any {
  yield put(gainStatPoints(statPointsGained));
}

export function* gainCreditsSaga(creditsGained: number): any {
  // Add credits
  yield put(gainCredits(creditsGained));
}

export function* loseCreditsSaga(creditsLost: number): any {
  const {
    data: { credits },
  } = yield select(getCharacter);

  // Don't let credits amount go below 0
  const totalCreditsLost = Math.min(credits, creditsLost);

  // Lose credits
  yield put(loseCredits(totalCreditsLost));
}

export default function* characterSagas() {
  yield takeLeading(validateCharacterName, validateCharacterNameSaga);
  yield takeLeading(createCharacter, createCharacterSaga);
  yield takeLeading(loadUserData, loadUserDataSaga);
  yield takeLeading(continueAsCharacter, continueAsCharacterSaga);
  yield takeLeading(signInUserAccount, signInUserAccountSaga);
  yield takeLeading(linkUserAccount, linkUserAccountSaga);
  yield takeLeading(signOutUserAccount, signOutUserAccountSaga);
  yield takeLeading(unlinkUserAccount, unlinkUserAccountSaga);
  yield takeLeading(exitToMainMenu, exitToMainMenuSaga);
  yield takeLeading(move, moveSaga);
  yield takeLeading(enterBuilding, enterBuildingSaga);
  yield takeLeading(exitBuilding, exitBuildingSaga);
  yield takeLeading(buyRepair, buyRepairSaga);
  yield takeLeading(buyRestore, buyRestoreSaga);
  yield takeLeading(buyEnhanceStat, buyEnhanceStatSaga);
  yield takeLeading(buyResetStats, buyResetStatsSaga);
  yield takeLeading(buyTrainSkill, buyTrainSkillSaga);
  yield takeLeading(buyResetSkills, buyResetSkillsSaga);
  yield takeLeading(buyShopObject, buyShopObjectSaga);
}
