import { BattleRatingModel } from "types";
import platinumRatingImage from "assets/rating-platinum.svg";
import goldRatingImage from "assets/rating-gold.svg";
import silverRatingImage from "assets/rating-silver.svg";
import bronzeRatingImage from "assets/rating-bronze.svg";

export const BATTLE_RATINGS: Record<string, BattleRatingModel> = {
  platinum: {
    value: 4,
    name: "Platinum",
    description:
      "You completely destroyed your opponent by achieving 20:1 damage dealt to damage taken.",
    image: platinumRatingImage,
    color: "#f2cdf7",
    damageRatio: 20 / (20 + 1),
    rewardMultiplier: 1.5,
  },
  gold: {
    value: 3,
    name: "Gold",
    description:
      "You severely decimated your opponent by achieving 10:1 damage dealt to damage taken.",
    image: goldRatingImage,
    color: "#ffd731",
    damageRatio: 10 / (10 + 1),
    rewardMultiplier: 1.2,
  },
  silver: {
    value: 2,
    name: "Silver",
    description:
      "You defeated your opponent quite handily by managing 3:1 damage dealt to damage taken.",
    image: silverRatingImage,
    color: "#d8d8d8",
    damageRatio: 3 / (3 + 1),
    rewardMultiplier: 1.1,
  },
  bronze: {
    value: 1,
    name: "Bronze",
    description: "You won the fight, but it wasn't pretty.",
    image: bronzeRatingImage,
    color: "#c68e5c",
    damageRatio: 0,
    rewardMultiplier: 1,
  },
};
