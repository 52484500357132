import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import LinkAccount from "components/ui/LinkAccount";
import { exitToMainMenu } from "redux/actions";

const AuthWrapper = styled.div``;

const AuthOption = styled.div`
  cursor: pointer;
`;

export default function SettingsAuth() {
  const dispatch = useDispatch();

  return (
    <AuthWrapper>
      <LinkAccount />
      <AuthOption onClick={() => dispatch(exitToMainMenu())}>
        Exit to Main Menu
      </AuthOption>
      <div>Direct Log Out</div>
    </AuthWrapper>
  );
}
