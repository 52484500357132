import healthImage from "assets/health.svg";
import expImage from "assets/exp.svg";

interface AttributeInfo {
  name: string;
  image: string;
  formatter: (x: number) => string;
}

export const ATTRIBUTES: Record<string, AttributeInfo> = {
  health: {
    name: "Ship Health",
    image: healthImage,
    formatter: (x) => `+${x}`,
  },
  integrity: {
    name: "Components Integrity",
    image: healthImage,
    formatter: (x) => `+${x}`,
  },
  experience: {
    name: "Experience",
    image: expImage,
    formatter: (x) => `+${x}`,
  },
};
