import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { getCharacter } from "redux/selectors";
import { getItemData } from "utils/stats";
import { employItem } from "redux/actions";
import activateImage from "assets/activate.svg";
import ObjectIcon from "components/base/ObjectIcon";
import Status from "components/base/Status";

const DataWrapper = styled.div`
  display: flex;
  gap: 5%;
`;

const Section = styled.div`
  flex-basis: 50%;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
`;

const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const UseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  opacity: 0;
  transition: opacity 200ms ease-out;
  pointer-events: none;
`;

const UseButton = styled.div`
  height: 100%;
  background-color: white;
  mask: url(${activateImage}) no-repeat center;
  mask-size: 40%;
`;

const ItemImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;

  &:hover ${UseWrapper} {
    opacity: 1;
  }
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;

const Quantity = styled.div`
  font-size: 16px;
  text-align: right;
`;

export default function InventoryScreen() {
  const dispatch = useDispatch();
  const {
    data: { inventory },
  } = useSelector(getCharacter);

  return (
    <DataWrapper>
      <Section>
        <Title>Item Inventory</Title>
        <Items>
          {inventory.items.map((inventoryItem) => {
            const item = getItemData(inventoryItem.slug);
            if (!!item) {
              return (
                <Item
                  key={inventoryItem.slug}
                  onClick={() => dispatch(employItem(inventoryItem.slug))}
                >
                  <ItemWrapper>
                    <ItemImageWrapper>
                      <ObjectIcon slug={inventoryItem.slug} />
                      <UseWrapper>
                        <UseButton />
                      </UseWrapper>
                    </ItemImageWrapper>
                    <ItemInfo>
                      <Name>{item.name}</Name>
                    </ItemInfo>
                  </ItemWrapper>
                  <Quantity>x{inventoryItem.quantity}</Quantity>
                </Item>
              );
            } else {
              return <span key={inventoryItem.slug} />;
            }
          })}
        </Items>
      </Section>
      <Section>
        <Title>Status</Title>
        <Status />
      </Section>
    </DataWrapper>
  );
}
