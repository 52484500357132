import styled from "styled-components";
import Tooltip, { TooltipDesc, TooltipName, Trigger } from "./Tooltip";

interface BarProps {
  name: string;
  description: string;
  icon: string;
  number: number;
  maxNumber: number;
  color: string;
}

interface BarMaxProps {
  color: string;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

interface BarIconProps {
  image: string;
  color: string;
}

const BarWrapper = styled.div`
  width: 100%;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: skew(-30deg);
`;

const BarIconWrapper = styled.div`
  position: absolute;
  right: -20px;
  z-index: 3;
  transform: skew(30deg);
`;

const BarIcon = styled.div<BarIconProps>`
  height: 14px;
  width: 14px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const BarMax = styled.div<BarMaxProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${(props) => props.color};
  filter: saturate(75%) brightness(60%);
`;

const BarValue = styled.div.attrs(({ percentage }: BarValueProps) => ({
  style: {
    width: `${percentage}%`,
  },
}))<BarValueProps>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 200ms ease-out;
  z-index: 2;
`;

const BarMaxNumber = styled.div`
  height: 100%;
  color: #00000060;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  margin-right: 8px;
  transform: skew(30deg);
  z-index: 1;
`;

const BarValueNumber = styled.div`
  height: 100%;
  position: absolute;
  left: 12px;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  transform: skew(30deg);
  z-index: 3;
`;

export default function Bar({
  name,
  description,
  icon,
  number,
  maxNumber,
  color,
}: BarProps) {
  return (
    <BarWrapper>
      <BarIconWrapper>
        <Trigger>
          <BarIcon image={icon} color={color} />
          <Tooltip>
            <TooltipName>{name}</TooltipName>
            <TooltipDesc>{description}</TooltipDesc>
          </Tooltip>
        </Trigger>
      </BarIconWrapper>
      <BarMax color={color}></BarMax>
      <BarMaxNumber>{maxNumber}</BarMaxNumber>
      <BarValue percentage={(number / maxNumber) * 100} color={color} />
      <BarValueNumber>{number}</BarValueNumber>
    </BarWrapper>
  );
}
