import React from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { getCharacter } from "redux/selectors";
import { getRepairCost, getShipDamage } from "utils/stats";
import healthImage from "assets/health.svg";
import creditsImage from "assets/credits.svg";
import { COLORS } from "utils/constants";
import LargeBar from "components/base/LargeBar";
import { buyRepair } from "redux/actions";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import Shop from "components/base/Shop";

interface IconProps {
  image: string;
}

interface RepairButtonProps {
  $isDisabled: boolean;
}

const disabledMixin = css`
  opacity: 0.3;
  pointer-events: none;
`;

const DataWrapper = styled.div`
  display: flex;
  gap: 5%;
`;

const Section = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Credits = styled.div`
  display: flex;
  gap: 2px;
  color: ${COLORS.CREDITS};
  align-items: center;
`;

const CreditsIcon = styled.div<IconProps>`
  height: 18px;
  width: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.CREDITS};
`;

const HealthWrapper = styled.div``;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const RepairButton = styled.div<RepairButtonProps>`
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  background-color: #0e4042;
  padding: 8px 16px;
  color: white;
  margin-top: 30px;
  align-self: end;
  display: flex;
  gap: 10px;

  &:hover {
    filter: brightness(120%);
  }

  ${(props) => props.$isDisabled && disabledMixin}
`;

export default function ShipworksRepair() {
  const dispatch = useDispatch();
  const {
    data: { health, stats },
  } = useSelector(getCharacter);

  const shipDamage = getShipDamage(health, stats.maxHealth);
  const repairCost = getRepairCost(health, stats.maxHealth);

  return (
    <DataWrapper>
      <Section>
        <Title>Repair Starship</Title>
        <Name>Ship Health</Name>
        <HealthWrapper>
          <Trigger>
            <LargeBar
              icon={healthImage}
              number={health}
              maxNumber={stats.maxHealth}
              color="#42a545"
            />
            <Tooltip>
              <TooltipName>Health</TooltipName>
              <TooltipDesc>
                {`This is how much health your ship currently has. Don't let it drop to zero!`}
              </TooltipDesc>
              <TooltipDesc>
                You can fully repair your starship at the nearest Shipworks in
                any city.
              </TooltipDesc>
            </Tooltip>
          </Trigger>
        </HealthWrapper>
        <RepairButton
          onClick={() => dispatch(buyRepair({ credits: repairCost }))}
          $isDisabled={!shipDamage}
        >
          Repair
          <Credits>
            <CreditsIcon image={creditsImage} />
            {repairCost}
          </Credits>
        </RepairButton>
      </Section>
      <Section>
        <Title>Buy Repair Kits</Title>
        <Shop slug="bishopShipworksRepair" />
      </Section>
    </DataWrapper>
  );
}
