import { all, fork } from "redux-saga/effects";
import characterSagas from "redux/sagas/character";
import itemSagas from "redux/sagas/item";
import gameSagas from "redux/sagas/game";
import fightSagas from "redux/sagas/fight";
import skillSagas from "redux/sagas/skill";

export default function* rootSaga() {
  yield all([
    fork(characterSagas),
    fork(itemSagas),
    fork(gameSagas),
    fork(fightSagas),
    fork(skillSagas),
  ]);
}
