import { BATTLE_RATINGS } from "data/battleRatings";
import { BUILDINGS } from "data/buildings";
import { ITEMS } from "data/items";
import { MOBS } from "data/mobs";
import { ShipsData, SHIPS } from "data/ships";
import { PILOTS } from "data/pilots";
import { GRADES, UPGRADES } from "data/upgrades";
import {
  AreaModel,
  BattleRatingModel,
  BattleRatings,
  BuildingModel,
  CharacterBaseStats,
  CharacterDerivedStats,
  DamageModel,
  GradeModel,
  InstalledUpgradesData,
  InventoryModel,
  ItemModel,
  MobDropModel,
  MobDrops,
  MobModel,
  ModifiedDerivedStats,
  MusicTrackModel,
  NPCModel,
  ObjectData,
  PartTypes,
  PilotModel,
  PilotTypes,
  PlanetModel,
  ShipModel,
  ShipTypes,
  ShopModel,
  SkillModel,
  UpgradeModel,
} from "types";
import { AREAS, PLANETS } from "data/areas";
import { NPCS } from "data/npcs";
import { MUSIC, PLAYLISTS } from "data/music";
import { SHOPS } from "data/shops";
import { DERIVED_STATS, DerivedStatInfo } from "data/derivedStats";
import { BASE_STATS, BASE_STATS_INFO } from "data/baseStats";
import { FlattenSimpleInterpolation } from "styled-components";

export const MAX_LEVEL = 50;
export const LEVEL_EXP_MULTIPLIER = 1.5;
export const BASE_LEVEL_EXP = 1000;
export const MOB_EXP_PER_LEVEL = 100;
export const MOB_CREDITS_PER_LEVEL = 10;
export const EXP_LOST_ON_LOSE_FIGHT = 0.05;
export const RANDOM_VARIANCE = 0.2;
export const REPAIR_HEALTH_PER_CREDIT = 5;
export const CREDITS_PER_STAT_TOKEN = 20;
export const CREDITS_PER_SKILL_TOKEN = 20;
export const UPGRADE_BASE_CREDITS = 1000;
export const MAX_BASE_STAT_VALUE = 50;
export const MOB_BASE_STATS_MODIFIERS_TOTAL = 10;
export const MOB_BASE_STATS_COSTS_TOTAL = 10;

export const calculateLevel = (totalExp: number): number => {
  let level = 0;
  let remainingExp = totalExp;
  while (remainingExp >= 0 && level < MAX_LEVEL) {
    level++;
    remainingExp -= calculateNextLevelExp(level);
  }

  return level;
};

export const calculateCurrentLevelExp = (totalExp: number): number => {
  let level = 0;
  let remainingExp = totalExp;
  while (remainingExp >= 0) {
    level++;

    const nextLevelExp = calculateNextLevelExp(level);
    if (nextLevelExp > remainingExp) {
      break;
    }
    remainingExp -= nextLevelExp;
  }

  return Math.round(remainingExp);
};

export const calculateNextLevelExp = (level: number): number => {
  return Math.round(BASE_LEVEL_EXP * level ** LEVEL_EXP_MULTIPLIER);
};

export const getFightTurns = (
  characterTurnPriority: number,
  opponentTurnPriority: number,
  characterAttackSpeed: number,
  opponentAttackSpeed: number
) => {
  const isCharacterCurrentTurn = characterTurnPriority >= opponentTurnPriority;
  let isCharacterNextTurn;

  if (isCharacterCurrentTurn) {
    // Character turn
    isCharacterNextTurn =
      characterTurnPriority >= opponentTurnPriority + opponentAttackSpeed;
  } else {
    // Opponent turn
    isCharacterNextTurn =
      opponentTurnPriority < characterTurnPriority + characterAttackSpeed;
  }

  return {
    isCharacterCurrentTurn,
    isCharacterNextTurn,
  };
};

export const getNextConsecutiveTurns = (
  characterTurnPriority: number,
  opponentTurnPriority: number,
  opponentAttackSpeed: number
) => {
  let nextTurns = 0;
  while (characterTurnPriority >= opponentTurnPriority + opponentAttackSpeed) {
    nextTurns++;
    characterTurnPriority -= opponentAttackSpeed;
  }
  return nextTurns;
};

export const getMinAttackDamage = (
  maxAttackDamage: number,
  attackAccuracy: number
) => {
  const { attackDamage: attackDamageInfo } = DERIVED_STATS;

  // Calculate attack damage range, consider accuracy can be above 100%
  const rangePercentage = Math.max(1 - attackAccuracy, 0);
  const damageRangeAmount = maxAttackDamage * rangePercentage;
  const minAttackDamage = maxAttackDamage - damageRangeAmount;

  return attackDamageInfo.rounder(minAttackDamage);
};

export const getAvgAttackDamage = (
  maxAttackDamage: number,
  attackAccuracy: number
) => {
  const { attackDamage: attackDamageInfo } = DERIVED_STATS;

  // Calculate attack damage range, consider accuracy can be above 100%
  const rangePercentage = Math.max(1 - attackAccuracy, 0);
  const damageRangeAmount = maxAttackDamage * rangePercentage;
  const minAttackDamage = maxAttackDamage - damageRangeAmount;

  const avgAttackDamage = (maxAttackDamage + minAttackDamage) / 2;

  return attackDamageInfo.rounder(avgAttackDamage);
};

export const getMinWeakenParts = (
  maxWeakenParts: number,
  attackAccuracy: number
) => {
  const { weakenParts: weakenPartsInfo } = DERIVED_STATS;

  // Calculate weaken parts range, consider accuracy can be above 100%
  const rangePercentage = Math.max(1 - attackAccuracy, 0);
  const weakenPartsRangeAmount = maxWeakenParts * rangePercentage;
  const minWeakenParts = maxWeakenParts - weakenPartsRangeAmount;

  return weakenPartsInfo.rounder(minWeakenParts);
};

export const getAvgWeakenParts = (
  maxWeakenParts: number,
  attackAccuracy: number
) => {
  const { weakenParts: weakenPartsInfo } = DERIVED_STATS;

  // Calculate attack damage range, consider accuracy can be above 100%
  const rangePercentage = Math.max(1 - attackAccuracy, 0);
  const damageRangeAmount = maxWeakenParts * rangePercentage;
  const minWeakenParts = maxWeakenParts - damageRangeAmount;

  const avgWeakenParts = (maxWeakenParts + minWeakenParts) / 2;

  return weakenPartsInfo.rounder(avgWeakenParts);
};

export const getDodgeChance = (dodgeChance: number, attackAccuracy: number) => {
  const { dodgeChance: dodgeChanceInfo } = DERIVED_STATS;

  let totalDodgeChance = dodgeChance;
  if (attackAccuracy > 1) {
    const accuracyOverage = attackAccuracy - 1;
    totalDodgeChance = Math.min(dodgeChance - accuracyOverage, 0);
  }
  return dodgeChanceInfo.rounder(totalDodgeChance);
};

export const getRegularAttackValues = (
  maxAttackDamage: number,
  maxWeakenParts: number,
  attackAccuracy: number
) => {
  const { attackDamage: attackDamageInfo, weakenParts: weakenPartsInfo } =
    DERIVED_STATS;

  // Get minimum of ranges for attack damage and weaken parts
  const minAttackDamage = getMinAttackDamage(maxAttackDamage, attackAccuracy);
  const minWeakenParts = getMinWeakenParts(maxWeakenParts, attackAccuracy);

  // Roll dice to determine efficacy of damage + weaken parts
  const diceRoll = rollDice();

  const attackDamage = getNumberFromDiceRoll(
    minAttackDamage,
    maxAttackDamage,
    diceRoll
  );
  const attackWeakenParts = getNumberFromDiceRoll(
    minWeakenParts,
    maxWeakenParts,
    diceRoll
  );

  return {
    attackDamage: attackDamageInfo.rounder(attackDamage),
    attackWeakenParts: weakenPartsInfo.rounder(attackWeakenParts),
  };
};

export const getMultiplierAttackValues = (
  damage: number,
  weakenParts: number,
  attackMultiplier: number
) => {
  const { attackDamage: attackDamageInfo, weakenParts: weakenPartsInfo } =
    DERIVED_STATS;

  const attackDamage = damage * attackMultiplier;
  const attackWeakenParts = weakenParts * attackMultiplier;

  return {
    attackDamage: attackDamageInfo.rounder(attackDamage),
    attackWeakenParts: weakenPartsInfo.rounder(attackWeakenParts),
  };
};

export const getTotalDamageValuesAfterDefense = (
  attackDamage: number,
  attackWeakenParts: number,
  damageReduction: number,
  weakenPartsReduction: number
) => {
  const { attackDamage: attackDamageInfo, weakenParts: weakenPartsInfo } =
    DERIVED_STATS;

  // Calculate end damages after shield reductions
  const damageAfterReduction = Math.max(0, attackDamage - damageReduction);
  const weakenPartsAfterReduction = Math.max(
    0,
    attackWeakenParts - weakenPartsReduction
  );

  // Get actual amount of damage that was reduced by shields
  const damageReduced = Math.min(attackDamage, damageReduction);
  const weakenPartsReduced = Math.min(attackWeakenParts, weakenPartsReduction);

  // If all damage is completely reduced by shields, it's nullified
  let isNullified = false;
  if (
    damageAfterReduction === 0 &&
    weakenPartsAfterReduction === 0 &&
    (damageReduced > 0 || weakenPartsReduced > 0)
  ) {
    isNullified = true;
  }

  return {
    damage: attackDamageInfo.rounder(damageAfterReduction),
    weakenParts: weakenPartsInfo.rounder(weakenPartsAfterReduction),
    damageReduced,
    weakenPartsReduced,
    isNullified,
  };
};

export const getOpponentBaseStatTarget = (
  baseStatsTargets: (keyof CharacterBaseStats)[],
  characterBaseStats: CharacterBaseStats
): keyof CharacterBaseStats => {
  // Go in order of opponent's targeting, unless the base stat is already 0
  for (const i in baseStatsTargets) {
    if (characterBaseStats[baseStatsTargets[i]] > 0) {
      return baseStatsTargets[i];
    }
  }
  // If all are already 0, just target the first
  return baseStatsTargets[0];
};

export const calculateBattleRating = (
  damageDealt: number,
  damageTaken: number
): BattleRatings => {
  const damageRatio: number = damageDealt / (damageDealt + damageTaken);
  let battleRating: BattleRatings = "bronze";

  for (const key in BATTLE_RATINGS) {
    const ratingKey = key as BattleRatings;
    const ratingData = BATTLE_RATINGS[ratingKey] as BattleRatingModel;
    if (damageRatio > ratingData.damageRatio) {
      battleRating = ratingKey;
      break;
    }
  }

  return battleRating;
};

export const getExpGained = (mobLevel: number, multiplier: number): number => {
  const baseExp = mobLevel * MOB_EXP_PER_LEVEL;
  const expGained = Math.round(baseExp * multiplier);

  return expGained;
};

export const getExpLost = (currentLevelExp: number): number => {
  const expLost = Math.round(currentLevelExp * EXP_LOST_ON_LOSE_FIGHT);

  return expLost;
};

export const getStatPointsGained = (
  newLevel: number,
  levelsGained: number
): number => {
  const oldLevel = newLevel - levelsGained;
  let statPointsGained = 0;

  for (let i = oldLevel + 1; i <= newLevel; i++) {
    const statsPerLevel = Math.ceil(i / 10);
    statPointsGained += statsPerLevel;
  }

  return statPointsGained;
};

export const getCreditsGained = (
  mobLevel: number,
  multiplier: number
): number => {
  const baseCredits = mobLevel * MOB_CREDITS_PER_LEVEL;
  const creditsGained = Math.round(baseCredits * multiplier);

  return creditsGained;
};

export const getMobData = (slug: string): MobModel => {
  return MOBS[slug];
};

export const isItemType = (slug: string): boolean => {
  if (!!ITEMS[slug]) {
    return true;
  }
  return false;
};

export const getItemData = (slug: string): ItemModel => {
  return ITEMS[slug];
};

export const getUpgradeData = (slug: string) => {
  const upgradeData = UPGRADES[slug];
  const gradeData = getGradeData(upgradeData.grade);

  // Calculate stats for upgrade
  const derivedStats = calculateUpgradeStats(upgradeData, gradeData);

  // Calculate credits for upgrade
  const credits = calculateUpgradeCredits(upgradeData, gradeData);

  return {
    ...upgradeData,
    gradeData,
    derivedStats,
    credits,
  };
};

export const calculateUpgradeStats = (
  upgrade: UpgradeModel,
  grade: GradeModel
): ModifiedDerivedStats => {
  const { requirement } = upgrade;
  const { multiplier } = grade;

  const derivedStats: { [key: string]: number } = {};

  const statMods = upgrade.derivedStatsModifiers;
  for (const key in statMods) {
    const statType = key as keyof CharacterDerivedStats;
    const statWeight = statMods[statType] as number;
    const statInfo = DERIVED_STATS[statType];

    const statValue = statInfo.rounder(
      statInfo.incrementValue * statWeight * requirement.value ** multiplier
    );

    derivedStats[statType] = statValue;
  }

  return derivedStats;
};

export const calculateUpgradeCredits = (
  upgrade: UpgradeModel,
  grade: GradeModel
): number => {
  const { requirement } = upgrade;
  const { multiplier } = grade;

  const credits = Math.round(
    UPGRADE_BASE_CREDITS * requirement.value ** multiplier
  );

  return credits;
};

export const getGradeData = (slug: string) => {
  return GRADES[slug];
};

export const getObjectData = (slug: string): ObjectData => {
  const objectData = isItemType(slug)
    ? getItemData(slug)
    : getUpgradeData(slug);

  return {
    name: objectData.name,
    description: objectData.description,
    icon: objectData.icon,
    credits: objectData.credits,
  };
};

export const getUpgradesStats = (
  upgrades: InstalledUpgradesData | null,
  currentBaseStats: CharacterBaseStats,
  totalBaseStats: CharacterBaseStats
) => {
  const stats = {
    maxHealth: 0,
    attackDamage: 0,
    attackSpeed: 0,
    attackAccuracy: 0,
    weakenParts: 0,
    damageReduction: 0,
    weakenPartsReduction: 0,
    criticalHitChance: 0,
    criticalHitMultiplier: 0,
    dodgeChance: 0,
    movementSpeed: 0,
    fasterRecharge: 0,
    energyMultiplier: 0,
  };

  if (!!upgrades) {
    for (const partKey in upgrades) {
      const part = partKey as PartTypes;
      const upgradeSlug = upgrades[part];
      if (!!upgradeSlug) {
        const statMods = getUpgradeData(upgradeSlug)
          .derivedStats as CharacterDerivedStats;
        for (const key in statMods) {
          const statType = key as keyof CharacterDerivedStats;
          const statInfo = DERIVED_STATS[statType];

          // Use weakened parts to determine efficacy of upgrade stats
          const percentage =
            currentBaseStats[statInfo.baseStat] /
            totalBaseStats[statInfo.baseStat];
          stats[statType] += statInfo.rounder(statMods[statType] * percentage);
        }
      }
    }
  }

  return stats;
};

export const getBattleRatingData = (slug: BattleRatings): BattleRatingModel => {
  return BATTLE_RATINGS[slug];
};

export const getMobDrops = (
  drops: MobDrops,
  battleRating: BattleRatings
): MobDropModel[] => {
  // Get drops based on rating, should get all lower rating drops as well
  const gainedDrops: MobDropModel[] = [];

  for (const key in BATTLE_RATINGS) {
    const ratingKey = key as BattleRatings;
    const ratingData = BATTLE_RATINGS[ratingKey] as BattleRatingModel;

    const { value: fightRatingValue } = getBattleRatingData(battleRating);

    if (fightRatingValue >= ratingData.value) {
      // Compare value to actually battle rating value to grab each drop?
      if (drops[ratingKey]) {
        const drop = drops[ratingKey] as MobDropModel;
        gainedDrops.push(drop);
      }
    }
  }

  return gainedDrops;
};

export const getPilotSkills = (
  pilot: PilotTypes,
  trainedSkills: string[],
  fasterRecharge: number
) => {
  const rechargeReduction = Math.floor(fasterRecharge);
  const pilotData = getPilotData(pilot);

  // adjust skills to account for faster recharge stat
  const pilotSkills = pilotData.skills.map((skill) => {
    // Check if skill is trained by player
    const isTrained = !!skill.isDefault || trainedSkills.includes(skill.slug);

    return {
      ...skill,
      recharge: Math.max(skill.recharge - rechargeReduction, 0),
      isTrained,
    };
  });

  return pilotSkills;
};

export const getFightItems = (inventoryItems: InventoryModel[]) => {
  const fightItems = inventoryItems
    .map((item) => {
      return {
        ...item,
        ...getItemData(item.slug),
      };
    })
    .filter((item) => !!item.inFight);

  return fightItems;
};

export const getRepairItems = (inventoryItems: InventoryModel[]) => {
  const repairItems = inventoryItems
    .map((item) => {
      return {
        ...item,
        ...getItemData(item.slug),
      };
    })
    .filter((item) => item.attribute === "health")
    .sort((a, b) => a.payload - b.payload);

  return repairItems;
};

export const getPilotData = (pilot: PilotTypes): PilotModel => {
  return PILOTS[pilot];
};

export const getAllPilotsData = (): Record<PilotTypes, PilotModel> => {
  return PILOTS;
};

export const getShipData = (ship: ShipTypes): ShipModel => {
  return SHIPS[ship];
};

export const getAllShipsData = (): ShipsData => {
  return SHIPS;
};

export const getNPCData = (npc: string): NPCModel => {
  return NPCS[npc];
};

export const getPlanetData = (planet: string): PlanetModel => {
  return PLANETS[planet];
};

export const getAreaData = (area: string): AreaModel => {
  return AREAS[area];
};

export const getBuildingData = (building: string): BuildingModel => {
  return BUILDINGS[building];
};

export const getMusicData = (track: string): MusicTrackModel => {
  return MUSIC[track];
};

export const getShopData = (shop: string): ShopModel => {
  return SHOPS[shop];
};

export const getNextMusicTrack = (
  currentTrack: string,
  currentPlaylist: string
): string => {
  const playlist = PLAYLISTS[currentPlaylist];
  const currentIndex = playlist.indexOf(currentTrack);

  // If switched to a different playlist, just play first song of new playlist
  // Repeat playlist when ended
  let nextIndex = 0;
  if (currentIndex >= 0 && currentIndex < playlist.length - 1) {
    nextIndex = currentIndex + 1;
  }

  return playlist[nextIndex];
};

export const getNumberFromDiceRoll = (
  min: number,
  max: number,
  diceRoll: number
): number => {
  return diceRoll * (max - min) + min;
};

export const rollDice = (): number => {
  const diceRoll = Math.random();

  return diceRoll;
};

export const doesEventHappen = (percentageChance: number): boolean => {
  const diceRoll = rollDice();
  if (diceRoll < percentageChance) {
    return true;
  }

  return false;
};

export const getRandomBaseStat = (): keyof CharacterBaseStats => {
  const baseStats = Object.values(BASE_STATS);

  const randomIndex = Math.floor(Math.random() * baseStats.length);

  const baseStat = baseStats[randomIndex];

  return baseStat;
};

export const getShipDamage = (health: number, maxHealth: number): number => {
  const shipDamage = maxHealth - health;

  return shipDamage;
};

export const getRepairCost = (health: number, maxHealth: number): number => {
  const damageToRepair = getShipDamage(health, maxHealth);
  return Math.ceil(damageToRepair / REPAIR_HEALTH_PER_CREDIT);
};

export const getTotalWeakenedIntegrity = (
  weakenedBaseStats: CharacterBaseStats
): number => {
  let totalWeakenedIntegrity = 0;
  for (const key in weakenedBaseStats) {
    const statSlug = key as keyof CharacterBaseStats;
    const weakenedValue = weakenedBaseStats[statSlug];
    totalWeakenedIntegrity += weakenedValue;
  }

  return totalWeakenedIntegrity;
};

export const getPartsToRestore = (weakenedBaseStats: CharacterBaseStats) => {
  const partsToRestore = Object.entries(weakenedBaseStats)
    .filter(([key, value]) => {
      const weakenedAmount = value as number;
      return weakenedAmount > 0;
    })
    .sort(([, a], [, b]) => {
      // Highest to lowest part damage
      const aValue = a as number;
      const bValue = b as number;
      return bValue - aValue;
    });
  return partsToRestore;
};

export const getLastWeakenedPartColor = (damage: DamageModel[] = []) => {
  let weakenedPartColor = "white";
  const lastDamage = damage[damage.length - 1];
  if (lastDamage) {
    const baseStatInfo = BASE_STATS_INFO.find(
      (stat) => stat.slug === lastDamage.baseStatWeakened
    );
    if (baseStatInfo) {
      weakenedPartColor = baseStatInfo.color;
    }
  }

  return weakenedPartColor;
};

export const hasAnimation = (
  animations: string[] = [],
  animation: string
): boolean => {
  return animations.includes(animation);
};

export const getSkillAnimation = (
  skillAnimations: {
    [key: string]: FlattenSimpleInterpolation;
  },
  activeAnimations: string[] = []
) => {
  const skill = Object.keys(skillAnimations).find((key) => {
    return activeAnimations.includes(key);
  });
  if (skill) {
    return skillAnimations[skill];
  } else {
    return null;
  }
};

// No longer restoring parts outside of battle
// export const getRestoreCost = (
//   weakenedBaseStats: CharacterBaseStats
// ): number => {
//   const integrityToRestore = getTotalWeakenedIntegrity(weakenedBaseStats);

//   return Math.ceil(integrityToRestore / RESTORE_INTEGRITY_PER_CREDIT);
// };

export const getStatsTokensSpent = (
  enhancedBaseStats: CharacterBaseStats,
  ship: ShipTypes
): number => {
  let totalTokensSpent = 0;
  const statsCosts = getShipData(ship).baseStatsCosts;

  // Figure out number of tokens to return - with differing stat costs
  for (const key in enhancedBaseStats) {
    const statSlug = key as keyof CharacterBaseStats;
    const enhancedStatValue = enhancedBaseStats[statSlug];

    const tokenCost = enhancedStatValue * statsCosts[statSlug];
    totalTokensSpent += tokenCost;
  }

  return totalTokensSpent;
};

export const getStatsResetCost = (
  enhancedBaseStats: CharacterBaseStats,
  ship: ShipTypes
): number => {
  const tokensSpent = getStatsTokensSpent(enhancedBaseStats, ship);

  const resetCost = Math.ceil(tokensSpent * CREDITS_PER_STAT_TOKEN);

  return resetCost;
};

export const getSkillsTokensSpent = (skills: SkillModel[]): number => {
  let totalTokensSpent = 0;

  skills.forEach((skill) => {
    if (!!skill.isTrained) totalTokensSpent += skill.medallions;
  });

  return totalTokensSpent;
};

export const getSkillsResetCost = (skills: SkillModel[]): number => {
  const tokensSpent = getSkillsTokensSpent(skills);

  return Math.ceil(tokensSpent * CREDITS_PER_SKILL_TOKEN);
};

// BASE AND DERIVED STATS

export const calculateTotalBaseStats = (
  shipBaseStats: CharacterBaseStats,
  enhancedBaseStats: CharacterBaseStats
): CharacterBaseStats => {
  return {
    firepower: shipBaseStats.firepower + enhancedBaseStats.firepower,
    resilience: shipBaseStats.resilience + enhancedBaseStats.resilience,
    speed: shipBaseStats.speed + enhancedBaseStats.speed,
    precision: shipBaseStats.precision + enhancedBaseStats.precision,
    energy: shipBaseStats.energy + enhancedBaseStats.energy,
  };
};

export const calculateCurrentBaseStats = (
  totalBaseStats: CharacterBaseStats,
  weakenedBaseStats: CharacterBaseStats
): CharacterBaseStats => {
  return {
    firepower:
      Math.round(
        (totalBaseStats.firepower - weakenedBaseStats.firepower) * 10
      ) / 10,
    resilience:
      Math.round(
        (totalBaseStats.resilience - weakenedBaseStats.resilience) * 10
      ) / 10,
    speed:
      Math.round((totalBaseStats.speed - weakenedBaseStats.speed) * 10) / 10,
    precision:
      Math.round(
        (totalBaseStats.precision - weakenedBaseStats.precision) * 10
      ) / 10,
    energy:
      Math.round((totalBaseStats.energy - weakenedBaseStats.energy) * 10) / 10,
  };
};

export const calculateMobBaseStats = (
  level: number,
  baseStatsModifiers: CharacterBaseStats,
  baseStatsCosts: CharacterBaseStats
): CharacterBaseStats => {
  // Mob base stats depend on level and modifiers
  // Total modifiers should add up to 15

  // Should take into account cost - 1 medallion doesn't equal 1 stat

  let totalMedallions = 0;
  for (let i = 1; i <= level; i++) {
    const medallionsPerLevel = Math.ceil(i / 10);
    totalMedallions += medallionsPerLevel;
  }

  // Put together initial mob base stats object
  const mobBaseStats = Object.values(BASE_STATS).reduce(
    (mobBaseStats, baseStat) => {
      const mobBaseStat = calculateMobBaseStat(
        baseStat,
        baseStatsModifiers[baseStat],
        baseStatsCosts[baseStat],
        totalMedallions
      );
      return {
        ...mobBaseStats,
        [baseStat]: mobBaseStat,
      };
    },
    { firepower: 0, resilience: 0, speed: 0, precision: 0, energy: 0 }
  );

  return mobBaseStats;
};

const calculateMobBaseStat = (
  baseStat: keyof CharacterBaseStats,
  modifier: number,
  cost: number,
  totalMedallions: number
): number => {
  // Add boost of 5 mob base stats to counter the character's initial 10
  const initialStatValue = modifier / 2;

  return (
    Math.round(
      Math.min(
        initialStatValue +
          (modifier / MOB_BASE_STATS_MODIFIERS_TOTAL) *
            (totalMedallions / cost),
        MAX_BASE_STAT_VALUE
      ) * 10
    ) / 10
  );
};

// CALCULATE SKILL VALUES

export const getShieldBreakerDamage = (stats: CharacterDerivedStats) => {
  const SHIELD_BREAKER_MULTIPLIER = 2;

  // Get double the max weaken stats
  const initialDamage = 0;
  const initialWeakenParts = SHIELD_BREAKER_MULTIPLIER * stats.weakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getScattershotDamage = (stats: CharacterDerivedStats) => {
  const SCATTERSHOT_MULTIPLIER = 3;

  // Get double the max weaken stats
  const initialDamage = 0;
  const initialWeakenParts = SCATTERSHOT_MULTIPLIER * stats.weakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getPowerSurgeDamage = (
  stats: CharacterDerivedStats,
  opponentDamages: DamageModel[],
  skillsValues: { [key: string]: number }
) => {
  const totalDamage = opponentDamages.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  // Only allow new deflected damage to build up in next attack
  const usedDamage = skillsValues.powerSurge || 0;

  const minAttackDamage = 0;
  const initialMaxDamage = Math.max(totalDamage - usedDamage, 0);
  const initialMaxWeakenParts = 0;

  // Get max attack damage with energy multiplier in mind, for the description values
  const { attackDamage: maxAttackDamage } = getMultiplierAttackValues(
    initialMaxDamage,
    initialMaxWeakenParts,
    stats.energyMultiplier
  );

  // Roll dice to determine efficacy of damage + weaken parts
  const diceRoll = rollDice();

  const actualAttackDamage = getNumberFromDiceRoll(
    minAttackDamage,
    initialMaxDamage,
    diceRoll
  );

  const initialDamage = actualAttackDamage;
  const initialWeakenParts = initialMaxWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
    maxAttackDamage,
    totalDamage,
  };
};

export const getRapidFireDamage = (stats: CharacterDerivedStats) => {
  const initialDamage = getAvgAttackDamage(
    stats.attackDamage,
    stats.attackAccuracy
  );
  const initialWeakenParts = getAvgWeakenParts(
    stats.weakenParts,
    stats.attackAccuracy
  );
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getHyperShiftDamage = (
  stats: CharacterDerivedStats,
  nextConsecutiveTurns: number
) => {
  // Double for each next consecutive turn
  const initialDamage = stats.attackDamage * 2 ** nextConsecutiveTurns;
  const initialWeakenParts = 0;

  // Get damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getPhantomStrikeDamage = (stats: CharacterDerivedStats) => {
  // Max damage
  const initialDamage = stats.attackDamage;
  const initialWeakenParts = 0;

  // Get damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getEmergencyRepairHealth = (stats: CharacterDerivedStats) => {
  const INITIAL_REPAIR_PERCENTAGE = 0.2;

  const healPercentage = INITIAL_REPAIR_PERCENTAGE * stats.energyMultiplier;

  const healAmount = stats.maxHealth * healPercentage;

  return {
    healAmount,
    healPercentage,
  };
};

export const getShieldRestoreAmount = (
  stats: CharacterDerivedStats,
  totalBaseStats: CharacterBaseStats
) => {
  const INITIAL_RESTORE_PERCENTAGE = 0.2;

  const restorePercentage = INITIAL_RESTORE_PERCENTAGE * stats.energyMultiplier;

  const restoreAmount = totalBaseStats.resilience * restorePercentage;

  return {
    restoreAmount,
    restorePercentage,
  };
};

export const getShieldStormDamage = (
  stats: CharacterDerivedStats,
  characterDamages: DamageModel[],
  skillsValues: { [key: string]: number }
) => {
  const totalDamageReduced = characterDamages.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damageReduced,
    0
  );
  // Only allow new deflected damage to build up in next attack
  const usedDamageReduced = skillsValues.shieldStorm || 0;
  const damageReduced = Math.max(totalDamageReduced - usedDamageReduced, 0);

  const initialDamage = damageReduced;
  const initialWeakenParts = 0;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
    totalDamageReduced,
  };
};

export const getEnergyBlastDamage = (
  stats: CharacterDerivedStats,
  currentBaseStats: CharacterBaseStats
) => {
  const ENERGY_BLAST_MULTIPLIER = 5;

  const initialDamage = ENERGY_BLAST_MULTIPLIER * currentBaseStats.energy;
  const initialWeakenParts = 0;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getReactorOverloadDamage = (
  stats: CharacterDerivedStats,
  currentBaseStats: CharacterBaseStats
) => {
  const ENERGY_BLAST_MULTIPLIER = 10;

  const initialDamage = ENERGY_BLAST_MULTIPLIER * currentBaseStats.energy;
  const initialWeakenParts = 0;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getShieldBypassDamage = (
  stats: CharacterDerivedStats,
  currentBaseStats: CharacterBaseStats
) => {
  const ENERGY_BLAST_MULTIPLIER = 2;

  const initialDamage = ENERGY_BLAST_MULTIPLIER * currentBaseStats.energy;
  const initialWeakenParts = 0;

  // Get damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getSystemsDecayDamage = (stats: CharacterDerivedStats) => {
  const SYSTEMS_DECAY_MULTIPLIER = 1;

  const initialDamage = 0;
  const initialWeakenParts = SYSTEMS_DECAY_MULTIPLIER;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getPressurePointDamage = (
  stats: CharacterDerivedStats,
  skillsValues: { [key: string]: number }
) => {
  const PRESSURE_POINT_MULTIPLIER_PER_USE = 2;
  const MAX_DOUBLING_TIMES = 4;

  const useCount = Math.min(
    skillsValues.pressurePoint || 0,
    MAX_DOUBLING_TIMES
  );

  // Start with at least 1 damage if min attack is 0
  const initialDamage = Math.max(
    getMinAttackDamage(stats.attackDamage, stats.attackAccuracy),
    1
  );
  const initialWeakenParts = 0;

  // Double the damage based on how many time it's been used
  const doubledDamage =
    initialDamage * PRESSURE_POINT_MULTIPLIER_PER_USE ** useCount;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    doubledDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getEagleEyeDamage = (stats: CharacterDerivedStats) => {
  const initialDamage = stats.attackDamage;
  const initialWeakenParts = stats.weakenParts;

  // Get critical hit damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.criticalHitMultiplier * stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getBlastEchoDamage = (
  stats: CharacterDerivedStats,
  opponentDamages: DamageModel[]
) => {
  const BLAST_ECHO_MULTIPLIER = 3;

  const avgWeakenParts = getAvgWeakenParts(
    stats.weakenParts,
    stats.attackAccuracy
  );

  // Get triple the average weaken stats
  const initialDamage = 0;
  const initialWeakenParts = BLAST_ECHO_MULTIPLIER * avgWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  // Get last weakened base stat
  let lastWeakenedBaseStat = null;
  const lastDamage = [...opponentDamages].pop();
  if (lastDamage) {
    lastWeakenedBaseStat = lastDamage.baseStatWeakened;
  }

  return {
    attackDamage,
    attackWeakenParts,
    lastWeakenedBaseStat,
  };
};

// Calculate Derived Stats

export const calculateBasicDerivedStat = (
  derivedStat: DerivedStatInfo,
  baseStatValue: number
): number => {
  return derivedStat.rounder(
    derivedStat.startingValue + baseStatValue * derivedStat.incrementValue
  );
};

export const calculateMobStartingHealth = (
  resilience: number,
  upgradesMaxHealth: number
) => {
  return (
    calculateBasicDerivedStat(DERIVED_STATS.maxHealth, resilience) +
    upgradesMaxHealth
  );
};

// Data tests, ensure that assumptions about static data are true, these are run by Jest

export const doesMobMeetUpgradeRequirements = (mobData: MobModel) => {
  // Ensure that mob upgrade reqs are met by mob stats
  const { name, level, baseStatsModifiers, baseStatsCosts, installedUpgrades } =
    mobData;
  const totalBaseStats = calculateMobBaseStats(
    level,
    baseStatsModifiers,
    baseStatsCosts
  );
  for (const partKey in installedUpgrades) {
    const part = partKey as PartTypes;
    const upgradeSlug = installedUpgrades[part];
    if (!!upgradeSlug) {
      const { requirement, name: upgradeName } = getUpgradeData(upgradeSlug);

      // Check mob base stat against upgrade req
      if (totalBaseStats[requirement.baseStat] < requirement.value) {
        console.log(
          `${name} has ${totalBaseStats[requirement.baseStat]} ${
            requirement.baseStat
          }, but ${upgradeName} needs ${requirement.value} ${
            requirement.baseStat
          }`
        );
        return false;
      }
    }
  }
  return true;
};

export const areMobBaseStatsModifiersValid = (mobData: MobModel) => {
  // Ensure that mob base stat modifiers sum up to 10
  const { name, baseStatsModifiers } = mobData;

  const statModsSum = Object.values(baseStatsModifiers).reduce(
    (sum: number, statMod: number) => sum + statMod,
    0
  );

  if (statModsSum !== MOB_BASE_STATS_MODIFIERS_TOTAL) {
    console.log(
      `${name} has base stat modifiers sum of ${statModsSum}. The expected sum is ${MOB_BASE_STATS_MODIFIERS_TOTAL}`
    );
    return false;
  }
  return true;
};

export const areMobBaseStatCostsValid = (mobData: MobModel) => {
  // Ensure that mob base stat costs sum up to 10
  const { name, baseStatsCosts } = mobData;

  const statCostsSum = Object.values(baseStatsCosts).reduce(
    (sum: number, statCost: number) => sum + statCost,
    0
  );

  if (statCostsSum !== MOB_BASE_STATS_COSTS_TOTAL) {
    console.log(
      `${name} has base stat costs sum of ${statCostsSum}. The expected sum is ${MOB_BASE_STATS_COSTS_TOTAL}`
    );
    return false;
  }
  return true;
};

export const areMobDropsInstalled = (mobData: MobModel) => {
  // Ensure that mob drops are actually installed upgrades for that mob
  const { name, drops, installedUpgrades } = mobData;

  for (const key in drops) {
    const rating = key as BattleRatings;
    const dropInfo = drops[rating];
    if (!!dropInfo) {
      const isUpgrade = !isItemType(dropInfo.slug);
      if (!!isUpgrade) {
        const isDropInstalled = Object.values(installedUpgrades).includes(
          dropInfo.slug
        );
        if (!isDropInstalled) {
          console.log(
            `${name} has ${dropInfo.slug} as a drop but not an installed upgrade`
          );
          return false;
        }
      }
    }
  }
  return true;
};

export const areUpgradeStatModifiersValid = (upgradeData: UpgradeModel) => {
  // Ensure that mob base stat modifier weights sum up to 1
  const { name, derivedStatsModifiers } = upgradeData;

  const statModsSum = Object.values(derivedStatsModifiers).reduce(
    (sum: number, statMod: number) => sum + statMod,
    0
  );

  if (statModsSum !== 1) {
    console.log(
      `${name} has derived stat weight total of ${statModsSum}. The expected total weight is 1`
    );
    return false;
  }
  return true;
};

export const areDefaultPilotSkillsValid = (pilotData: PilotModel) => {
  // Ensure that default pilot skills have a medallion cost of 0
  const { skills } = pilotData;

  const defaultSkills = skills.filter((value) => !!value.isDefault);

  for (const skill of defaultSkills) {
    if (skill.medallions > 0) {
      console.log(
        `Default skill ${skill.name} has a cost of ${skill.medallions}. The expected cost is 0`
      );
      return false;
    }
  }
  return true;
};
