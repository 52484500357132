import React from "react";
import "css-doodle";

export default function MainMenuBackground() {
  return (
    <css-doodle>
      {`
        --color: #51eaea, #fffde1, #ff9d76, #fb3569;

        @grid: 10x1 / 100vw 100vh / #270f34; 

        :container {
          perspective: 30vmin;
          --deg: @p(-180deg, 180deg);
          z-index: 0;
          position: absolute;
        }

        :after, :before {
          content: '';
          background: @p(--color); 
          @place: @r(100%) @r(100%);
          @size: @r(1px);
          @shape: hypocycloid 4;
          opacity: 0.2;
        }

        @place: center;
        @size: 18vmin; 

        box-shadow: @m2(0 0 15px @p(--color));
        background: @m100(
          radial-gradient(@p(--color) 50%, transparent 0) 
          @r(-20%, 120%) @r(-20%, 100%) / 1px 1px
          no-repeat
        );

        will-change: transform, opacity;
        animation: scale-up 15s linear infinite;
        animation-delay: calc(-15s / @I * @i);
        border-radius: 25%;

        @keyframes scale-up {
          0%, 95.01%, 100% {
            transform: translateZ(0) rotate(0);
            opacity: 0;
          }
          10% { 
            opacity: 1; 
          }
          95% {
            transform: 
              translateZ(35vmin) rotateZ(var(--deg));
          }
        }
      `}
    </css-doodle>
  );
}
